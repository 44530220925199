import React, { PropsWithChildren, ReactElement, useContext, useMemo } from 'react'
import { ScreenContext } from '../../../contexts'
import { useFile, UseFileData } from '../../../hooks'
import { PdfView } from '../../PdfView'
import { DefaultModal } from '../DefaultModal'

interface PdfViewModalProps {
  isOpen: boolean
  onClose: () => void
  url?: string
  header: string | ReactElement
}

interface StoragePathPdfViewModalProps {
  isOpen: boolean
  onClose: () => void
  storagePath: string
  header: string | ReactElement
}

export const PdfViewModal = ({
  url,
  header,
  children,
  ...modalProps
}: PropsWithChildren<PdfViewModalProps>) => {
  const screen = useContext(ScreenContext)

  const { width, height } = useMemo(() => {
    const w = Math.min(screen.width, 1000)
    return { width: w, height: Math.min(screen.height - 100, w * 1.4143) }
  }, [screen])
  return (
    <DefaultModal
      scrollBehavior="inside"
      header={header}
      {...modalProps}
      bodyProps={{
        padding: 0,
        overflow: 'hidden',
        width: `${width}px`,
        maxHeight: `${height}px`,
        display: 'flex',
        flexFlow: 'column',
        height: `${height}px`,
      }}
      contentProps={{
        overflow: 'hidden',
        minW: 0,
        margin: 'auto',
        maxW: width,
        height: `${height}px`,
        maxH: `${height}px`,
        width: 'auto',
      }}
      render={() => (
        <PdfView url={url} width={width} height={height}>
          {children}
        </PdfView>
      )}
    />
  )
}

export const StoragePathPdfViewModal = ({
  storagePath,
  ...modalProps
}: StoragePathPdfViewModalProps) => {
  const useFileArgs = useMemo<UseFileData>(
    () => ({
      path: storagePath,
      pathLoading: false,
    }),
    [storagePath],
  )
  const { url } = useFile(useFileArgs)
  return <PdfViewModal url={url} {...modalProps} />
}

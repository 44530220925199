import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  ASSESSMENTS,
  colors,
  formatField,
  makeAllFieldsOptional,
  Note,
  planDesignField,
  signOnFields,
} from '@hb/shared'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { updateSharedNote } from '../../backend'
import { useApp } from '../../contexts/AppContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { UserContext } from '../../contexts/UserContext'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'
import { useMobileLayout } from '../../hooks/useMobileLayout'
import { addMetadata } from '../../utils'
import { DataView } from '../DataView/DataView'
import { NoteForm } from '../forms/NoteForm'
import { SignOnRemindersView } from '../Users/Profile/Assessments/SignOnRemindersView'
import { SkipToSignOn } from '../Users/Profile/Assessments/SkipToSignOn'
import { MenuTabList } from '../Users/Profile/MenuTabList'
import { AssessmentPayments } from '../Users/Profile/Payments/AssessmentPayments'
import { AssessmentFaxes } from './AssessmentFaxes'
import { AssessmentModal } from './AssessmentModal/AssessmentModal'
import { AssessmentPreview } from './AssessmentPreview'
import { InsuranceCardsView } from './InsuranceCardsView'
import { AdminInsurancePlansView } from './InsurancePlansView'

const ArchivedAssessments = () => {
  const { assessmentId, selectAssessment, user, assessments } = useContext(UserContext)
  const archivedAssessments = useMemo(
    () =>
      Object.entries(assessments || {}).filter(
        ([id, val]) => !!val.archivedOn && id !== assessmentId,
      ),
    [assessments, assessmentId],
  )
  return user ? (
    <Accordion allowMultiple defaultIndex={[]} width="100%">
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              <Text>{`Archived assessments (${archivedAssessments.length})`}</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          {archivedAssessments.map(([id, assessment]) => (
            <AssessmentPreview
              id={id}
              key={id}
              buttonText={id === assessmentId ? 'Selected' : 'Select'}
              onSelect={() => selectAssessment(id)}
              user={user}
              assessment={assessment}
            />
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : null
}

const AssessmentNote = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
    assessmentId,
  } = useContext(UserContext)
  const { sharedNote } = selectedAssessment || {}
  const { appName } = useApp()
  const { processResponse } = useContext(PopUpMessageContext)

  const handleSubmitSharedNote = useCallback(
    async (data: Note) => {
      if (!assessmentId) return processResponse({ error: 'No assessment selected' })
      const { text } = data
      try {
        await updateSharedNote({
          appName,
          assessmentId,
          note: text,
          noteId: 'sharedNote',
        })
        return processResponse({ success: 'Note saved' })
      } catch (err: any) {
        return processResponse({ error: err.message || 'Error saving note' })
      }
    },
    [appName, assessmentId, processResponse],
  )

  const { mobileLayout, ref: contentRef } = useMobileLayout(700)
  return (
    <Flex gap={2} flexFlow={mobileLayout ? 'column' : 'row'} ref={contentRef} w="100%">
      <NoteForm
        onSubmit={handleSubmitSharedNote}
        placeholder="Shared note (visible to HB and practice)"
        note={sharedNote}
      />
    </Flex>
  )
}
const AssessmentsView = () => {
  const {
    assessments,
    user,
    selectAssessment,
    assessmentId,
    selectedAssessment: { populated: selectedAssessment, assessmentData: selectedAssessmentData },
    admin,
  } = useContext(UserContext)
  const notSelected = useMemo(
    () => Object.entries(assessments || {}).filter(([id]) => id !== assessmentId),
    [assessments, assessmentId],
  )
  const notArchived = useMemo(() => notSelected.filter(([, val]) => !val.archivedOn), [notSelected])

  const [assessmentModalIsOpen, setAssessmentModalIsOpen] = useState(false)
  return user ? (
    <VStack spacing={2} width="100%">
      {assessmentId ? (
        <>
          <Text fontWeight="semibold" color="gray.500">
            Selected assessment
          </Text>
          <AssessmentPreview
            id={assessmentId}
            selected
            buttonText="Open"
            onSelect={() => {
              setAssessmentModalIsOpen(true)
            }}
            user={user}
            assessment={selectedAssessmentData}
          />
          <AssessmentNote />
        </>
      ) : null}
      <Flex w="100%" direction="column" gap={1}>
        <Text fontWeight="semibold" color="gray.500">
          {assessmentId ? 'Other assessments' : 'Assessments'}
        </Text>
        {notArchived.map(([id, assessment]) => (
          <AssessmentPreview
            id={id}
            key={id}
            selected={id === assessmentId}
            buttonText={id === assessmentId ? 'Open' : 'Select'}
            onSelect={() => {
              if (id === assessmentId) setAssessmentModalIsOpen(true)
              else selectAssessment(id)
            }}
            user={user}
            assessment={assessment}
          />
        ))}
      </Flex>
      <ArchivedAssessments />
      {user && assessmentModalIsOpen ? (
        <AssessmentModal
          assessment={selectedAssessment}
          admin={admin}
          user={user}
          id={assessmentId}
          isOpen={assessmentModalIsOpen}
          onClose={() => setAssessmentModalIsOpen(false)}
        />
      ) : null}
    </VStack>
  ) : (
    <Text color="red.500">Error: no patient</Text>
  )
}

const SignOnInfoPanel = ({ mobileLayout }: { mobileLayout: boolean }) => {
  const { appName } = useApp()
  const {
    selectedAssessment: { ref, populated: selectedAssessment },
    assessmentId,
  } = useContext(UserContext)
  const { signOnData, signOnCorrections, selectedCoverage, insuranceCoverage } =
    selectedAssessment || {}
  const { memberId, 'plan-design-and-state-mandates': planDesignAndStateMandates } =
    selectedCoverage || {}
  const { planDesign } = planDesignAndStateMandates || {}
  const planDesignText = useMemo(() => {
    if (!planDesign) return ''
    return `${formatField(planDesignField, planDesign)}`
  }, [planDesign])
  const update = useUpdateDoc('Sign on data')
  const optionalSignOnFields = useMemo(() => makeAllFieldsOptional(signOnFields), [])

  return (
    <VStack w="100%">
      <Flex flexFlow={mobileLayout ? 'column' : 'row'} gap={2} align="center" w="100%">
        <Flex
          w={mobileLayout ? '100%' : 'auto'}
          gap={2}
          align="center"
          flex={mobileLayout ? 'unset' : 1}>
          <SignOnRemindersView />
          <HStack
            px={2}
            bg={colors.green.hex}
            flex={1}
            minW="0"
            py={1}
            color="white"
            textShadow="1px 1px 1px rgba(0,0,0,0.5)"
            fontFamily="Comfortaa"
            borderRadius={6}
            boxShadow="md">
            <span style={{ marginRight: '4px', fontWeight: 600 }}>
              {`${insuranceCoverage || '(No insurer set)'} Member ID:`}
            </span>
            <span style={{ fontFamily: 'Hero-New' }}>
              {typeof memberId === 'string' ? memberId || 'None' : 'None'}
              {planDesignText ? ` - ${planDesignText}` : ''}
            </span>
          </HStack>
        </Flex>
        {appName === 'app' ? <SkipToSignOn mobileLayout={mobileLayout} /> : null}
      </Flex>
      <Box w="100%" borderRadius={4} p={2} bg="white" boxShadow="md">
        <DataView
          initExpanded
          withEditModal
          adminView
          childrenExpanded
          onSubmit={data =>
            update(ref, 'signOnCorrections', addMetadata(data, appName, !signOnCorrections))
          }
          baseStoragePath={`${ASSESSMENTS}/${assessmentId}/signOnCorrections`}
          updateField={(path, value) => update(ref, `signOnCorrections.${path}`, value)}
          field={optionalSignOnFields}
          corrections={signOnCorrections}
          data={signOnData}
        />
      </Box>
      <InsuranceCardsView />
      {appName === 'app' ? <AssessmentFaxes /> : null}
    </VStack>
  )
}

const getTabs = (appName: string) => {
  const tabs: Array<string> = []
  tabs.push(appName === 'app' ? 'Assessment Info' : 'Insurance Info')
  tabs.push(appName === 'app' ? 'Sign On Info' : 'Assessment')
  if (appName === 'providers-app') tabs.push('Payments')
  tabs.push('Insurance Plans')
  return tabs
}
const DesktopTabs = ({ tabs }: { tabs: Array<string> }) => {
  return (
    <TabList>
      {tabs.map((tab, i) => (
        <Tab
          mr={3}
          key={i}
          background="white"
          fontSize="sm"
          _selected={{ background: colors.pink.hex, color: '#333' }}
          shadow="md">
          {tab}
        </Tab>
      ))}
    </TabList>
  )
}

export const AssessmentSummary = ({ width }: { width: number }) => {
  const { appName } = useApp()
  const tabs = useMemo(() => getTabs(appName), [appName])
  const mobileLayout = useMemo(() => width < 500, [width])
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <Box w="100%">
      <Tabs onChange={setTabIndex} index={tabIndex} size="sm" isLazy variant="soft-rounded">
        <Flex
          borderBottom={mobileLayout ? '1px solid #cdcdcd' : 'none'}
          bg={mobileLayout ? 'white' : 'transparent'}
          gap={0}
          px={mobileLayout ? 0 : 4}
          pt={mobileLayout ? 0 : 2}
          w="100%">
          {mobileLayout ? <MenuTabList tabs={tabs} /> : <DesktopTabs tabs={tabs} />}
        </Flex>
        <TabPanels>
          {appName === 'app' ? (
            <TabPanel>
              <AssessmentsView />
            </TabPanel>
          ) : (
            <TabPanel>
              <SignOnInfoPanel mobileLayout={mobileLayout} />
            </TabPanel>
          )}
          {appName === 'app' ? (
            <TabPanel>
              <SignOnInfoPanel mobileLayout={mobileLayout} />
            </TabPanel>
          ) : (
            <TabPanel>
              <AssessmentsView />
            </TabPanel>
          )}
          {appName === 'providers-app' ? (
            <TabPanel>
              <AssessmentPayments width={width} />
            </TabPanel>
          ) : null}
          <TabPanel p={mobileLayout ? 0 : 2}>
            <AdminInsurancePlansView />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

import React, { JSXElementConstructor, ReactElement } from 'react'
import { DefaultModal, DefaultModalProps } from '../Modals/DefaultModal'
import { EditorSize, useEditorWidth } from './hooks'

export const EditorModalContainer = ({
  render,
  contentProps,
  bodyProps,
  height,
  ...props
}: Omit<DefaultModalProps, 'render'> & {
  render: (
    editorSize: EditorSize,
    onBack: () => void,
  ) => ReactElement<any, string | JSXElementConstructor<any>>
  height?: number
}) => {
  const editorSize = useEditorWidth(undefined, height)

  return (
    <DefaultModal
      isCentered
      closeOnEsc={false}
      // closeOnOverlayClick={false}
      closeDisabled
      closeOnOverlayClick
      overlayHeader
      size="4xl"
      render={() => render(editorSize, props.onClose)}
      contentProps={{
        p: 0,
        width: 'auto',
        maxW: 'unset',
        margin: 'auto',
        height: 'auto',
        maxH: 'unset',
        ...contentProps,
      }}
      bodyProps={{
        p: 0,
        overflow: 'hidden',
        style: {
          width: editorSize.width,
          height: editorSize.height,
          ...bodyProps?.style,
        },
        ...bodyProps,
      }}
      {...props}
    />
  )
}

// TEMPLATES

import { DownloadIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react'
import { A4_DIMS_PIXELS, A4_MARGINS_PIXELS, defaultInitialValue, isConsentForm } from '@hb/shared'
import React, {
  forwardRef,
  KeyboardEvent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react'
import { downloadFromStorage } from '../../backend'
import { PopUpMessageContext } from '../../contexts'
import { pipe } from '../utils'
import { TextEditorContext, TextEditorContextValue } from './context'
import { DefaultRenderLeaf } from './DefaultRenderLeaf'
import { EditorToolbar } from './EditorToolbar'
import { downloadFromEditor } from './TemplateToolbar/utils'
import { ReadOnlyEditorProps, TextEditorProps } from './types'

import { CustomEditor, CustomRenderElementProps } from '../../types/editor'
import marginGuidelinesSvg from './assets/marginGuidelines.svg'
import marginGuidelinesTiledSvg from './assets/marginGuidelinesTiled.svg'
import { TextEditorDocumentFormProvider } from './DocumentFormProvider'
import { ImageElement } from './Elements/ImageElement'
import { LinkElement } from './Elements/LinkElement'
import { DefaultElement } from './Elements/SpanTemplateElement'
import { EditorFieldElementView } from './Elements/TemplateFieldElementView'
import { VariableElement } from './Elements/VariableElement'

export const DefaultRenderElement = (props: CustomRenderElementProps) => {
  switch (props.element.type) {
    case 'variable':
      return <VariableElement {...props} />
    case 'field':
      return <EditorFieldElementView {...props} />
    case 'image':
      return <ImageElement node={props.element} {...props} />
    case 'link':
      return <LinkElement {...props} />
    default:
      return <DefaultElement {...props} />
  }
}

export const ReadOnlyEditor: React.FC<ReadOnlyEditorProps> = props => (
  <TextEditor readOnly {...props} />
)

export const TextEditor = forwardRef<ReactEditor, PropsWithChildren<TextEditorProps>>(
  (
    {
      value,
      decorators,
      onChange,
      withDownload,
      document,
      onFormSubmit,
      toolbars,
      version,
      children,
      templateType,
      baseZoom,
      readOnly,
      height,
      style,
      width,
      smallMargins,
    }: TextEditorProps,
    ref,
  ) => {
    const { processResponse } = useContext(PopUpMessageContext)
    const [editor] = useState<CustomEditor>(
      decorators
        ? pipe(...decorators)(withReact(withHistory(createEditor())))
        : withReact(withHistory(createEditor())),
    )

    const [mode, setMode] = useState<'View' | 'Edit'>(readOnly ? 'View' : 'Edit')
    const [downloadLoading, setDownloadLoading] = useState(false)

    useImperativeHandle(ref, () => editor)

    const onDownload = useCallback(async () => {
      if (!withDownload) return
      if (document && isConsentForm(document) && !document.signedStoragePath) {
        processResponse({ error: 'Consent form not yet signed' })
        return
      }
      setDownloadLoading(true)
      const res =
        document && isConsentForm(document)
          ? await downloadFromStorage(document.signedStoragePath || '')
          : await downloadFromEditor(editor)
      processResponse(res)
      setDownloadLoading(false)
    }, [editor, processResponse, withDownload, document])

    const handleRenderElement = useCallback(
      (props: RenderElementProps) =>
        DefaultRenderElement({
          ...props,
          version,
          mode,
          readOnly: !!readOnly,
          editor,
        }),
      [version, mode, readOnly, editor],
    )

    const handleRenderLeaf = useCallback(
      (props: RenderLeafProps) =>
        DefaultRenderLeaf({
          ...props,
          mode,
          editor,
          version,
        }),
      [mode, editor, version],
    )

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          event.preventDefault()
          editor.insertText('\u2003'.toString())
        }
      },
      [editor],
    )

    const editorDomElement = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      const domElement = ReactEditor.toDOMNode(editor, editor) as HTMLDivElement

      const onResize = () => {
        const elementHeight = domElement.scrollHeight

        if (mode === 'Edit') {
          domElement.style.setProperty(
            'background',
            elementHeight > A4_DIMS_PIXELS[1]
              ? `url(${marginGuidelinesTiledSvg})`
              : `url(${marginGuidelinesSvg})`,
          )
        } else {
          domElement.style.setProperty('background', 'none')
        }
      }
      onResize()
      new ResizeObserver(onResize).observe(domElement)
      editorDomElement.current = domElement
    }, [editor, baseZoom, mode])

    const contextData = useMemo<TextEditorContextValue>(
      () => ({
        mode,
        document,
        editorDomElement,
        width,
        height,
        baseZoom,
      }),
      [document, mode, width, height, baseZoom],
    )

    const downloadText = useMemo(
      () =>
        document && isConsentForm(document)
          ? 'Downloading signed consent form...'
          : 'Generating PDF...',
      [document],
    )

    const editorPadding = useMemo(() => {
      if (smallMargins) {
        return `${A4_MARGINS_PIXELS[1] / 2}px ${A4_MARGINS_PIXELS[0] / 2}px`
      }
      return `${A4_MARGINS_PIXELS[1]}px ${A4_MARGINS_PIXELS[0]}px`
    }, [smallMargins])

    // const headerRef = useRef<HTMLDivElement>(null)
    // const { height: headerHeight } = useResizeObserver(headerRef, 'content')

    // const contentRef = useRef<HTMLDivElement>(null)
    // const { height: contentHeight } = useResizeObserver(contentRef, 'content')
    // const bodyHeight = useMemo(() => contentHeight - headerHeight, [contentHeight, headerHeight])

    return (
      <Slate
        initialValue={value?.length ? value : defaultInitialValue}
        onChange={onChange || (() => {})}
        editor={editor}>
        <TextEditorDocumentFormProvider
          readOnly={readOnly}
          onFormSubmit={onFormSubmit}
          document={document}
          height={height}
          style={style}>
          <TextEditorContext.Provider value={contextData}>
            <Flex flexFlow="column" position="relative" w="100%" h="100%">
              <Box
                // ref={headerRef}
                height="auto"
                background="whitesmoke"
                position="relative"
                zIndex={2}
                top={0}
                contentEditable={false}>
                {withDownload ? (
                  <Flex
                    pos="absolute"
                    alignItems="center"
                    bottom={readOnly ? '-40px' : '-32px'}
                    left={readOnly ? '8px' : '5px'}>
                    <Tooltip
                      hasArrow
                      placement="right"
                      bg="gray.600"
                      label={downloadLoading ? '' : 'Download'}>
                      <IconButton
                        size={readOnly ? 'sm' : 'xs'}
                        color="white"
                        isLoading={downloadLoading}
                        aria-label="download"
                        bg="blackAlpha.400"
                        _hover={{ bg: 'blackAlpha.600' }}
                        onClick={onDownload}
                        icon={<DownloadIcon />}
                      />
                    </Tooltip>
                    <Text
                      opacity={downloadLoading ? 1 : 0}
                      transition="opacity 500ms"
                      pointerEvents="none"
                      color="gray.400"
                      position="absolute"
                      right="120%"
                      whiteSpace="nowrap"
                      fontSize="xs">
                      {downloadText}
                    </Text>
                  </Flex>
                ) : null}
                {!readOnly ? (
                  <>
                    {children}
                    <EditorToolbar setMode={setMode} mode={mode} />
                    {toolbars
                      ? toolbars.map((T, index) => (
                          <T
                            document={document}
                            templateType={templateType}
                            version={version}
                            key={index}
                            mode={mode}
                          />
                        ))
                      : null}
                  </>
                ) : null}
              </Box>
              <Box
                position="relative"
                overflowY="auto"
                overflowX="hidden"
                w="100%"
                flex={1}
                minH="0">
                <Editable
                  readOnly={readOnly || mode === 'View'}
                  spellCheck={mode === 'Edit'}
                  renderLeaf={handleRenderLeaf}
                  renderElement={handleRenderElement}
                  onKeyDown={handleKeyDown}
                  style={{
                    position: 'absolute',
                    width: A4_DIMS_PIXELS[0],
                    minHeight: A4_DIMS_PIXELS[1],
                    height: 'auto',
                    padding: editorPadding,
                    boxSizing: 'border-box',
                    background: 'white',
                    left: 0,
                    top: 0,
                    transformOrigin: '0 0',
                    border: 'none',
                    transform: `scale(${baseZoom || 1})`,
                    transformBox: 'view-box',
                    // backgroundImage,
                    backgroundRepeat: 'repeat-y',
                    outline: 'none',
                  }}
                />
              </Box>
            </Flex>
            {/* {
                // guidelines for edit mode
                mode === 'Edit' ? (
                  <EditorMarginGuidelines/>
                ) : null
              } */}
          </TextEditorContext.Provider>
        </TextEditorDocumentFormProvider>
      </Slate>
    )
  },
)

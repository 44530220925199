import {
  AdminAssessmentData,
  AdminUserData,
  getFullName,
  getLogInfo,
  getReverseName,
  LogInfo,
  MIDWIVES,
  PopulatedUser,
  PopulatedUserData,
  PracticeUserData,
  User,
  UserInvite,
  USERS,
  USERS_ADMIN,
  USER_INVITES,
  USER_ROLES,
} from '@hb/shared'
import 'firebase/firestore'
import { useMemo } from 'react'
import { useApp } from '../../../contexts/AppContext'
import { useAuth } from '../../../store/auth'
import { useDocument } from '../useDocument'
import { usePatientClaims } from './usePatientClaims'

export const useLogInfo = (log?: AdminAssessmentData['log']) =>
  useMemo<LogInfo>(() => getLogInfo(log), [log])
export function usePopulatedUser(
  id?: string,
  disabled?: boolean,
  practiceId?: string,
): PopulatedUserData {
  const { authUser, loading: authLoading, admin } = useAuth()
  const { appName } = useApp()
  const userId = useMemo(() => (authUser ? id : null), [authUser, id])
  const { data: usersItem, loading: uLoading, ref: usersItemRef } = useDocument<User>(USERS, userId)
  const {
    data: adminItem,
    loading: aLoading,
    ref: adminPatientRef,
  } = useDocument<AdminUserData>(USERS_ADMIN, appName === 'providers-app' || !admin ? null : userId)
  const {
    data: inviteItem,
    loading: iLoading,
    ref: inviteRef,
  } = useDocument<UserInvite>(USER_INVITES, userId)

  const {
    data: practiceItem,
    loading: practiceItemLoading,
    ref: practicePatientRef,
  } = useDocument<PracticeUserData>(
    `${MIDWIVES}/${practiceId}/${USER_ROLES}`,
    practiceId ? userId : null,
  )

  const loading = useMemo(() => {
    if (!authUser || authLoading || aLoading || uLoading || iLoading || practiceItemLoading) {
      return true
    }
    return false
  }, [authLoading, aLoading, uLoading, iLoading, authUser, practiceItemLoading])
  const {
    item,
    ref: patientRef,
    isInvite,
  } = useMemo(() => {
    if (uLoading || disabled) return { item: null, ref: null, isInvite: false }
    if (usersItem) {
      return { item: usersItem, ref: usersItemRef, isInvite: false }
    }
    return { item: inviteItem, ref: inviteRef, isInvite: true }
  }, [usersItem, inviteItem, disabled, inviteRef, uLoading, usersItemRef])
  const { data: claims } = usePatientClaims(id)
  const user = useMemo<PopulatedUser | null>(() => {
    if (!id || !item) return null
    return {
      ...item,
      ...adminItem,
      patientNote: appName === 'providers-app' ? practiceItem?.patientNote : adminItem?.patientNote,
      adminAlerts: adminItem?.alerts,
      practiceAlerts: practiceItem?.alerts,
      isInvite,
      name: getFullName(item),
      reverseName: getReverseName(item),
      claims: claims || undefined,
      id,
    }
  }, [item, adminItem, claims, id, isInvite, practiceItem, appName])
  return useMemo(
    () => ({
      user,
      loading,
      patientRef,
      practicePatientRef,
      adminPatientRef,
    }),
    [user, loading, patientRef, adminPatientRef, practicePatientRef],
  )
}

import { deliveryStage } from '../../fields'
import { ShortcutArgs } from '../../types'
import { getDateString } from '../dates'
import { isField } from '../fields'
import { getFieldValue } from './utils'

export const getPregnancyValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  // ex: payments.received.0
  const { assessment } = shortcutArgs || {}
  const [, fieldPath] = path.split('.')
  const { selectedCoverage } = assessment || {}
  const firstVisitDate = selectedCoverage?.initialServiceDate
  switch (fieldPath) {
    case 'firstVisit':
      return firstVisitDate ? getDateString(firstVisitDate, 'short') : undefined
    default: {
      const field = deliveryStage.children[fieldPath]
      if (isField(field)) {
        return getFieldValue(field, fieldPath, assessment?.mergedData?.delivery)
      }
      return undefined
    }
  }
}

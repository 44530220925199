import { WarningIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  StackDivider,
  Tab,
  TabList,
  TabProps,
  Text,
  TextProps,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  Assessment,
  colors,
  FieldTypes,
  formatPhoneNumber,
  getAssessmentName,
  getDateString,
  getMedicaidCoverage,
  getPrimaryCoverage,
  getYearsSinceString,
  medicaidProvidersCollection,
  partnerEmailField,
  partnerNameField,
  partnerPhoneField,
  planDesignOptions,
  providersCollection,
  TextField,
} from '@hb/shared'

import useResizeObserver from '@react-hook/resize-observer'
import merge from 'lodash.merge'
import React, { useContext, useMemo, useRef, useState } from 'react'
import { AppContext, ScreenContext, useApp } from '../../../contexts'
import { UserContext } from '../../../contexts/UserContext'
import { useCreateAssessment } from '../../../hooks/backend/assessments'
import { useCollectionItem } from '../../../hooks/backend/useCollectionItem'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { useFormattedValue } from '../../../hooks/useFormattedValue'
import { AssessmentIcon } from '../../../icons/AssessmentIcon'
import {
  CoverageTerminationStatus,
  useTerminationStatus,
} from '../../Assessments/Coverage/TerminationStatus'
import { CopyTooltip } from '../../CopyTooltip'
import { ProfileRedFlagPopover } from '../../DataView/RedFlagPopover'
import { Expandable } from '../../Expandable'
import { EditableRow } from '../../forms/Input'
import { DefaultModal } from '../../Modals/DefaultModal'
import { PatientMidwifeInfo } from '../../Practices/MidwifeInfo'
import { AssessmentClearedSection } from './AssessmentClearedSection'
import { AddAssessmentAlert } from './Assessments/AddAssessmentAlert'
import { ReportDeliveryPopover } from './Assessments/ReportDeliveryPopover'
import { AssessmentThreadView } from './AssessmentThread'
import { UploadFilePopover } from './Files/UploadFilePopover'
import { MenuTabList } from './MenuTabList'
import { NameEditModal } from './NameEditModal'
import { PronounsView } from './Patient/PronounsView'
import { PatientStatusDropdown } from './PatientStatus'
import { AddPaymentPopover } from './Payments/AddPaymentPopover'
import { ProfileHeaderAlerts } from './ProfileHeaderAlerts'
import { ProfileSortColorPopover } from './SortColorPopover'
import { ProfileUrgentPopover } from './UrgentPopover'

const HeaderItem = (props: TextProps) => (
  <Flex px={1} whiteSpace="nowrap" fontSize="sm" color="#333" {...props} />
)

const SecondaryCoverageSummary = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(UserContext)
  const { signOnData, signOnCorrections, mergedData } = selectedAssessment || {}

  const { hasSecondaryCoverage, isMedicaid, insurerId, memberId } = useMemo(() => {
    const mergedSignOnData = merge(signOnData || {}, signOnCorrections || {})
    if (
      !mergedSignOnData?.hasSecondaryCoverage &&
      mergedData?.['insurance-info']?.option !== 'two-plans'
    )
      return { hasSecondaryCoverage: false }
    const details = mergedSignOnData.secondaryCoverageDetails
    // if (!details) return { hasSecondaryCoverage: true, memberId: 'None' }
    if (details) {
      if (details.isMedicaid) {
        return {
          hasSecondaryCoverage: true,
          isMedicaid: true,
          insurerId: details.medicaidInsurerId,
          memberId: details.memberId,
        }
      }
      return {
        hasSecondaryCoverage: true,
        insurerId: details.insurerId,
        memberId: details.memberId,
      }
    }
    const primaryIsMedicaid =
      mergedData?.['insurance-info']?.medicaidCoverage?.currentlyOnMedicaidPlan || false
    const secondaryCoverage = primaryIsMedicaid
      ? mergedData?.['insurance-info']?.primaryCoverage
      : mergedData?.['insurance-info']?.medicaidCoverage
    return {
      hasSecondaryCoverage: true,
      isMedicaid: !primaryIsMedicaid,
      memberId: secondaryCoverage?.memberId,
      insurerId: secondaryCoverage?.insuranceProviderId,
    }
  }, [signOnData, signOnCorrections, mergedData])
  const { item: insuranceProvider } = useCollectionItem(
    isMedicaid ? medicaidProvidersCollection : providersCollection,
    insurerId,
  )
  return hasSecondaryCoverage ? (
    <Box>
      <HeaderItem>
        <span style={{ marginRight: '4px', fontWeight: 600 }}>
          Secondary Coverage{isMedicaid ? ' (Medicaid)' : ''}
        </span>
        {insuranceProvider?.name || 'None'}
        {/* {midwife ? AdminPracticeData?.taxId?.main || 'None' : 'N/A'} */}
      </HeaderItem>
      <HeaderItem>
        <span style={{ marginRight: '4px', fontWeight: 600 }}>Secondary Coverage ID</span>
        {memberId || 'None'}
      </HeaderItem>
    </Box>
  ) : null
}

const AssessmentSelect = () => {
  const {
    selectAssessment,
    selectedAssessment: { populated: selectedAssessment },
    assessments,
  } = useContext(UserContext)

  const { isMobile } = useContext(ScreenContext)
  const { addLoading, confirmingNewAssessment, createAssessment, setConfirmingNewAssessment } =
    useCreateAssessment()

  const { archivedAssessments, nonArchivedAssessments } = useMemo(() => {
    const arr = Object.entries(assessments || {})
    const archived: { id: string; data: Assessment }[] = []
    const nonArchived: { id: string; data: Assessment }[] = []
    arr.forEach(([id, data]) => {
      if (data.archivedOn) {
        archived.push({ id, data })
      } else {
        nonArchived.push({ id, data })
      }
    })
    return {
      archivedAssessments: archived,
      nonArchivedAssessments: nonArchived,
    }
  }, [assessments])

  return (
    <>
      <Menu gutter={0} isLazy strategy="fixed" placement="bottom-start">
        <MenuButton shadow="inner" bg="white" minW="0" isLoading={addLoading} px={2} as={Button}>
          <Flex align="center">
            {selectedAssessment ? <AssessmentIcon /> : null}
            <Text
              lineHeight={1}
              py={1}
              fontSize="sm"
              maxW={isMobile ? '100%' : '175px'}
              isTruncated>
              {selectedAssessment ? getAssessmentName(selectedAssessment) : 'Select an assessment'}
            </Text>
          </Flex>
        </MenuButton>
        <Portal>
          <MenuList bg="gray.100" minW="300px" py={0} overflowY="auto" maxH="350px">
            {nonArchivedAssessments?.length
              ? nonArchivedAssessments.map(({ id, data }, i) => (
                  <MenuItem
                    key={`${data.createdOn || i}`}
                    onClick={() => selectAssessment(id)}
                    _hover={{ bg: 'rgb(245,245,245)' }}
                    bg="white"
                    borderBottom="1px solid #cdcdcd"
                    px={3}
                    py={2}>
                    <AssessmentIcon />
                    {getAssessmentName(data)}
                  </MenuItem>
                ))
              : null}
            {archivedAssessments?.length ? (
              <Expandable
                header={() => (
                  <Text px={2} py={1} color="red.600" fontSize="sm" fontWeight={600}>
                    ARCHIVED
                  </Text>
                )}>
                {archivedAssessments.map(({ id, data }, i) => (
                  <MenuItem
                    key={`${data.createdOn || i}`}
                    borderTop="1px solid #cdcdcd"
                    onClick={() => selectAssessment(id)}
                    bg="white"
                    _hover={{ bg: 'rgb(245,245,245)' }}
                    px={3}
                    py={2}>
                    <AssessmentIcon />
                    {getAssessmentName(data)}
                  </MenuItem>
                ))}
              </Expandable>
            ) : null}
            <MenuItem
              borderTop="1px solid #cdcdcd"
              bg={colors.green.hex}
              _hover={{ bg: 'green.400' }}
              color="white"
              fontFamily="Comfortaa"
              fontSize="sm"
              onClick={() => setConfirmingNewAssessment(true)}>
              + NEW PREGNANCY
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
      <AddAssessmentAlert
        onConfirm={createAssessment}
        isOpen={confirmingNewAssessment}
        onCancel={() => setConfirmingNewAssessment(false)}
      />
    </>
  )
}
const MidwifeRedFlagPopver = ({ redFlaggedReason }: { redFlaggedReason?: string }) => (
  <Popover trigger="hover">
    <PopoverTrigger>
      <WarningIcon
        ml={3}
        w={4}
        h={4}
        color={redFlaggedReason ? 'red.600' : 'gray.400'}
        cursor="pointer"
      />
    </PopoverTrigger>
    <PopoverContent w="auto">
      <PopoverArrow />
      {redFlaggedReason ? (
        <PopoverHeader fontWeight={600} color="red.600">
          RED FLAG
        </PopoverHeader>
      ) : null}
      <PopoverBody w="auto" minW="0" maxW="400px" p={0}>
        <Text
          whiteSpace="pre-wrap"
          p={2}
          fontSize="sm"
          color={redFlaggedReason ? 'red.600' : 'gray.600'}>
          {redFlaggedReason || 'Not red flagged'}
        </Text>
      </PopoverBody>
    </PopoverContent>
  </Popover>
)

const PartnerPopover = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(UserContext)
  const { mergedSignOnData } = selectedAssessment || {}
  const { hasPartner, partnerContact } = mergedSignOnData || {}
  const { name, email, phone } = partnerContact || {}
  return hasPartner ? (
    <Popover trigger="hover">
      <PopoverTrigger>
        <IconButton
          size="sm"
          variant="ghost"
          _hover={{ bg: 'whiteAlpha.500' }}
          borderRadius="full"
          aria-label="Partner"
          icon={<Image src="/images/svg/family.svg" width="24px" opacity={0.5} />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <HStack bg="gray.100" w="100%" borderBottom="1px solid #cdcdcd">
          <Text color="gray.600" fontWeight={600} fontSize="lg" px={2}>
            Partner Info
          </Text>
        </HStack>
        <VStack spacing={0} width="100%">
          <EditableRow
            index={0}
            label="Partner Name"
            value={name}
            parentValue={mergedSignOnData}
            field={partnerNameField}
          />
          <EditableRow
            index={1}
            label="Partner Email"
            value={email}
            parentValue={mergedSignOnData}
            field={partnerEmailField}
          />
          <EditableRow
            index={1}
            label="Partner Phone"
            value={phone}
            parentValue={mergedSignOnData}
            field={partnerPhoneField}
          />
        </VStack>
      </PopoverContent>
    </Popover>
  ) : null
}
const PatientHeaderRow = ({ width }: { width: number }) => {
  const {
    selectedAssessment: { practiceRef, snippetRef, populated: selectedAssessment },
    user,
    patientRef,
  } = useContext(UserContext)
  const { appName } = useContext(AppContext)
  const { nickname, fname, lname, pronouns } = user || {}
  const { midwife, redFlags } = selectedAssessment || {}
  const [showMidwife, setShowMidwife] = useState(false)
  const [nameModalOpen, setNameModalOpen] = useState(false)
  const update = useUpdateDoc()
  const midwifeName = useMemo(
    () => (midwife?.fname && midwife?.lname ? `${midwife.fname} ${midwife.lname}` : ''),
    [midwife],
  )

  const isMobile = useMemo(() => width < 1000, [width])
  const name = useMemo(
    () => `${fname || '[Missing first name]'} ${lname || '[Missing Last Name]'}`,
    [fname, lname],
  )

  return (
    <>
      <Stack
        borderBottom="1px solid #cdcdcd"
        divider={<StackDivider borderColor="blackAlpha.400" />}
        // align='center'
        direction="row"
        flexFlow={isMobile ? 'column' : 'row'}
        bg="#FFC7BC"
        spacing={isMobile ? 1 : 3}
        py={2}
        minH="60px"
        align={isMobile ? 'flex-start' : 'center'}
        px={4}
        // pb={0}
      >
        <HStack py={0.5}>
          <Flex>
            {appName === 'app' && snippetRef && selectedAssessment ? (
              <ProfileUrgentPopover />
            ) : null}
            {appName === 'providers-app' && practiceRef && selectedAssessment ? (
              <ProfileSortColorPopover />
            ) : null}
          </Flex>
          <Tooltip placement="top" hasArrow label="Edit name">
            <Button
              onClick={() => setNameModalOpen(true)}
              variant="link"
              fontSize="md"
              fontFamily="Hero-New"
              py={2}
              fontWeight={500}
              color="gray.700">
              {name || 'No name entered'}
              {nickname ? ` (${nickname})` : ''}
            </Button>
          </Tooltip>
          <PartnerPopover />
          {pronouns ? <PronounsView /> : null}
        </HStack>
        <Stack
          flexFlow={['column', 'column', 'column', 'row']}
          w={isMobile ? '100%' : 'auto'}
          pl={1}
          spacing={isMobile ? 1 : 3}>
          <AssessmentSelect />
          <Flex gap={2} align="center">
            {selectedAssessment && appName === 'app' ? (
              <Flex align="center">
                <Button
                  fontWeight={400}
                  color="blackAlpha.700"
                  bg="whiteAlpha.800"
                  _hover={{ bg: 'white' }}
                  style={{ alignItems: 'center' }}
                  onClick={() => setShowMidwife(true)}
                  alignItems="flex-start"
                  display="flex"
                  p={2}
                  variant="unstyled">
                  <VStack align="flex-start" spacing={0}>
                    <Text
                      fontFamily="Comfortaa"
                      lineHeight={1}
                      fontWeight={600}
                      isTruncated
                      fontSize="md"
                      maxW="250px">
                      {midwifeName}
                    </Text>
                    <Text lineHeight={1} isTruncated fontSize="sm" maxW="250px">
                      {midwife?.name || 'No practice name'}
                    </Text>
                  </VStack>
                  {appName === 'app' && midwife?.redFlaggedReason ? (
                    <MidwifeRedFlagPopver redFlaggedReason={midwife?.redFlaggedReason} />
                  ) : null}
                </Button>
              </Flex>
            ) : null}
            {appName === 'app' && selectedAssessment && redFlags ? (
              <Box>
                <ProfileRedFlagPopover redFlags={redFlags} />
              </Box>
            ) : null}
          </Flex>
        </Stack>
        {selectedAssessment ? (
          <HStack w={isMobile ? '100%' : 'auto'} pr={isMobile ? 0 : 10} ml={isMobile ? 0 : 'auto'}>
            {isMobile ? (
              <Flex gap={2} align="center" ml="auto">
                <ProfileHeaderAlerts />
                <ReportDeliveryPopover mobileLayout={isMobile} />
              </Flex>
            ) : (
              <ProfileHeaderAlerts />
            )}
          </HStack>
        ) : null}
        <Flex justify="center" gap={2} align="center">
          {appName === 'providers-app' ? <AddPaymentPopover /> : null}
          {appName === 'providers-app' ? <UploadFilePopover /> : null}
          {isMobile ? null : <ReportDeliveryPopover mobileLayout={isMobile} />}
        </Flex>
      </Stack>
      {showMidwife ? (
        <DefaultModal
          overlayHeader
          size="4xl"
          contentProps={{ p: 0 }}
          isOpen={showMidwife}
          onClose={() => setShowMidwife(false)}
          render={() => <PatientMidwifeInfo />}
        />
      ) : null}
      <NameEditModal
        userType="patient"
        value={user}
        isOpen={nameModalOpen}
        onClose={() => setNameModalOpen(false)}
        onSubmit={v => {
          return update(patientRef, '', v)
        }}
      />
    </>
  )
}

const midwifeTaxIdField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Midwife Tax ID',
}
const midwifeNPIField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Midwife NPI',
}

const ArchivedByOtherPreview = () => {
  const { selectedAssessment } = useContext(UserContext)
  const { practiceData, assessmentData } = selectedAssessment || {}
  const { appName } = useApp()
  const archivedByOther = appName === 'app' ? practiceData?.archivedOn : assessmentData?.archivedOn
  if (!archivedByOther) return null
  return (
    <Box>
      <Tooltip
        placement="top"
        bg="red.600"
        textShadow="1px 1px 3px #00000055"
        hasArrow
        label={`Archived by ${appName === 'app' ? 'practice' : 'HB'} ${getDateString(archivedByOther, 'short')}`}>
        <Badge bg="red.600" color="white" textShadow="1px 1px 3px #00000055">
          Archived by {appName === 'app' ? 'practice' : 'HB'}
        </Badge>
      </Tooltip>
    </Box>
  )
}

const AssessmentHeaderRow = ({ width }: { width: number }) => {
  const {
    selectedAssessment: { populated: selectedAssessment },
    user,
  } = useContext(UserContext)
  const {
    midwife,
    insuranceProvider,
    data,
    corrections,
    deliveredOn,
    edd,
    signOnCorrections,
    signOnData,
    planState,
    selectedCoverage,
  } = selectedAssessment || {}
  const { dob } = user || {}

  const { appName } = useApp()
  const {
    memberId,
    insuranceProviderNumber,
    'plan-design-and-state-mandates': planDesign,
  } = selectedCoverage || {}
  const planDesignText = useMemo(() => {
    if (!planDesign?.planDesign) return ''
    return planDesignOptions.find(p => p.id === planDesign.planDesign)?.text || ''
  }, [planDesign])

  const hasSecondaryCoverage = useMemo(() => {
    if (signOnCorrections?.hasSecondaryCoverage !== undefined)
      return signOnCorrections?.hasSecondaryCoverage
    if (signOnData?.hasSecondaryCoverage !== undefined) return signOnData?.hasSecondaryCoverage
    if (corrections?.['insurance-info']?.option !== undefined)
      return corrections?.['insurance-info']?.option === 'two-plans'
    if (data?.['insurance-info']?.option !== undefined)
      return data?.['insurance-info']?.option === 'two-plans'
    return false
  }, [signOnCorrections, signOnData, data, corrections])
  const weeksIn = useMemo(() => {
    if (deliveredOn === Infinity) return undefined
    if (edd) {
      const delivered = deliveredOn || Date.now()
      // start date is 40 weeks before edd
      const startDate = edd - 1000 * 60 * 60 * 24 * 7 * 40
      const difference = Math.floor((delivered - startDate) / (1000 * 60 * 60 * 24 * 7))
      return difference > 0 ? difference : undefined
    }
    return undefined
  }, [edd, deliveredOn])

  const { name: insuranceProviderName } = insuranceProvider || {}

  const { value: midwifeTaxId } = useFormattedValue(midwifeTaxIdField, midwife?.taxId)

  const { value: midwifeNPI } = useFormattedValue(midwifeNPIField, midwife?.npi)

  const coveragePrefix = useMemo(() => {
    let text = planDesignText || ''
    if (planState) text += `${planDesignText ? ' ' : ''}(${planState})`
    return text
  }, [planDesignText, planState])
  const coverageText = useMemo(
    () => `${memberId || 'None'}${coveragePrefix ? ` - ${coveragePrefix}` : ''}`,
    [memberId, coveragePrefix],
  )

  const isMobile = useMemo(() => width < 1000, [width])
  const primaryCoverage = useMemo(
    () => getPrimaryCoverage(selectedAssessment?.mergedData),
    [selectedAssessment],
  )

  const medicaidCoverage = useMemo(
    () => getMedicaidCoverage(selectedAssessment?.mergedData),
    [selectedAssessment],
  )
  const primaryCoverageTerminationStatus = useTerminationStatus(primaryCoverage)
  const medicaidCoverageTerminationStatus = useTerminationStatus(medicaidCoverage)

  return (
    <Stack
      borderBottom="1px solid #cdcdcd"
      // align='center'
      direction={isMobile ? 'column' : 'row'}
      bg="gray.100"
      width="100%"
      maxW="100%"
      align="center"
      spacing={0}>
      <Stack
        px={2}
        py={1}
        spacing={1}
        direction={['column', 'column', 'row']}
        w={isMobile ? '100%' : 'auto'}
        divider={<StackDivider borderColor="blackAlpha.500" />}>
        <Flex w={isMobile ? '100%' : 'auto'}>
          <Box flex={isMobile ? 1 : 'unset'} minW="0" w={isMobile ? 'unset' : 'auto'}>
            <HeaderItem>
              <span style={{ marginRight: '4px', fontWeight: 600 }}>DOB</span>
              <CopyTooltip label={dob ? getDateString(dob, 'short') : 'None'}>
                {dob
                  ? `${getDateString(dob, 'short')} (${getYearsSinceString(dob)} years)`
                  : 'None'}
              </CopyTooltip>
            </HeaderItem>
            {deliveredOn ? (
              <HeaderItem>
                <span style={{ fontWeight: 600, marginRight: '4px' }}>Delivered</span>
                <CopyTooltip label={getDateString(deliveredOn, 'short')}>
                  <Text>
                    {deliveredOn === Infinity ? 'Transfer' : getDateString(deliveredOn, 'short')}
                    {weeksIn ? ` - ${weeksIn} weeks` : ''}
                  </Text>
                </CopyTooltip>
              </HeaderItem>
            ) : (
              <HeaderItem>
                <span style={{ fontWeight: 600, marginRight: '4px' }}>EDD</span>
                <CopyTooltip label={edd ? getDateString(edd, 'short') : 'None'}>
                  <Text>{edd ? `${getDateString(edd, 'short')} (${weeksIn} weeks)` : 'None'}</Text>
                </CopyTooltip>
              </HeaderItem>
            )}
          </Box>
        </Flex>
        {appName === 'app' ? (
          <Box>
            <HeaderItem maxW="270px">
              <CopyTooltip prefix={coveragePrefix} label={memberId || ''}>
                <Text maxW="270px" isTruncated>
                  <span style={{ marginRight: '4px', fontWeight: 600 }}>
                    {`${insuranceProviderName || 'Member'} ID `}
                  </span>
                  {coverageText}
                </Text>
              </CopyTooltip>
            </HeaderItem>
            <HeaderItem>
              <span style={{ marginRight: '4px', fontWeight: 600 }}>
                {insuranceProviderName || 'Insurance Provider '} Phone
              </span>
              <CopyTooltip
                label={
                  insuranceProviderNumber ? formatPhoneNumber(insuranceProviderNumber) : 'None'
                }>
                <Text>
                  {insuranceProviderNumber ? formatPhoneNumber(insuranceProviderNumber) : 'None'}
                </Text>
              </CopyTooltip>
            </HeaderItem>
          </Box>
        ) : null}
        {appName === 'app' ? (
          <Box>
            <HeaderItem>
              <span style={{ marginRight: '4px', fontWeight: 600 }}>Midwife Tax ID</span>
              <CopyTooltip label={midwifeTaxId}>
                <Text>{midwifeTaxId}</Text>
              </CopyTooltip>
              {/* {midwife ? AdminPracticeData?.taxId?.main || 'None' : 'N/A'} */}
            </HeaderItem>
            <HeaderItem>
              <span style={{ marginRight: '4px', fontWeight: 600 }}>Midwife NPI</span>
              <CopyTooltip label={midwifeNPI}>
                <Text>{midwifeNPI}</Text>
              </CopyTooltip>
              {/* {midwife ? AdminPracticeData?.taxId?.main || 'None' : 'N/A'} */}
            </HeaderItem>
          </Box>
        ) : null}
        {hasSecondaryCoverage ? <SecondaryCoverageSummary /> : null}
        {primaryCoverageTerminationStatus || medicaidCoverageTerminationStatus ? (
          <Box>
            <HeaderItem>
              <Flex gap={1} align="center">
                <CoverageTerminationStatus
                  wrap
                  coverage={primaryCoverage}
                  terminationStatus={primaryCoverageTerminationStatus}
                />
                <CoverageTerminationStatus
                  wrap
                  coverage={medicaidCoverage}
                  terminationStatus={medicaidCoverageTerminationStatus}
                />
              </Flex>
            </HeaderItem>
          </Box>
        ) : null}
      </Stack>
      <HStack
        borderTop={isMobile ? '1px solid #cdcdcd' : 'none'}
        justify="flex-end"
        minW="0"
        w={isMobile ? '100%' : 'auto'}
        flex={1}
        px={2}
        py={1}
        spacing={3}>
        {isMobile ? null : <ArchivedByOtherPreview />}
        <Box mr={isMobile ? 'auto' : 0} minW="0">
          <AssessmentThreadView />
        </Box>
        <Box>
          <AssessmentClearedSection />
        </Box>
        {isMobile ? null : <PatientStatusDropdown />}
      </HStack>
      {isMobile ? (
        <Flex px={2} w="100%" justify="space-between" align="center" gap={2} pb={1}>
          <ArchivedByOtherPreview />
          <PatientStatusDropdown isMobile />
        </Flex>
      ) : null}
    </Stack>
  )
}

const ProfileTab = (props: TabProps) => {
  const { isMobile } = useContext(ScreenContext)
  return (
    <Tab
      py={isMobile ? 1 : 0}
      minW="30px"
      color="gray.600"
      borderRadius="full"
      fontSize={['xs', 'sm']}
      lineHeight={1}
      _selected={{
        background: colors.pink.hex,
        fontWeight: 600,
        color: '#333',
      }}
      {...props}
    />
  )
}

const DesktopHeaderTabs = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(UserContext)
  const { appName } = useApp()
  return (
    <TabList height={'36px'} p={1} overflowX="auto" overflowY="hidden" bg="gray.50" maxW="100%">
      <ProfileTab>{appName === 'app' ? 'Patient' : 'Patient / Pregnancy'}</ProfileTab>
      {selectedAssessment ? (
        <ProfileTab>{appName === 'app' ? 'Assessment' : 'Billing / Insurance'}</ProfileTab>
      ) : null}
      {appName === 'app' && selectedAssessment ? (
        <>
          <ProfileTab>Payments</ProfileTab>
          <ProfileTab>Authorizations</ProfileTab>
          <ProfileTab>Claims / Charting</ProfileTab>
        </>
      ) : null}
      {selectedAssessment ? <ProfileTab>Files</ProfileTab> : null}
      {selectedAssessment ? <ProfileTab>Visits</ProfileTab> : null}
    </TabList>
  )
}

const MobileHeaderTabs = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(UserContext)
  const { appName } = useApp()
  const tabs = useMemo<Array<string>>(() => {
    const tabs: Array<string> = []
    tabs.push(appName === 'app' ? 'Patient' : 'Patient / Pregnancy')
    if (selectedAssessment) tabs.push(appName === 'app' ? 'Assessment' : 'Billing / Insurance')
    if (appName === 'app' && selectedAssessment) {
      tabs.push('Payments')
      tabs.push('Authorizations')
      tabs.push('Claims / Charting')
    }
    if (selectedAssessment) tabs.push('Files')
    if (selectedAssessment) tabs.push('Visits')
    return tabs
  }, [appName, selectedAssessment])

  return (
    <Flex borderBottom="1px solid #cdcdcd" bg={`${colors.green.hex}33`} w="100%">
      <MenuTabList tabs={tabs} />
    </Flex>
  )
}

export const ProfileHeader = ({
  onResize,
  width,
}: {
  onResize: (size: { height: number; width: number }) => void
  width: number
}) => {
  const { isMobile } = useContext(ScreenContext)
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(UserContext)
  const contentRef = useRef<HTMLDivElement>(null)
  useResizeObserver(contentRef, ({ contentRect }) => {
    onResize({ height: contentRect.height, width: contentRect.width })
  })
  return (
    <Flex ref={contentRef} flexFlow="column" width="100%" zIndex={2} top={0}>
      <PatientHeaderRow width={width} />
      <Collapse
        style={{ width: '100%', maxWidth: '100%', display: 'flex' }}
        in={!!selectedAssessment}>
        <AssessmentHeaderRow width={width} />
      </Collapse>
      {isMobile ? <MobileHeaderTabs /> : <DesktopHeaderTabs />}
    </Flex>
  )
}

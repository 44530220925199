import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'
import {
  DropdownField,
  ExternalPdfFieldType,
  FieldTypes,
  getUniqueRandomId,
  InlineFilePlacement,
  TemplateAttachedInput,
  TextField,
} from '@hb/shared'
import React, { useCallback, useEffect, useState } from 'react'
import { Editable, StandaloneInput } from '../../forms'
import { usePdfView } from '../../PdfView/context'
import { getInlineSize } from '../../PdfView/utils'
import { CollapseHorizontal } from '../../shared'
import { useExternalPdfTemplateEditor } from './context'

const addField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Add Field',
  options: [
    {
      id: FieldTypes.TEXT,
      text: 'Text Field',
    },
    {
      id: FieldTypes.DATE,
      text: 'Date Field',
    },
    {
      id: FieldTypes.SIGNATURE,
      text: 'Signature Field',
    },
    {
      id: FieldTypes.INITIALS,
      text: 'Initials Field',
    },
  ],
  optional: true,
}

const placeholderField: TextField = {
  placeholder: 'Field Label',
  type: FieldTypes.TEXT,
  optional: true,
}

const PageSelect = ({
  pageIndex,
  setPageIndex,
}: {
  pageIndex: number
  setPageIndex: (index: number) => void
}) => {
  const { numPages } = usePdfView()
  return (
    <Box ml={1.5}>
      <Menu size="sm" strategy="fixed" placement="bottom-start">
        <MenuButton variant="ghost" size="xs" as={Button} rightIcon={<ChevronDownIcon />}>
          TO PAGE {pageIndex + 1}
        </MenuButton>
        <Portal>
          <MenuList
            w="100px"
            minW="0"
            bg="gray.50"
            boxShadow="0 0 4px #00000066"
            border="1px solid #cdcdcd"
            borderRadius={6}
            p={0}>
            {Array.from({ length: numPages || 0 }).map((_, i) => (
              <MenuItem
                bg="transparent"
                py={1}
                px={2}
                borderTop={i === 0 ? 'none' : '1px solid #cdcdcd'}
                _hover={{ bg: 'whiteAlpha.500' }}
                fontSize="sm"
                key={i}
                onClick={() => setPageIndex(i)}>
                Page {i + 1}
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  )
}

export const ExternalPdfAddField = () => {
  const { updateExternalPdf, externalPdf } = useExternalPdfTemplateEditor()

  const { scrollPageIndex } = usePdfView()

  const [pageIndex, setPageIndex] = useState(scrollPageIndex)
  useEffect(() => {
    setPageIndex(scrollPageIndex)
  }, [scrollPageIndex])
  const [addedFieldType, setAddedFieldType] = useState<ExternalPdfFieldType | null>(null)
  const handleAdd = useCallback(
    (type: ExternalPdfFieldType, placeholder: string) => {
      const newAttachment: TemplateAttachedInput<InlineFilePlacement> = {
        id: getUniqueRandomId(Object.keys(externalPdf.attachments || {})),
        field: {
          type,
          placeholder,
        },
        placement: {
          type: 'inline',
          pageIndex,
          orientation: {
            position: [0, 0],
            scale: [1, 1],
            rotation: 0,
          },
        },
        type: 'input',
      }
      const defaultSize = getInlineSize(newAttachment)
      newAttachment.placement.orientation.position = [defaultSize[0] / 2, defaultSize[1] / 2]
      updateExternalPdf({
        ...externalPdf,
        attachments: {
          ...externalPdf.attachments,
          [newAttachment.id]: newAttachment,
        },
      })
    },
    [externalPdf, updateExternalPdf, pageIndex],
  )

  const handleAddConfirm = useCallback(
    async (placeholder: string) => {
      if (addedFieldType) {
        handleAdd(addedFieldType, placeholder)
        setAddedFieldType(null)
      }
      return { success: 'added' }
    },
    [addedFieldType, handleAdd],
  )

  const handleAddClick = useCallback(
    (type: ExternalPdfFieldType) => {
      switch (type) {
        case FieldTypes.TEXT:
          setAddedFieldType(FieldTypes.TEXT)
          break
        case FieldTypes.DATE:
          handleAdd(type, 'Date')
          break
        case FieldTypes.SIGNATURE:
          handleAdd(type, 'Signature')
          break
        case FieldTypes.INITIALS:
          handleAdd(type, 'Initials')
          break
      }
    },
    [handleAdd],
  )

  return (
    <Flex w="100%" align="center">
      <CollapseHorizontal width={240} in={!addedFieldType}>
        <Flex position="relative" top="2px" h="32px" align="center" w="240px">
          <StandaloneInput
            style={{ padding: '0.3rem 0.6rem', border: '1px solid #cdcdcd' }}
            onChange={handleAddClick}
            field={addField}
          />
        </Flex>
      </CollapseHorizontal>
      <CollapseHorizontal width={240} in={!!addedFieldType}>
        <Box position="relative" top="2px" w="240px">
          <Flex h="32px" align="center" w="240px">
            {addedFieldType ? (
              <Editable
                closeCallback={() => setAddedFieldType(null)}
                initEditing
                style={{ background: 'transparent' }}
                inputStyle={{
                  padding: '0.2rem 0.6rem',
                  position: 'relative',
                  top: '1px',
                  border: '1px solid #cdcdcd',
                  background: 'white',
                }}
                value={null}
                onSubmit={handleAddConfirm}
                field={placeholderField}
              />
            ) : null}
          </Flex>
        </Box>
      </CollapseHorizontal>
      <PageSelect pageIndex={pageIndex} setPageIndex={setPageIndex} />
      {/* <Text lineHeight={1} fontSize="xs" ml={2} color="gray.500">
        TO PAGE {scrollPageIndex + 1}
      </Text> */}
    </Flex>
  )
}

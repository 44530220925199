import { DeleteIcon } from '@chakra-ui/icons'
import { Center, IconButton } from '@chakra-ui/react'
import React, { PropsWithChildren, useMemo } from 'react'
import { GestureContainerProvider, useGestureContainer } from './context'

const DeleteButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <IconButton
      position="absolute"
      bottom="-6px"
      right="-6px"
      borderRadius="full"
      aria-label="Delete"
      boxShadow="1px 1px 3px #00000077"
      size="none"
      w="20px"
      h="20px"
      bg="red.200"
      icon={<DeleteIcon w="12px" h="12px" color="red.600" />}
      onClick={onClick}
      variant="ghost"
      colorScheme="red"
    />
  )
}

const BaseGestureOverlayContainer = ({
  children,
  onDelete,
}: PropsWithChildren<{ onDelete?: () => void }>) => {
  const { containerRef } = useGestureContainer()
  const { width } = useGestureContainer()
  const strokeWidth = useMemo(() => Math.min(1, width / 200), [width])
  return (
    <Center
      ref={containerRef}
      userSelect="none"
      position="absolute"
      zIndex={2}
      left="0"
      top="0"
      bg="blackAlpha.300"
      w="100%"
      h="100%">
      <div className="resize-container-inner">
        <div className="animated-dashed-border">
          <svg viewBox="0 0 101 101" preserveAspectRatio="none">
            <path
              vectorEffect="non-scaling-stroke"
              strokeWidth={strokeWidth}
              d="M 0,0 L 100,0 L 100,100 L 0,100 Z"
            />
          </svg>
        </div>
      </div>
      {children}
      {onDelete ? <DeleteButton onClick={onDelete} /> : null}
    </Center>
  )
}

export const GestureOverlayContainer = ({
  children,
  onDelete,
}: PropsWithChildren<{ onDelete?: () => void }>) => (
  <GestureContainerProvider>
    <BaseGestureOverlayContainer onDelete={onDelete}>{children}</BaseGestureOverlayContainer>
  </GestureContainerProvider>
)

import { CloseIcon, InfoIcon } from '@chakra-ui/icons'
import { Box, Collapse, Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { defaultFieldValidate, DropdownField, enrollmentTypeField } from '@hb/shared'
import {
  MultiFactorInfo,
  PhoneMultiFactorGenerator,
  PhoneMultiFactorInfo,
  TotpMultiFactorInfo,
} from 'firebase/auth'
import React, { useCallback, useMemo, useState } from 'react'
import { useMultiFactorOpen } from '../../../hooks'
import { useAppModals } from '../../../store'
import { signOut, useAuth } from '../../../store/auth'
import { EnrolledFactorsView, MultiFactorInfoView } from '../../Auth/MultiFactorInfoView'
import { ActionButton, SolidActionButton } from '../../Buttons'
import { CollapseError } from '../../CollapseError'
import { StandaloneInput } from '../../forms'
import { DefaultModal } from '../DefaultModal'
import { AuthenticatorVerify } from './AuthenticatorVerify'
import { PhoneEnroll } from './PhoneEnroll'
import { PhoneVerify } from './PhoneVerify'
import { StepNumberSpan } from './StepNumberSpan'
import { TotpEnroll } from './TotpEnroll'

const ClearCacheNote = () => (
  <Box px={3} py={2} bg="white" borderRadius={6} border="1px solid #cdcdcd">
    <Text fontFamily="Hero-New" fontSize="sm" color="gray.600">
      <InfoIcon color="gray.500" /> Hamilton Billing recently added two factor authentication. If
      you are experiencing errors, try clearing your cache and refreshing the page as you may be
      using an outdated version of the site.
    </Text>
  </Box>
)

const TwoFactorInfoView = () => {
  const [manuallyEnrolling, setManuallyEnrolling] = useState(false)
  const [selectedEnrollmentFactorType, setSelectedEnrollmentFactorType] = useState<
    MultiFactorInfo['factorId'] | null
  >(null)

  const authUser = useAuth(s => s.authUser)

  const enrolledFactors = useAuth(s => s.enrolledFactors)
  const onClose = useCallback(() => {
    if (enrolledFactors?.length) {
      useAuth.setState({ enrollingUser: null })
      useAppModals.getState().multiFactorAuth.close()
    } else {
      signOut()
    }
  }, [enrolledFactors])

  const shouldEnroll = useMemo(
    () => !enrolledFactors?.length || manuallyEnrolling,
    [enrolledFactors, manuallyEnrolling],
  )

  const typeField = useMemo<DropdownField>(
    () => ({
      ...enrollmentTypeField,
      validate: (v: string) => {
        if (!v) return undefined
        if (v === 'totp' && enrolledFactors?.some(f => f.factorId === 'totp'))
          return 'For security, you are limited to one Authenticator App per account'
        return undefined
      },
    }),
    [enrolledFactors],
  )

  const enrollmentTypeError = useMemo(
    () => defaultFieldValidate(typeField, selectedEnrollmentFactorType),
    [selectedEnrollmentFactorType, typeField],
  )

  return (
    <VStack spacing={0} w="100%" align="flex-start">
      <HStack w="100%">
        <Text fontFamily="Hero-New" fontSize="lg" color="gray.600">
          Two-Factor Authentication
        </Text>
        <ActionButton
          ml="auto"
          size="xs"
          bg="white"
          border="1px solid #cdcdcd"
          color="gray.500"
          gap={1}
          onClick={onClose}
          _hover={{
            bg: 'gray.100',
          }}>
          <CloseIcon w={2} />
          {enrolledFactors?.length ? 'Close' : 'Close and Sign Out'}
        </ActionButton>
      </HStack>
      <Text py={1} fontFamily="Hero-New" fontSize="sm" color="gray.600">
        Hamilton Billing uses two-factor authentication to keep your account secure.
      </Text>
      <ClearCacheNote />
      <EnrolledFactorsView
        stackProps={{
          border: '1px solid #cdcdcd',
          borderRadius: 6,
          my: 2,
          bg: 'white',
        }}
        factors={enrolledFactors}
      />
      <Collapse
        style={{ width: '100%' }}
        in={shouldEnroll && (!selectedEnrollmentFactorType || !!enrollmentTypeError)}>
        <Box p={1}>
          <Text py={1} fontFamily="Hero-New" fontSize="md" color="gray.600">
            <StepNumberSpan step={1} />
            Select an option to enroll with.
          </Text>
          <Flex gap={2} align="center" w="100%">
            <Box flex={1}>
              <StandaloneInput
                value={selectedEnrollmentFactorType}
                field={typeField}
                theme="detailed"
                onChange={setSelectedEnrollmentFactorType}
              />
            </Box>
            {enrolledFactors?.length ? (
              <ActionButton
                bg="white"
                size="sm"
                onClick={() => {
                  useAuth.setState({ enrollingUser: null })
                  setSelectedEnrollmentFactorType(null)
                  setManuallyEnrolling(false)
                }}>
                Cancel
              </ActionButton>
            ) : null}
          </Flex>
        </Box>
      </Collapse>
      <Collapse
        unmountOnExit
        style={{ width: '100%' }}
        in={shouldEnroll && !enrollmentTypeError && selectedEnrollmentFactorType === 'phone'}>
        <PhoneEnroll onBack={() => setSelectedEnrollmentFactorType(null)} />
      </Collapse>
      <Collapse
        unmountOnExit
        style={{ width: '100%' }}
        in={shouldEnroll && !enrollmentTypeError && selectedEnrollmentFactorType === 'totp'}>
        <TotpEnroll onBack={() => setSelectedEnrollmentFactorType(null)} />
      </Collapse>
      <CollapseError error={enrollmentTypeError} />
      <Collapse style={{ width: '100%' }} in={!!enrolledFactors?.length && !manuallyEnrolling}>
        <Flex w="100%" p={1}>
          <SolidActionButton
            ml="auto"
            size="sm"
            onClick={() => {
              setManuallyEnrolling(true)
              useAuth.setState({ enrollingUser: authUser })
            }}>
            + Add another factor
          </SolidActionButton>
        </Flex>
      </Collapse>
    </VStack>
  )
}

const TwoFactorSelect = ({ factors }: { factors: MultiFactorInfo[] }) => (
  <Box w="100%">
    <Text fontFamily="Open Sans">Select an option to verify with:</Text>
    <VStack w="100%" divider={<Divider />} align="flex-start">
      {factors.map(factor => (
        <Flex
          w="100%"
          border="1px solid #cdcdcd"
          key={factor.uid}
          as="button"
          onClick={() => useAuth.setState({ selectedEnrollmentFactor: factor })}
          cursor="pointer"
          bg="white"
          _hover={{
            bg: 'green.50',
          }}
          borderRadius={6}>
          <MultiFactorInfoView
            // readOnly
            // isSoleFactor={factors.length === 1}
            factorInfo={factor}
          />
        </Flex>
      ))}
    </VStack>
  </Box>
)

const TwoFactorVerify = ({ factorInfo }: { factorInfo: MultiFactorInfo }) =>
  factorInfo.factorId === PhoneMultiFactorGenerator.FACTOR_ID ? (
    <PhoneVerify factor={factorInfo as PhoneMultiFactorInfo} />
  ) : (
    <AuthenticatorVerify factor={factorInfo as TotpMultiFactorInfo} />
  )

export const TwoFactorContent = () => {
  const selectedEnrollmentFactor = useAuth(s => s.selectedEnrollmentFactor)
  const enrolledFactors = useAuth(s => s.enrolledFactors)
  const authUser = useAuth(s => s.authUser)
  if (authUser) return <TwoFactorInfoView />
  if (selectedEnrollmentFactor) return <TwoFactorVerify factorInfo={selectedEnrollmentFactor} />
  if (!enrolledFactors) return null
  if (enrolledFactors.length === 0) return <TwoFactorInfoView />
  if (enrolledFactors.length === 1) return <TwoFactorVerify factorInfo={enrolledFactors[0]} />
  return <TwoFactorSelect factors={enrolledFactors} />
}

export const TwoFactorAuthModal = () => {
  const multiFactorOpen = useMultiFactorOpen()

  if (!multiFactorOpen) return null
  return (
    <DefaultModal
      isOpen
      onClose={() => {}}
      overlayHeader
      size="md"
      closeDisabled
      closeOnEsc={false}
      closeOnOverlayClick={false}
      contentProps={{ p: 4, bg: 'gray.50' }}
      render={() => <TwoFactorContent />}
    />
  )
}

import { TimeIcon } from '@chakra-ui/icons'
import { Center, Flex, IconButton, Img, Text, Tooltip } from '@chakra-ui/react'
import {
  FieldTypes,
  FileDBValue,
  FileField,
  getDateString,
  getFullName,
  UpdateCallback,
  UploadProgress,
} from '@hb/shared'
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { processFileFieldData } from '../../backend'
import { useCachedUser } from '../../collections'
import { PopUpMessageContext } from '../../contexts'
import uploadIcon from '../../icons/upload.svg'
import { DeleteButton } from '../Buttons'
import { UploadProgressView } from '../forms/FinalForm/UploadProgressView'
import { UserBadge } from '../Users'
import { fileToDataUrl } from '../utils/getDataUrl'

export const SmallFileUpload = ({
  value,
  onUpload,
  accept,
  canDelete,
  label,
  storagePath,
}: {
  value: FileDBValue | null
  label: string
  canDelete?: boolean
  accept?: string
  onUpload: (value: FileDBValue | null) => Promise<UpdateCallback>
  storagePath: string
}) => {
  const reader = useRef(new FileReader())
  const [uploading, setUploading] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const [uploads, setUploads] = useState<Record<string, UploadProgress>>({})

  const field = useMemo<FileField>(
    () => ({
      placeholder: label,
      name: label,
      type: FieldTypes.FILE,
      fileType: 'pdf',
    }),
    [label],
  )
  const hasUploads = Object.keys(uploads).length > 0

  const { showMessage } = useContext(PopUpMessageContext)

  const handleUpload = useCallback(
    async (f: File) => {
      const onError = (message: string) => {
        setUploads({})
        setUploading(false)
        showMessage({
          type: 'error',
          text: 'Error uploading file',
          subText: message,
        })
        return { error: `Error uploading file: ${message}` }
      }
      if (!storagePath) {
        return onError('No storage path provided')
      }
      if (f) {
        setUploading(true)
        try {
          const dataUrl = await fileToDataUrl(reader.current, f)
          const fileValue: FileDBValue = {
            id: '',
            name: f.name,
            uploadedOn: Date.now(),
            dataUrl,
            type: f.type,
          }
          const processed = await processFileFieldData(
            storagePath,
            [],
            field,
            fileValue,
            value,
            progress => setUploads({ value: progress }),
          )
          if (!processed && !canDelete) {
            return onError('Cannot delete file')
          }
          await onUpload(processed)
          setUploads({})
        } catch (err: any) {
          return onError(err.message)
        }
        setUploading(false)
      }
    },
    [onUpload, storagePath, field, value, showMessage, canDelete],
  )

  const text = useMemo(() => {
    if (!value) return label
    return value.name || `File uploaded ${getDateString(value.uploadedOn)}`
  }, [value, label])

  const { data: uploadedBy } = useCachedUser(value?.uploadedBy || null)
  const uploadedText = useMemo(() => {
    if (!value) return null
    return `Uploaded ${getDateString(value.uploadedOn, 'short')}${uploadedBy ? `\nby ${getFullName(uploadedBy)}` : ''}`
  }, [value, uploadedBy])

  return (
    <Flex position="relative" gap={2} align="center" py={1}>
      <Text lineHeight={1} fontWeight={600} fontSize="sm" opacity={0.8} maxW="200px" isTruncated>
        {label}
      </Text>
      <Tooltip label={value?.name || 'No file uploaded'} placement="top">
        <Text opacity={value ? 1 : 0.6} fontSize="sm" maxW="200px" isTruncated>
          {text}
        </Text>
      </Tooltip>
      {uploadedText ? (
        <Center>
          <Tooltip textAlign="center" label={uploadedText} bg="green.500" hasArrow placement="top">
            <Center>
              {value?.uploadedBy ? (
                <UserBadge noTooltip userGroup="admin" userId={value?.uploadedBy} />
              ) : (
                <TimeIcon w={3} h={3} opacity={0.7} />
              )}
            </Center>
          </Tooltip>
        </Center>
      ) : null}
      <Center cursor="pointer" position="relative">
        <Tooltip
          label={value ? 'Replace File' : 'Upload File'}
          bg="green.500"
          placement="top"
          color="white"
          textShadow="1px 1px 3px #000000aa"
          hasArrow>
          <IconButton
            variant="outline"
            aria-label="Edit"
            isLoading={uploading}
            size="xs"
            colorScheme="green"
            icon={<Img src={uploadIcon} w={3} h={3} />}
            onClick={() => inputRef.current?.click()}
          />
        </Tooltip>
        <input
          type="file"
          ref={inputRef}
          accept={accept}
          style={{
            width: '0.1px',
            height: '0.1px',
            opacity: 0,
            cursor: 'pointer',
          }}
          onChange={e => {
            const { files } = e.target
            const [f] = files || []
            if (f) {
              handleUpload(f)
            }
          }}
        />
      </Center>
      {canDelete ? (
        <DeleteButton
          itemName="file"
          size="xs"
          onDelete={async () => {
            await onUpload(null)
          }}
        />
      ) : null}
      <Center
        position="absolute"
        top={0}
        left={0}
        h="100%"
        w="100%"
        bg="blackAlpha.500"
        pointerEvents={hasUploads ? 'auto' : 'none'}
        opacity={hasUploads ? 1 : 0}
        transition="all 400ms">
        <UploadProgressView uploads={uploads} />
      </Center>
    </Flex>
  )
}

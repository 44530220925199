import { BellIcon, ChatIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Circle,
  CircularProgress,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  colors,
  getDateTimeString,
  NotificationTopic,
  PushNotificationsDevice,
  PushNotificationsTokenData,
  UserRoleItem,
} from '@hb/shared'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { updateWebPushNotificationSettings } from '../../backend'
import { ScreenContext } from '../../contexts'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { useMyRoleItem } from '../../hooks/backend/auth/useMyRoleItem'
import { useHBDeviceId } from '../../hooks/useLocalStorage'
import {
  enableWebPushNotifications,
  unregisterPushNotifications,
} from '../../utils/pushNotifications'
import { SolidActionButton } from '../Buttons'
import { Loading } from '../Loading'
import { CollapseHorizontal } from '../shared'

const PushNotificationsToggle = ({
  item,
  loading,
}: {
  item: UserRoleItem | null
  loading: boolean
}) => {
  const { webPushNotificationsToken } = item || {}
  const enabled = !!webPushNotificationsToken
  const { isMobile } = useContext(ScreenContext)
  const { processResponse } = useContext(PopUpMessageContext)
  const thisDeviceId = useHBDeviceId()
  const [actionLoading, setActionLoading] = useState(false)
  const onEnable = useCallback(async () => {
    try {
      setActionLoading(true)
      await enableWebPushNotifications()
    } catch (err: any) {
      processResponse({ error: err.message || 'An error occurred' })
    }
    setActionLoading(false)
  }, [processResponse])

  const onDisable = useCallback(async () => {
    try {
      setActionLoading(true)
      await unregisterPushNotifications()
    } catch (err: any) {
      processResponse({ error: err.message || 'An error occurred' })
    }
    setActionLoading(false)
  }, [processResponse])
  if (!item && loading) return null

  if (actionLoading) {
    return <Loading stackProps={{ pt: 2 }} text="Updating notifications..." />
  }

  return (
    <Flex flexFlow={isMobile ? 'column-reverse' : 'row'} pt={2} gap={isMobile ? 3 : 1} w="100%">
      {enabled ? (
        <>
          <SolidActionButton
            flex={1}
            size="sm"
            colorScheme="red"
            _hover={{
              bg: 'red.600',
            }}
            isLoading={actionLoading}
            onClick={onDisable}>
            Disable push notifications
          </SolidActionButton>
          {thisDeviceId === webPushNotificationsToken?.device.hbDeviceId ? null : (
            <SolidActionButton flex={1} size="sm" isLoading={actionLoading} onClick={onEnable}>
              Move to this device
            </SolidActionButton>
          )}
        </>
      ) : (
        <SolidActionButton size="sm" w="100%" isLoading={actionLoading} onClick={onEnable}>
          Enable on this device
        </SolidActionButton>
      )}
    </Flex>
  )
}

const PushNotificationsDeviceView = ({
  token,
}: {
  token: PushNotificationsTokenData | null | undefined
}) => {
  const { device, timestamp } = token || {}
  const [displayedDevice, setDisplayedDevice] = useState<
    PushNotificationsDevice | null | undefined
  >(device)
  const [displayedTimestamp, setDisplayedTimestamp] = useState<number | null>(timestamp || null)
  const thisDeviceId = useHBDeviceId()

  useEffect(() => {
    if (device) {
      setDisplayedDevice(device)
    }
    if (timestamp) {
      setDisplayedTimestamp(timestamp)
    }
  }, [device, timestamp])
  const displayedProperties = useMemo(() => {
    const { browser, model, os, type, vendor } = displayedDevice || {}
    const props: [string, string][] = []
    if (vendor) props.push(['Vendor', vendor])
    if (model) props.push(['Model', model])
    if (type) props.push(['Type', type])
    if (browser) props.push(['Browser', browser])
    if (os) props.push(['OS', os])
    return props
  }, [displayedDevice])

  return (
    <VStack
      mt={2}
      border="1px solid #cdcdcd"
      borderRadius={6}
      spacing={0}
      align="flex-start"
      py={1}
      px={2}
      w="100%">
      <Text fontFamily="Hero-New" color="gray.600" fontSize="sm">
        Sending notifications to:
      </Text>
      <Text
        bg={thisDeviceId === displayedDevice?.hbDeviceId ? colors.green.hex : 'gray.400'}
        color="gray.50"
        textShadow="1px 1px 3px #00000077"
        fontSize="sm"
        fontWeight={600}
        px={2}
        borderRadius="full">
        {thisDeviceId === displayedDevice?.hbDeviceId ? 'THIS DEVICE' : 'ANOTHER DEVICE'}
      </Text>
      {displayedProperties.map(([key, value]) => (
        <HStack key={key} spacing={1}>
          <Text fontFamily="Open Sans" fontWeight={600} color="gray.600" fontSize="sm">
            {key}
          </Text>
          <Text fontFamily="Open Sans" color="gray.600" fontSize="sm">
            {value}
          </Text>
        </HStack>
      ))}
      {displayedTimestamp ? (
        <Text fontFamily="Open Sans" color="gray.500" fontSize="sm">
          Registered on {getDateTimeString(displayedTimestamp)}
        </Text>
      ) : null}
    </VStack>
  )
}

const PushNotificationsStatus = ({
  item,
  loading,
}: {
  item: UserRoleItem | null
  loading: boolean
}) => {
  const { webPushNotificationsToken } = item || {}
  const enabled = !!webPushNotificationsToken
  return (
    <Flex flexFlow="column" w="100%">
      <Flex align="center" w="100%">
        <CollapseHorizontal in={loading} width={28}>
          <CircularProgress size={4} color={colors.green.hex} isIndeterminate />
        </CollapseHorizontal>
        <CollapseHorizontal in={!loading} width={28}>
          <Circle
            size="13px"
            bg={enabled ? 'green.400' : '#cdcdcd'}
            boxShadow={`0 0 4px ${enabled ? '#33ff3388' : '#ffffff00'}`}
          />
        </CollapseHorizontal>
        <Text
          style={{
            fontFamily: 'Comfortaa',
            color: enabled ? colors.green.hex : '#777',
            fontSize: '0.9rem',
            top: '1px',
            lineHeight: 1,
            fontWeight: 700,
            position: 'relative',
          }}>
          {enabled ? 'ACTIVE' : 'DISABLED'}
        </Text>
      </Flex>
    </Flex>
  )
}

const NotificationTopicToggle = ({
  topicId,
  title,
  item,
  icon,
  loading,
  canEdit,
}: {
  topicId: NotificationTopic
  title: string
  icon: React.ReactNode
  item: UserRoleItem | null
  loading: boolean
  canEdit?: boolean
}) => {
  const { webPushNotifications } = item || {}
  const enabled = webPushNotifications?.[topicId]
  const { processResponse } = useContext(PopUpMessageContext)
  const [actionLoading, setActionLoading] = useState(false)
  const onToggle = useCallback(async () => {
    try {
      setActionLoading(true)
      await updateWebPushNotificationSettings({
        topic: topicId,
        enabled: !enabled,
      })
    } catch (err: any) {
      processResponse({ error: err.message || 'An error occurred' })
    }
    setActionLoading(false)
  }, [processResponse, enabled, topicId])

  return (
    <Flex align="center" w="100%">
      <Switch
        size="sm"
        colorScheme={canEdit ? 'green' : 'gray'}
        _focus={{ boxShadow: 'none' }}
        _active={{ boxShadow: 'none' }}
        isChecked={!!enabled}
        onChange={onToggle}
        isDisabled={loading || !canEdit}
      />
      <Flex color={enabled ? 'gray.500' : 'gray.400'} gap={1} py={1} align="center" ml={2}>
        {icon}
        <Text lineHeight={1} fontFamily="Open Sans" fontSize="sm">
          {title}
        </Text>
      </Flex>
      <CollapseHorizontal in={actionLoading} width={40}>
        <CircularProgress size={4} color={colors.green.hex} isIndeterminate />
      </CollapseHorizontal>
    </Flex>
  )
}

const NotificationTopics = ({ item, loading }: { item: UserRoleItem | null; loading: boolean }) => {
  const { webPushNotificationsToken } = item || {}
  return (
    <VStack
      mt={2}
      border="1px solid #cdcdcd"
      opacity={webPushNotificationsToken ? 1 : 0.7}
      borderRadius={6}
      spacing={0}
      pointerEvents={webPushNotificationsToken ? 'auto' : 'none'}
      bg={webPushNotificationsToken ? 'white' : 'gray.100'}
      align="flex-start"
      py={2}
      px={3}
      w="100%">
      <Text fontFamily="Hero-New" color="gray.600" fontSize="sm">
        Sending notifications for:
      </Text>
      <NotificationTopicToggle
        topicId="messaging"
        title="Chat Messages"
        icon={<ChatIcon w={3} />}
        item={item}
        canEdit={!!webPushNotificationsToken}
        loading={loading}
      />
    </VStack>
  )
}

export const PushNotificationsSettings = ({ onClose }: { onClose?: () => void }) => {
  const { data: userRoleItem, loading } = useMyRoleItem()
  const { webPushNotificationsToken } = userRoleItem || {}
  return (
    <VStack spacing={0} py={3} px={4} w="100%">
      <HStack py={1} w="100%">
        <Flex gap={1} align="center">
          <BellIcon color="gray.500" />
          <Text fontFamily="Hero-New" color="gray.500">
            Push Notifications
          </Text>
        </Flex>
        {onClose ? (
          <IconButton
            ml="auto"
            borderRadius="full"
            color="gray.500"
            bg="blackAlpha.100"
            size="xs"
            aria-label="Close"
            icon={<CloseIcon />}
            onClick={onClose}
          />
        ) : null}
      </HStack>
      <PushNotificationsStatus item={userRoleItem} loading={loading} />
      <Collapse in={!!webPushNotificationsToken} style={{ width: '100%' }}>
        <PushNotificationsDeviceView token={webPushNotificationsToken} />
        <NotificationTopics item={userRoleItem} loading={loading} />
      </Collapse>
      <PushNotificationsToggle item={userRoleItem} loading={loading} />
    </VStack>
  )
}

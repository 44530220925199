import { Center } from '@chakra-ui/react'
import React, { CSSProperties, useCallback, useRef, useState } from 'react'
import { useDrag } from 'react-use-gesture'
import dragPanIcon from '../../icons/drag_pan_thin.svg'
import { GestureOverlayContainer } from './GestureOverlayContainer'
import './styles.css'

const moveIconStyle: CSSProperties = {
  width: '80%',
  height: '80%',
  opacity: 0.7,
  filter: 'invert(1) drop-shadow(0px 0px 3px #000000aa)',
}

type MoveOverlayProps = {
  position: [number, number]
  onMove: (pos: [number, number]) => void
  zoom: number
}

export const MoveOverlayBody = ({ position: [x, y], onMove, zoom }: MoveOverlayProps) => {
  const dragStartPos = useRef<[number, number]>([0, 0])
  const dragStartPointer = useRef<[number, number]>([0, 0])
  const [isDragging, setIsDragging] = useState(false)

  const onMoveDrag: Parameters<typeof useDrag<React.PointerEvent>>[0] = useCallback(
    ({ movement: [dx, dy], event, first, last }) => {
      event.stopPropagation()
      if (first) {
        dragStartPointer.current = [event.clientX, event.clientY]
        dragStartPos.current = [x, y]
        setIsDragging(true)
      }
      if (last) {
        setIsDragging(false)
      }
      requestAnimationFrame(() => {
        onMove([dragStartPos.current[0] + dx / zoom, dragStartPos.current[1] + dy / zoom])
      })
    },
    [zoom, x, y, onMove],
  )
  const bind = useDrag(onMoveDrag)

  return (
    <Center
      userSelect="none"
      position="absolute"
      left={0}
      top={0}
      cursor={isDragging ? 'grabbing' : 'move'}
      w="100%"
      h="100%"
      {...bind()}>
      <img style={moveIconStyle} src={dragPanIcon} alt="move" draggable={false} />
    </Center>
  )
}

export const MoveOverlay = (props: MoveOverlayProps) => {
  return (
    <GestureOverlayContainer>
      <MoveOverlayBody {...props} />
    </GestureOverlayContainer>
  )
}

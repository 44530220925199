import { SiteSettings } from '@hb/shared'
import { create } from 'zustand'
import { getLocalStorage, setLocalStorage } from '../utils/localStorage'

import createSelectors from './selectors'

// Define a store for managing collections

const useSiteSettingsBase = create<SiteSettings>(() => ({
  nativeDateInputOverride: getLocalStorage('nativeDateInputOverride') === true,
}))

// const publishToSubscriber

export const useSiteSettings = createSelectors(useSiteSettingsBase)

export const updateSiteSettings = (key: keyof SiteSettings, value: any) => {
  useSiteSettingsBase.setState({ [key]: value })
  setLocalStorage(key, value)
}

import { Box, Button, Center, CircularProgress, Flex, Text } from '@chakra-ui/react'
import {
  FieldMap,
  FieldTypes,
  objectToArray,
  PaymentsLogItem,
  TextAreaField,
  UpdateCallback,
  UpdatePaymentsLogArgs,
  WithId,
} from '@hb/shared'
import { httpsCallable } from 'firebase/functions'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { functions } from '../../../../backend/functions'
import { PopUpMessageContext, useApp, UserContext } from '../../../../contexts'
import { useMe } from '../../../../hooks/backend/useMe'
import { ActionLog } from '../../../ActionLog'
import { FormPopover, getRowBackground } from '../../../DataView'
import { Expandable } from '../../../Expandable'
import { Editable } from '../../../forms'
import { usePaymentsLog } from './hooks'

const logTextField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Entry',
}

const newLogEntryField: FieldMap = {
  name: 'New Log Entry',
  children: {
    text: {
      type: FieldTypes.TEXTAREA,
      placeholder: 'Entry text...',
    },
  },
}

const updatePaymentsLogFunc = httpsCallable<UpdatePaymentsLogArgs>(functions, 'updatePaymentsLog')
const updatePaymentsLog = (args: UpdatePaymentsLogArgs): Promise<UpdateCallback> =>
  updatePaymentsLogFunc(args)
    .then(() => ({ success: 'Updated payments log' }))
    .catch(error => ({ error: error?.message || 'An error occurred' }))

type PaymentsLogItemViewProps = {
  entry: WithId<PaymentsLogItem>
  assessmentId: string
}
const OwnerLogItemView = ({ entry, assessmentId }: PaymentsLogItemViewProps) => {
  const { appName } = useApp()
  return (
    <Flex px={1} w="100%" flexFlow="column">
      <Editable
        field={logTextField}
        value={entry.text}
        theme="detailed"
        style={{ background: 'transparent' }}
        editableStackProps={{ bg: 'transparent', boxShadow: 'none' }}
        onDelete={() =>
          updatePaymentsLog({
            itemUri: `assessment:${assessmentId}`,
            entryId: entry.id,
            text: null,
            appName,
          })
        }
        onSubmit={v =>
          updatePaymentsLog({
            itemUri: `assessment:${assessmentId}`,
            entryId: entry.id,
            text: v,
            appName,
          })
        }
      />
      <Box w="100%" px={1} pb={2}>
        <ActionLog
          action={entry.updatedOn !== entry.createdOn ? 'Updated' : 'Created'}
          by={entry.updatedBy}
          group={entry.updatedByGroup}
          on={entry.updatedOn}
        />
      </Box>
    </Flex>
  )
}

const NonOwnerLogItemView = ({ entry }: PaymentsLogItemViewProps) => {
  return (
    <Flex pt={1} px={2} pb={2} flexFlow="column" w="100%">
      <Text whiteSpace="pre-wrap" w="100%">
        {entry.text}
      </Text>
      <ActionLog
        action={entry.updatedOn !== entry.createdOn ? 'Updated' : 'Created'}
        by={entry.updatedBy}
        group={entry.updatedByGroup}
        on={entry.updatedOn}
      />
    </Flex>
  )
}

const NewEntryPopover = ({ assessmentId }: { assessmentId: string }) => {
  return (
    <Box onClick={e => e.stopPropagation()} ml="auto">
      <FormPopover
        field={newLogEntryField}
        onSubmit={v =>
          updatePaymentsLog({
            itemUri: `assessment:${assessmentId}`,
            text: v.text || '',
            appName: 'app',
            entryId: null,
          })
        }>
        <Button size="xs" colorScheme="green" borderRadius="full">
          + NEW ENTRY
        </Button>
      </FormPopover>
    </Box>
  )
}

const PaymentsLogItemView = ({
  entry,
  assessmentId,
  index,
}: {
  entry: WithId<PaymentsLogItem>
  assessmentId: string
  index: number
}) => {
  const me = useMe()
  const bg = useMemo(() => getRowBackground(index), [index])
  const body =
    me?.uid === entry.createdBy ? (
      <OwnerLogItemView entry={entry} assessmentId={assessmentId} />
    ) : (
      <NonOwnerLogItemView entry={entry} assessmentId={assessmentId} />
    )
  return (
    <Box px={1} w="100%" bg={bg}>
      {body}
    </Box>
  )
}

const FixDuplicates = () => {
  const { assessmentId } = useContext(UserContext)
  const { showMessage } = useContext(PopUpMessageContext)
  const [fixing, setFixing] = useState(false)
  const onFix = useCallback(async () => {
    if (!assessmentId) {
      showMessage({
        type: 'error',
        text: 'No assessmentId found',
      })
      return
    }
    try {
      setFixing(true)
      const func = httpsCallable<{ assessmentId: string }, { success: string }>(
        functions,
        'removeDuplicatePaymentLogText',
      )
      const { data } = await func({ assessmentId })
      showMessage({
        type: 'success',
        text: data.success,
      })
    } catch (err: any) {
      showMessage({
        type: 'error',
        text: 'Error fixing duplicates',
        subText: err.message,
      })
    }
    setFixing(false)
  }, [assessmentId, showMessage])

  return (
    <Box>
      <Button
        size="xs"
        borderRadius="full"
        onClick={e => {
          e.stopPropagation()
          onFix()
        }}
        isLoading={fixing}
        loadingText="Fixing">
        Fix Duplicates
      </Button>
    </Box>
  )
}

export const PaymentsLog = () => {
  const { assessmentId } = useContext(UserContext)
  const { data, loading, error } = usePaymentsLog(assessmentId)
  const sortedByCreatedAt = useMemo(() => {
    const asArr = objectToArray(data?.entries || {})
    return asArr.sort((a, b) => b.createdOn - a.createdOn)
  }, [data])
  if (!assessmentId)
    return (
      <Center p={2} bg="red.600">
        <Text color="white">No assessmentId found</Text>
      </Center>
    )
  return (
    <Expandable
      w="100%"
      initExpanded
      bg="gray.50"
      borderRadius={6}
      boxShadow="2px 2px 5px #00000055"
      headerProps={{ bg: 'white' }}
      header={() => (
        <Flex w="100%" align="center" py={1} px={3}>
          <Text fontSize="lg" fontWeight={600}>
            Payments Log
          </Text>
          <CircularProgress
            isIndeterminate={loading}
            size="20px"
            ml={2}
            opacity={loading ? 1 : 0}
            transition="opacity 300ms"
          />
          <Flex gap={2} ml="auto" align="center">
            <FixDuplicates />
            <NewEntryPopover assessmentId={assessmentId} />
          </Flex>
        </Flex>
      )}>
      <Flex direction="column" maxH="400px" overflowY="auto">
        {sortedByCreatedAt.length ? (
          sortedByCreatedAt.map((entry, idx) => (
            <PaymentsLogItemView
              index={idx}
              key={entry.id}
              entry={entry}
              assessmentId={assessmentId}
            />
          ))
        ) : (
          <Text px={3} py={1} color={error ? 'red.600' : 'gray.600'} fontStyle="italic">
            {error || 'No log entries'}
          </Text>
        )}
      </Flex>
    </Expandable>
  )
}

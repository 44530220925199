import { Box, useTabsContext } from '@chakra-ui/react'
import { DropdownField, FieldTypes } from '@hb/shared'
import React, { useMemo } from 'react'
import { StandaloneInput } from '../../forms'

export const MenuTabList = ({ tabs }: { tabs: Array<string> }) => {
  const { selectedIndex, setSelectedIndex } = useTabsContext()

  const field = useMemo<DropdownField>(() => {
    return {
      type: FieldTypes.DROPDOWN,
      options: tabs.map((t, i) => ({ id: `${i}`, text: t })),
      placeholder: 'Select a tab',
    }
  }, [tabs])

  return (
    <Box w="100%" bg="white">
      <StandaloneInput
        field={field}
        style={{
          background: 'transparent',
          height: '100%',
          boxShadow: 'none',
          padding: '0.7rem 0.5rem 0.4rem 0.7rem',
        }}
        theme="detailed"
        value={`${selectedIndex}`}
        onChange={v => setSelectedIndex(parseInt(v, 10))}
      />
    </Box>
  )
}

import { Calculation } from 'final-form-calculate'

import { Collection, type AppCollectionsState } from '../../collections'
import { FieldTypes } from '../../constants'
import { type CustomElement, type Descendant } from '../../editor/types'
import { BaseUINode } from '../app/ui'
import { ValidationErrors } from '../data'
import { AnyObject, FieldHints, FieldLabels, FieldMapValue, WithId } from '../utils'

export type ShortcutItem<T = any, Y = any> = (data: T) => Y

export type DropdownOptionItem<T = string> = {
  text: string
  id: T
  redFlagReason?: string
}

export type DropdownOptionGroup<T = string> = {
  title: string
  options: DropdownOption<T>[]
}

export const isDropdownOptionItem = (
  option: DropdownOption<any>,
): option is DropdownOptionItem<any> => 'id' in option

export type DropdownOption<T = string> = DropdownOptionItem<T> | DropdownOptionGroup<T>

export interface FieldBlueprint {
  placeholder: string
  optional?: boolean
  labels?: FieldLabels
  condition?: FieldCondition
}
export interface FileFieldBlueprint extends FieldBlueprint {
  fileType?: FileType
  name: string
}
export interface AlternateFieldBlueprint extends FieldBlueprint {
  fieldType: FieldTypes
  altPrefix?: string
}

export type FieldCondition = (value?: FieldMapValue) => boolean
export type Validator<FieldType> = (value?: any, field?: FieldType) => string | undefined

type FieldPreset = {
  title: string
  data: any
}
export type BaseField = {
  itemFields?: never
  autocomplete?: string
  shortcut?: never
  children?: never
  info?: never
  placeholder: string
  presets?: Array<FieldPreset> | ((value?: FieldMapValue) => Array<FieldPreset>)
  optional?: boolean
  labels?: FieldLabels
  protected?: boolean
  hints?: FieldHints
  condition?: FieldCondition | null
  format?: (value?: any) => any
  parse?: (value?: any) => any
  validate?: (value?: any) => string | undefined
}

export type FileType = 'image' | 'pdf'
export type PathPrefix = {
  item: string
  location: string
}

export type TextField = BaseField & {
  type: FieldTypes.TEXT
}

export interface PhoneField extends BaseField {
  type: FieldTypes.PHONE
}

export interface EmailField extends BaseField {
  type: FieldTypes.EMAIL
}

export type TextAreaField = BaseField & {
  type: FieldTypes.TEXTAREA
}

export interface FileField extends BaseField {
  type: FieldTypes.FILE
  fileType?: FileType
  name: string
}

export interface DynamicDropdownField<Data extends AnyObject> extends BaseField {
  type: FieldTypes.DYNAMIC_DROPDOWN
  selectInput?: boolean
  baseId: string
  listId: string
  collection: Collection<Data>
  notApplicableText?: string
}

export interface DateField extends BaseField {
  type: FieldTypes.DATE
  nativeInput?: boolean
  minDate?: number | 'now'
  maxDate?: number | 'now'
  defaultToNow?: boolean
  isoFormat?: boolean
}

export interface TimeField extends BaseField {
  type: FieldTypes.TIME
  minTime?: string
  maxTime?: string
  defaultToNow?: boolean
}

export interface DateTimeField extends BaseField {
  type: FieldTypes.DATETIME
  minDate?: number | 'now'
  defaultToNow?: boolean
  maxDate?: number | 'now'
  isoFormat?: boolean
}

export interface DropdownField<T = string, UINode extends BaseUINode = BaseUINode>
  extends BaseField {
  type: FieldTypes.DROPDOWN
  options: DropdownOption<T>[]
  selectInput?: boolean
  getKey?: (item: T) => string
  searchable?: boolean
  renderOption?: (item: DropdownOptionItem<T>) => UINode
  allowOther?: boolean
}

// Extract the union of ids from DropdownOptionItem
type ExtractOptionIds<T> =
  T extends DropdownOptionItem<infer U>
    ? U
    : T extends DropdownOptionGroup<infer V>
      ? ExtractOptionIds<V>
      : never

// Create a type that extracts the ids from a DropdownField
export type DropdownFieldValue<T extends DropdownField<any>> = ExtractOptionIds<
  T['options'][number]
>

export interface MultipleSelectField<UINode extends BaseUINode = BaseUINode> extends BaseField {
  type: FieldTypes.MULTIPLE_SELECT
  options: DropdownOptionItem[]
  renderOption?: (item: DropdownOptionItem) => UINode
}

export interface IdField<Data extends AnyObject, UINode extends BaseUINode = BaseUINode>
  extends BaseField {
  type: FieldTypes.ID
  collection: Collection<Data>
  selectInput?: boolean
  renderOption?: (item: WithId<Data>) => UINode
  allowOther?: boolean
  getItemText?: (item: any) => string
  // renderItem: (item: T) => React.ReactNode
  // ^ unimplemented but could be used to extend IdField
}

export interface BooleanField extends BaseField {
  type: FieldTypes.BOOLEAN
  yesText?: string
  noText?: string
  redFlagYesReason?: string
  redFlagNoReason?: string
}

export interface CheckboxField extends BaseField {
  type: FieldTypes.CHECKBOX
  redFlagYesReason?: string
  redFlagNoReason?: string
  inputType?: 'dropdown' | 'switch'
  yesText?: string
  noText?: string
}

export interface BaseNumberField extends BaseField {
  max?: number
  min?: number
  defaultValue?: number
}

export interface NumberField extends BaseNumberField {
  type: FieldTypes.NUMBER
  step?: number
  numDigits?: number
  precision?: number
  withStepper?: boolean
}
export interface PercentageField extends BaseNumberField {
  type: FieldTypes.PERCENTAGE
  step?: number
  precision?: number
}

export interface DollarAmountField extends BaseNumberField {
  type: FieldTypes.DOLLAR_AMOUNT
}

// doesnt support list yet
export interface AlternateField extends BaseField {
  type: FieldTypes.ALTERNATE
  fieldType: FieldTypes
  altPrefix?: string
}

export interface SignatureField extends BaseField {
  type: FieldTypes.SIGNATURE
}

export interface InitialsField extends BaseField {
  type: FieldTypes.INITIALS
}

export type Field =
  | AlternateField
  | BooleanField
  | CheckboxField
  | DateField
  | TimeField
  | DateTimeField
  | DollarAmountField
  | DropdownField<any>
  | DynamicDropdownField<any>
  | EmailField
  | FileField
  | IdField<any>
  | NumberField
  | PercentageField
  | PhoneField
  | TextField
  | TextAreaField
  | MultipleSelectField
  | SignatureField
  | InitialsField

export type FieldFromType<T extends FieldTypes> = Extract<Field, { type: T }>

export type FieldFormatter = (
  val: any | undefined,
  field: Field,
  collections: AppCollectionsState,
) => any

export type FormElement = FieldMap | Field | ListField | InfoStage
export type FormElementProps<StyleProp extends AnyObject> = {
  field: FormElement
  root?: boolean
  name?: string
  basePath?: string
  showName?: boolean
  active?: boolean
  disabled?: boolean
  readOnly?: boolean
  index?: number
  style?: StyleProp
}

export interface StageElementProps<StyleProp extends AnyObject>
  extends FormElementProps<StyleProp> {
  errors: ValidationErrors
  dirty: boolean
  setIsDirty?: (dirty: boolean) => void
  setCanSubmit?: (dirty: boolean) => void
}

export interface ListField {
  type?: never
  info?: never
  children?: never
  name: string
  itemFields: FieldMap | Field
  itemName: string
  displayedField?: 'index' | 'alpha-index' | 'alpha' | string
  getTitle?: (value: FieldMapValue | undefined, index: number) => string
  optional?: boolean
  labels?: FieldLabels
  hints?: FieldHints
  condition?: FieldCondition
  horizontalConfig?: {
    itemWidth: number
  }
}

export type FieldMap<UINode extends BaseUINode = BaseUINode> = {
  info?: never
  itemFields?: never
  shortcut?: never
  type?: never
  name: string
  initExpanded?: boolean // for data viewss
  hints?: FieldHints
  labels?: FieldLabels
  condition?: FieldCondition
  toName?: (value: FieldMapValue) => UINode | string
  optional?: boolean
  validate?: (value?: FieldMapValue) => ValidationErrors
  children: Record<string, Field | FieldMap | ListField | InfoStage>
}
export interface Stage extends FieldMap {
  calculate?: Calculation[]
  storagePath: string
}

export interface InfoStage {
  type?: never
  itemFields?: never
  info: true
  name: string
  data: CustomElement[]
  condition?: FieldCondition
  editorVersion?: 'v1' | 'v2'
}

export type DBStageType = 'text'

export type DBTextStage = {
  text?: Descendant[]
}

export type DBStageContent = DBTextStage

export type DBStage = {
  id: string
  parents?: string[]
  type?: DBStageType
  content?: DBStageContent
}

export type DBForm = {
  name: string
  description?: string
  stages: DBStage[]
  parents?: string[]
}

export type FieldStructure = {
  name: string
  children: FieldStructure[]
}

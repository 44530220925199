import {
  getPracticeConsentFormsCollectionPath,
  ShortcutArgs,
  Template,
  UpdateCallback,
} from '@hb/shared'
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import { db } from '../../../backend/db'
import { useApp } from '../../../contexts/AppContext'
import { useDocument } from '../../../hooks/backend/useDocument'
import { addMetadata } from '../../../utils/data'

import { TemplateEditorProvider } from './editor'
import { TemplatePracticeDataProvider, useTemplatePractice } from './practice'

const ConsentFormTemplateProviderBody = ({
  children,
  templateId,
}: PropsWithChildren<{ templateId: string }>) => {
  const data = useTemplatePractice()

  const { practice, practiceId, isLoading: practiceLoading } = data
  const consentFormTemplatesCollection = useMemo(
    () => (practiceId ? getPracticeConsentFormsCollectionPath(practiceId) : null),
    [practiceId],
  )

  const { appName } = useApp()
  const { data: template, loading: templateLoading } = useDocument<Template>(
    consentFormTemplatesCollection,
    templateId,
  )
  const isLoading = useMemo(
    () => templateLoading || practiceLoading,
    [templateLoading, practiceLoading],
  )
  const onSubmit = useCallback(
    async (updated: Omit<Template, 'rank'>): Promise<UpdateCallback> => {
      if (!consentFormTemplatesCollection) {
        throw new Error('No practice selected')
      }
      const consentFormsRef = collection(db, consentFormTemplatesCollection)
      const submitted = addMetadata(updated, appName, false)
      try {
        await setDoc(doc(consentFormsRef, templateId), submitted, {
          merge: true,
        })
        return { success: 'Updated template!' }
      } catch (err: any) {
        console.error(err)
        return { error: err?.message || 'Error saving template' }
      }
    },
    [consentFormTemplatesCollection, appName, templateId],
  )

  const onUpdateField = useCallback(
    async (field: string, value: any) => {
      if (!consentFormTemplatesCollection) {
        throw new Error('No practice selected')
      }
      try {
        const consentFormsRef = collection(db, consentFormTemplatesCollection)
        await updateDoc(doc(consentFormsRef, templateId), field, value)
        return { success: 'Updated template' }
      } catch (err: any) {
        console.error(err)
        return { error: err?.message || 'Error saving template' }
      }
    },
    [consentFormTemplatesCollection, templateId],
  )

  const shortcutArgs = useMemo<ShortcutArgs>(() => ({ practice, assessmentId: null }), [practice])
  return (
    <TemplateEditorProvider
      isLoading={isLoading}
      onSubmit={onSubmit}
      onUpdateField={onUpdateField}
      template={template}
      type="consentForm"
      shortcutArgs={shortcutArgs}>
      {children}
    </TemplateEditorProvider>
  )
}

export const ConsentFormTemplateProvider = ({
  children,
  templateId,
}: PropsWithChildren<{ templateId: string }>) => (
  <TemplatePracticeDataProvider>
    <ConsentFormTemplateProviderBody templateId={templateId}>
      {children}
    </ConsentFormTemplateProviderBody>
  </TemplatePracticeDataProvider>
)

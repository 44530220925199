import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Img,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  colors,
  defaultStageValidate,
  FieldMap,
  FieldTypes,
  formatPhoneNumber,
  ListField,
  PhoneField,
  PracticeVisit,
  TextAreaField,
  WithId,
} from '@hb/shared'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { faxVisitPdf } from '../../../../../backend/functions'
import { ThemeContext, ThemeContextData } from '../../../../../contexts'
import { useApp } from '../../../../../contexts/AppContext'
import { PopUpMessageContext } from '../../../../../contexts/PopUpMessage/PopUpMessageContext'
import faxIcon from '../../../../../icons/fax.svg'
import pdfIcon from '../../../../../icons/pdf.svg'
import { ActionLog } from '../../../../ActionLog'
import { AdditionalFilesField } from '../../../../Assessments/AdditionalFilesField'
import { FormElement } from '../../../../forms/Input'

type SendFaxData = {
  faxToNumber: string
  notes: string
  fileIds: string[]
}

const faxToNumberField: PhoneField = {
  type: FieldTypes.PHONE,
  placeholder: 'Fax to #',
}

const faxNotesField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Notes',
}
const fileIdsField: ListField = {
  itemName: 'File Id',
  name: 'fileIds',
  optional: true,
  itemFields: {
    type: FieldTypes.TEXT,
    placeholder: 'File ID',
  },
}

const faxVisitPdfField: FieldMap<SendFaxData> = {
  name: 'Fax Visit PDF',
  children: {
    faxToNumber: faxToNumberField,
    notes: faxNotesField,
    fileIds: fileIdsField,
  },
}

const BottomButton = ({
  onClick,
  text,
  bg = 'green.400',
  hoverBg = 'green.600',
}: {
  onClick: () => void
  text: string
  bg?: string
  hoverBg?: string
}) => (
  <Button
    minH="0"
    h={8}
    onClick={e => {
      e.stopPropagation()
      onClick()
    }}
    borderTopRadius={0}
    variant="unstyled"
    w="100%"
    bg={bg}
    _hover={{ bg: hoverBg }}
    py={1}>
    <Text textShadow="1px 1px 3px #00000088" fontSize="sm" color="white">
      {text}
    </Text>
  </Button>
)

const SentFaxesView = ({
  visit,
  onViewFax,
  onSendClick,
}: {
  visit: WithId<PracticeVisit>
  onViewFax: (index: number) => void
  onSendClick: () => void
}) => {
  const { sentFaxes = [] } = visit

  return (
    <VStack spacing={0} divider={<Divider />} w="100%" flexFlow="column">
      <Text px={2} py={1} fontSize="lg" fontFamily="Open Sans" color="gray.600">
        Sent Faxes
      </Text>
      <Flex maxH="360px" overflowY="auto" w="100%" flexFlow="column">
        {sentFaxes.map((fax, i) => (
          <Flex bg="gray.50" pt={2} px={3} pb={3} w="100%" flexFlow="column" key={i} gap={1}>
            <Flex w="100%" align="center" gap={1}>
              <Text fontSize="sm" fontWeight={600} opacity={0.7}>
                Sent to
              </Text>
              <Text fontSize="sm">{formatPhoneNumber(fax.sentToNumber)}</Text>
              <Flex ml="auto" align="center">
                <Button
                  gap={1}
                  size="xs"
                  variant="link"
                  color="gray.600"
                  onClick={() => onViewFax(i)}>
                  <Img src={pdfIcon} w="16px" />
                  <Text>View PDF</Text>
                </Button>
              </Flex>
            </Flex>
            <Box bg="white" border="1px solid #cdcdcd" borderRadius={3} py={1} px={2} w="100%">
              <Text lineHeight={1} fontSize="sm" color="gray.600">
                Notes
              </Text>
              <Text>{fax.notes}</Text>
            </Box>
            <ActionLog on={fax.sentOn} by={fax.sentBy} action="Sent" />
          </Flex>
        ))}
      </Flex>
      <BottomButton onClick={onSendClick} text="Send another fax" />
    </VStack>
  )
}

const themeContextValue: ThemeContextData = {
  theme: 'detailed',
}

export const SendVisitFaxPopover = ({
  practiceId,
  onViewFax,
  visit,
}: {
  practiceId: string
  onViewFax: (index: number) => void
  visit: WithId<PracticeVisit>
}) => {
  const { id: visitId, sentFaxes = [] } = visit
  const { showMessage } = useContext(PopUpMessageContext)
  const [isOpen, setIsOpen] = useState(false)
  const [faxing, setFaxing] = useState(false)
  const [viewing, setViewing] = useState<'view' | 'send'>(sentFaxes.length ? 'view' : 'send')
  const { appName } = useApp()

  const validate = useMemo(() => defaultStageValidate(faxVisitPdfField), [])
  const handleClose = useCallback(() => {
    setIsOpen(false)
    setViewing(sentFaxes.length ? 'view' : 'send')
  }, [sentFaxes])
  const onFax = useCallback(
    async ({ faxToNumber, notes, fileIds }: SendFaxData) => {
      setFaxing(true)
      try {
        // Call fax function
        await faxVisitPdf({
          appName,
          faxToNumber,
          practiceId,
          visitId,
          notes,
          fileIds: fileIds || [],
        })
        showMessage({
          text: 'Fax sent',
          type: 'success',
        })
        setViewing('view')
        setIsOpen(false)
      } catch (err: any) {
        showMessage({
          text: 'Failed to fax',
          subText: err?.message || 'An error occurred',
          type: 'error',
        })
        setFaxing(false)
        return { [FORM_ERROR]: err?.message || 'Failed to fax' }
      }
      setFaxing(false)
      return undefined
    },
    [showMessage, appName, practiceId, visitId],
  )
  return (
    <Popover
      isOpen={faxing || isOpen}
      onClose={handleClose}
      onOpen={() => setIsOpen(true)}
      isLazy
      closeOnBlur={false}
      placement="top-end">
      <PopoverTrigger>
        <Box>
          <Tooltip bg="gray.50" color="gray.600" placement="top" hasArrow label="Fax Visit PDF">
            <IconButton
              isLoading={faxing}
              color="gray.600"
              aria-label="Fax"
              size="sm"
              icon={<Image src={faxIcon} w="24px" opacity={0.9} />}
              borderRadius="full"
              bg="gray.50"
              boxShadow="1px 1px 4px #00000066"
            />
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody overflowY="auto" maxH="500px" p={0}>
            {!!sentFaxes.length && viewing === 'view' ? (
              <SentFaxesView
                onViewFax={onViewFax}
                onSendClick={() => setViewing('send')}
                visit={visit}
              />
            ) : (
              <Box pt={1} w="100%">
                <ThemeContext.Provider value={themeContextValue}>
                  <Form<SendFaxData>
                    onSubmit={onFax}
                    validate={validate}
                    mutators={{ ...arrayMutators }}>
                    {({ handleSubmit, submitting }) => (
                      <VStack w="100%" py={3} px={5} align="flex-start" spacing={0}>
                        <FormElement name="faxToNumber" field={faxToNumberField} />
                        <FormElement name="notes" field={faxNotesField} />
                        <FieldArray name="fileIds">
                          {({ fields, meta }) => (
                            <AdditionalFilesField meta={meta} fields={fields} />
                          )}
                        </FieldArray>
                        <HStack w="100%" pt={2}>
                          <Button
                            onClick={handleSubmit}
                            isLoading={submitting}
                            ml="auto"
                            size="sm"
                            bg={colors.green.hex}
                            color="white">
                            Send Fax
                          </Button>
                        </HStack>
                      </VStack>
                    )}
                  </Form>
                </ThemeContext.Provider>
                {sentFaxes.length ? (
                  <BottomButton
                    bg="gray.500"
                    hoverBg="gray.400"
                    onClick={() => setViewing('view')}
                    text="View sent faxes"
                  />
                ) : null}
              </Box>
            )}
          </PopoverBody>
          <PopoverCloseButton />
          <PopoverArrow />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

import { Collection, midwivesCollection, PracticeWithAdmin } from '@hb/shared'
import { documentId } from 'firebase/firestore'
import { useMemo } from 'react'
import { useApp, usePracticeAccess } from '../../../contexts'

export const useSelectedPracticeCollection = () => {
  const { appName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()

  return useMemo<Collection<PracticeWithAdmin> | null>(() => {
    if (appName === 'app' || !selectedPracticeId) return null
    return {
      ...midwivesCollection,
      filters: [
        ...(midwivesCollection.filters || []),
        [documentId() as any, '==', selectedPracticeId],
      ],
    }
  }, [appName, selectedPracticeId])
}

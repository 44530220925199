import { TemplateKey } from '@hb/shared'
import React, { FC, useMemo } from 'react'
import { DefaultModalProps } from '../Modals/DefaultModal'
import { EditorModalContainer } from '../RichText'
import { useEditorWidth } from '../RichText/hooks'
import {
  ConsentFormTemplateProvider,
  DocumentTemplateProvider,
  useTemplateEditor,
} from './contexts'
import { ExternalPdfTemplateEditor } from './ExternalPdf/ExternalPdfTemplateEditor'
import { TemplateEditor } from './TemplateEditor'

export type TemplateModalProps = BaseModalProps & {
  templateId: string
}

type BaseModalProps = Pick<DefaultModalProps, 'isOpen' | 'onClose'>

export const BaseTemplateModal: FC<BaseModalProps> = ({ isOpen, onClose }) => {
  // const { height: screenHeight } = useContext(ScreenContext)
  // const height = useMemo(() => `${screenHeight - 160}px`, [screenHeight])
  const { template } = useTemplateEditor()
  const sizeProps = useEditorWidth()
  const Component = useMemo(
    () => (template?.isExternalPdf ? ExternalPdfTemplateEditor : TemplateEditor),
    [template],
  )
  return (
    <EditorModalContainer
      isOpen={isOpen}
      onClose={onClose}
      render={() => <Component template={template} onClose={onClose} {...sizeProps} />}
    />
  )
}

export const TemplateEditorModal: FC<
  TemplateModalProps & {
    templateType: TemplateKey
  }
> = ({ templateId, templateType, ...props }) => (
  <DocumentTemplateProvider templateType={templateType} templateId={templateId}>
    <BaseTemplateModal {...props} />
  </DocumentTemplateProvider>
)

export const ConsentFormTemplateModal: FC<
  TemplateModalProps & {
    templateId: string
  }
> = ({ templateId, ...props }) => (
  <ConsentFormTemplateProvider templateId={templateId}>
    <BaseTemplateModal {...props} />
  </ConsentFormTemplateProvider>
)

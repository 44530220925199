import { Descendant, type Font, type FontId } from './types'

export const standardFontIds: FontId[] = ['Courier', 'Helvetica', 'Times-Roman']

export const fonts: Record<FontId, Font> = {
  Helvetica: {
    id: 'Helvetica',
    name: 'Helvetica',
    hasBold: true,
    hasOblique: true,
  },
  'Times-Roman': {
    id: 'Times-Roman',
    name: 'Times Roman',
    hasBold: true,
    hasItalic: true,
  },
  Courier: {
    id: 'Courier',
    name: 'Courier',
    hasBold: true,
    hasOblique: true,
  },
}

export const defaultFont = fonts.Helvetica

export const A4_DIMS_MM: [number, number] = [210, 297]
export const A4_ASPECT_RATIO = A4_DIMS_MM[0] / A4_DIMS_MM[1]
export const A4_MARGINS_MM: [number, number] = [16, 16]
export const MM_PIXEL_SCALE = 4
export const MM_PDF_UNITS_SCALE = 2.83464567
export const PIXEL_PDF_UNITS_SCALE = MM_PDF_UNITS_SCALE / MM_PIXEL_SCALE
export const A4_DIMS_PIXELS: [number, number] = [
  A4_DIMS_MM[0] * MM_PIXEL_SCALE,
  A4_DIMS_MM[1] * MM_PIXEL_SCALE,
]
export const A4_MARGINS_PIXELS: [number, number] = [
  A4_MARGINS_MM[0] * MM_PIXEL_SCALE,
  A4_MARGINS_MM[1] * MM_PIXEL_SCALE,
]
export const A4_DIMS_PDF_UNITS: [number, number] = [
  A4_DIMS_MM[0] * MM_PDF_UNITS_SCALE,
  A4_DIMS_MM[1] * MM_PDF_UNITS_SCALE,
]
export const A4_MARGINS_PDF_UNITS: [number, number] = [
  A4_MARGINS_MM[0] * MM_PDF_UNITS_SCALE,
  A4_MARGINS_MM[1] * MM_PDF_UNITS_SCALE,
]

// TEMPLATES
export const defaultInitialValue: Descendant[] = [
  {
    type: 'span',
    children: [
      {
        text: 'Template text...',
      },
    ],
  },
]

export const emptyInitialTemplateValue: Descendant[] = [
  {
    type: 'span',
    children: [
      {
        text: '',
      },
    ],
  },
]

import { CloseIcon } from '@chakra-ui/icons'
import { Box, Button, Center, CircularProgress, Flex, HStack, Text } from '@chakra-ui/react'
import {
  Descendant,
  EditorVersion,
  Template,
  TemplateKey,
  templateKeyToCollection,
  WithId,
} from '@hb/shared'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ReactEditor } from 'slate-react'
import { DefaultModalProps } from '../Modals/DefaultModal'
import { EditorModalContainer, EditorSize, SubmitText } from '../RichText'
import { TemplateToolbar } from '../RichText/TemplateToolbar'
import { TextEditor } from '../RichText/TextEditor'
import { AssessmentEditorProvider } from './contexts/documentEditor'

import { useDocument } from '../../hooks/backend/useDocument'
import { useTemplateData } from './contexts/data'
import { useTemplateView } from './contexts/view'
import { EditorFaxButton, useOnFax } from './EditorFaxButton'
import { EditorSaveButton, useOnSave } from './EditorSaveButton'
import { TextEditorSubmitButton, useOnSubmit } from './EditorSubmitButton'
import { ExternalPdfTemplateView } from './ExternalPdf/ExternalPdfTemplateView'
import { TemplateMenu } from './TemplateMenu'
import { TemplateViewProps } from './types'
import { getWithVariables } from './withVariables'

export const TemplateViewModal: React.FC<
  Omit<TemplateViewProps, 'editorSize'> & Omit<DefaultModalProps, 'render'>
> = ({ id, templateType, editorVersion, submitText, ...modalProps }) => (
  <EditorModalContainer
    render={(editorSize, onBack) => (
      <TemplateViewBody
        templateType={templateType}
        editorVersion={editorVersion}
        onBack={onBack}
        id={id}
        editorSize={editorSize}
        submitText={submitText}
      />
    )}
    {...modalProps}
  />
)
const TemplateViewHeader = ({
  setManualTemplateId,
  manualTemplateSelectedOn,
  usedVersion,
  onBack,
  templateType,
  template,
  submitText,
}: {
  manualTemplateSelectedOn: React.MutableRefObject<number>
  setManualTemplateId: (id: string) => void
  usedVersion: EditorVersion
  onBack?: () => void
  templateType: TemplateKey
  template: WithId<Template> | null
  submitText: SubmitText
}) => {
  const {
    shortcutArgs: { assessment },
  } = useTemplateData()

  const { onFax, onSave, onSubmit, updateInitialText } = useTemplateView()

  const saveManager = useOnSave(usedVersion)

  const faxManager = useOnFax()
  const submitManager = useOnSubmit(templateType, usedVersion, submitText)
  return (
    <HStack align="center" width="100%" px={2} py={1} zIndex={4} bg="white">
      <Box minW="0" flex={1}>
        <TemplateMenu
          insuranceProviderId={assessment?.insuranceProvider?.id}
          onChange={(t: WithId<Template>) => {
            manualTemplateSelectedOn.current = Date.now()
            setManualTemplateId(t.id)
            if (updateInitialText) updateInitialText(t.editorVersion || 'v1', t.templateText || [])
          }}
          template={template}
          templateType={templateType}
        />
      </Box>
      <Flex gap={1}>
        {onFax && !submitManager.submitConfirm ? <EditorFaxButton {...faxManager} /> : null}
        {onSubmit && !faxManager.faxSending ? <TextEditorSubmitButton {...submitManager} /> : null}
        {onSave && !submitManager.submitConfirm ? <EditorSaveButton {...saveManager} /> : null}
        {onBack ? (
          <Button
            variant="outline"
            onClick={onBack}
            size="xs"
            bg="gray.50"
            color="gray.500"
            alignItems="center"
            gap={1}>
            <CloseIcon position="relative" top="1px" w={2} />
            <Text>Close</Text>
          </Button>
        ) : null}
      </Flex>
    </HStack>
  )
}

const RichTextTemplateView = ({
  manualTemplateSelectedOn,
  template,
  editorVersion,
  templateType,
  editorSize,
  submitText,
  setManualTemplateId,
  onBack,
}: {
  manualTemplateSelectedOn: React.MutableRefObject<number>
  template: WithId<Template> | null
  editorVersion: EditorVersion
  templateType: TemplateKey
  editorSize: EditorSize
  submitText: SubmitText
  setManualTemplateId: (id: string) => void
  onBack?: () => void
}) => {
  const { initialText } = useTemplateView()
  const initTextUpdatedOn = useRef(Date.now())

  const [initText, setInitialText] = useState(initialText || template?.templateText)
  const [isRestarting, setIsRestarting] = useState(false)

  const t0 = useRef<ReturnType<typeof setTimeout> | undefined>()
  const restartWithText = useCallback((text: Descendant[]) => {
    setIsRestarting(true)
    if (t0.current) clearTimeout(t0.current)
    setInitialText(text)
    t0.current = setTimeout(() => {
      setIsRestarting(false)
    }, 250)
  }, [])
  useEffect(() => {
    if (initialText?.length) {
      initTextUpdatedOn.current = Date.now()
      restartWithText(initialText || [])
    }
  }, [restartWithText, initialText])

  const usedVersion = useMemo(
    () => (template ? template?.editorVersion || 'v1' : editorVersion),
    [template, editorVersion],
  )

  const ref = useRef<ReactEditor>(null)

  const decorators = useMemo(() => [getWithVariables(usedVersion)], [usedVersion])

  const toolbars = useMemo(() => [TemplateToolbar], [])
  return isRestarting ? (
    <Center gap={2} w={`${editorSize.width}px`} h={`${editorSize.height}px`} p={4}>
      <CircularProgress color="green.500" size={8} isIndeterminate />
      <Text>Loading template...</Text>
    </Center>
  ) : (
    <TextEditor
      withDownload
      version={usedVersion}
      decorators={decorators}
      toolbars={toolbars}
      templateType={templateType}
      value={initText}
      ref={ref}
      {...editorSize}>
      <TemplateViewHeader
        manualTemplateSelectedOn={manualTemplateSelectedOn}
        setManualTemplateId={setManualTemplateId}
        usedVersion={usedVersion}
        onBack={onBack}
        templateType={templateType}
        template={template}
        submitText={submitText}
      />
    </TextEditor>
  )
}

const TemplateViewBody: React.FC<TemplateViewProps> = ({
  id,
  editorVersion,
  editorSize,
  onBack,
  templateType,
  submitText,
}) => {
  const manualTemplateSelectedOn = useRef(Date.now())
  const [manualTemplateId, setManualTemplateId] = useState<string | undefined>()
  const collection = useMemo(() => templateKeyToCollection[templateType], [templateType])
  const { data: template, loading: templateLoading } = useDocument<Template>(
    collection,
    manualTemplateId || id,
  )

  const { updateInitialText } = useTemplateView()
  const initLoad = useRef(false)
  useEffect(() => {
    if (!initLoad.current && id && template) {
      if (updateInitialText)
        updateInitialText(template.editorVersion || 'v1', template.templateText || [])
      initLoad.current = true
    }
  }, [template, updateInitialText, id])

  if (templateLoading)
    return (
      <Center w={`${editorSize.width}px`} h={`${editorSize.height}px`} p={4}>
        <CircularProgress color="gray.500" size={5} isIndeterminate />
      </Center>
    )
  if (template?.isExternalPdf) {
    return (
      <ExternalPdfTemplateView
        template={template}
        templateType={templateType}
        submitText={submitText}
        manualTemplateSelectedOn={manualTemplateSelectedOn}
        setManualTemplateId={setManualTemplateId}
        height={editorSize.height}
        width={editorSize.width}
        onClose={onBack}
      />
    )
  }
  return (
    <RichTextTemplateView
      template={template}
      manualTemplateSelectedOn={manualTemplateSelectedOn}
      editorVersion={editorVersion}
      templateType={templateType}
      editorSize={editorSize}
      submitText={submitText}
      setManualTemplateId={setManualTemplateId}
      onBack={onBack}
    />
  )
}

export const AssessmentTemplateView: React.FC<{
  initialText: Descendant[]
  updateInitialText: (version: EditorVersion, text: Descendant[]) => void
  size: EditorSize
  version: EditorVersion
  submitText: SubmitText
  templateId?: string
}> = ({ initialText, size, submitText, templateId, version, updateInitialText }) => (
  <AssessmentEditorProvider updateInitialText={updateInitialText} initialText={initialText}>
    <TemplateViewBody
      editorSize={size}
      editorVersion={version}
      templateType="assessments"
      submitText={submitText}
      id={templateId}
    />
  </AssessmentEditorProvider>
)

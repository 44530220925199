import { CalendarIcon } from '@chakra-ui/icons'
import { BoxProps, Flex, FlexProps, HStack, StackProps, Text } from '@chakra-ui/react'
import {
  ASSESSMENTS_ADMIN,
  Claim,
  colors,
  FieldTypes,
  getCoverageLabel,
  getCoverageText,
  getDateString,
  InsuranceCoverage,
  InsuranceCoverageId,
  ListItem,
  NextAction,
  parseNextActionDate,
  providersCollection,
  TextAreaField,
  UpdateCallback,
} from '@hb/shared'

import { deleteField, doc, DocumentReference, updateDoc } from 'firebase/firestore'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { db } from '../../../backend'
import { useCollectionItem } from '../../../hooks/backend/useCollectionItem'
import { useMe } from '../../../hooks/backend/useMe'
import { Editable } from '../../forms/Input/index'

const nextActionField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Next action',
  optional: true,
}

const NextActionContent = ({
  nextAction,
  onSubmit,
  flexProps,
  dataCellProps,
  dateContainerProps,
}: {
  nextAction: NextAction | undefined
  onSubmit: (updated: string) => Promise<UpdateCallback>
  flexProps?: FlexProps
  dataCellProps?: BoxProps
  dateContainerProps?: StackProps
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const text = useMemo(
    () => (nextAction?.text && nextAction.text !== 'zzz' ? nextAction.text : ''),
    [nextAction],
  )
  return (
    <Flex align="flex-start" minW="0" flex={1} {...flexProps}>
      <Editable
        onSubmit={onSubmit}
        value={text}
        editableStackProps={{ bg: 'transparent', boxShadow: 'none' }}
        openCallback={() => setIsEditing(true)}
        closeCallback={() => setIsEditing(false)}
        dataCellProps={{
          fontSize: 'sm',
          borderRadius: '4px',
          border: '1px solid #cdcdcd',
          background: 'white',
          px: 2,
          ...dataCellProps,
        }}
        style={{
          flex: 1,
          minWidth: 0,
        }}
        field={nextActionField}
      />
      {nextAction?.updatedOn && !isEditing ? (
        <HStack px={1} py={2} spacing={1} {...dateContainerProps}>
          <CalendarIcon width={3} color="gray.600" />
          <Text fontWeight={500} color="gray.600" fontSize="xs">
            {getDateString(nextAction.updatedOn, 'short', false)}
          </Text>
        </HStack>
      ) : null}
    </Flex>
  )
}

export const CollectionNextActionContent: FC<{
  collection: string
  id?: string
  item?: ListItem
}> = ({ item, collection, id }) => {
  // const { nextAction } = item || {}
  const { nextAction, nextActionText, nextActionDate } =
    (item as {
      nextAction?: NextAction
      nextActionText?: string
      nextActionDate?: number
    }) || {}
  const displayedNextAction = useMemo<NextAction>(
    () =>
      nextAction || {
        text: nextActionText || '',
        date: nextActionDate || 0,
      },
    [nextAction, nextActionText, nextActionDate],
  )

  // const nextAction = item && 'nextAction' in item ? item.nextAction : {}
  const ref = useMemo(
    () => (id ? (doc(db, collection, id) as DocumentReference<ListItem>) : null),
    [collection, id],
  )
  const getNextAction = useGetNextAction()
  const handleSubmit = useCallback(
    async (updatedText: string | null): Promise<UpdateCallback> => {
      if (!ref) return { error: 'Internal error' }
      const updated = getNextAction(updatedText || '')
      try {
        return updateDoc(ref, {
          nextActionText: updatedText,
          nextActionDate: parseNextActionDate(updatedText || ''),
          nextAction: updated,
        }).then(() => ({ success: 'Next action updated!' }))
      } catch (err: any) {
        console.error(err)
        return { error: 'Internal error' }
      }
    },
    [ref, getNextAction],
  )
  return <NextActionContent nextAction={displayedNextAction} onSubmit={handleSubmit} />
}

export const AssessmentNextAction = ({
  item,
  collection,
  id,
  ...props
}: FlexProps & { item?: ListItem; collection: string }) => (
  <Flex width="100%" px={1} bg={`${colors.pink.hex}99`} align="center" {...props}>
    <Text
      whiteSpace="nowrap"
      fontSize="sm"
      position="relative"
      px={2}
      color="gray.500"
      fontWeight={600}>
      Next Action:
    </Text>
    <CollectionNextActionContent id={id} collection={collection} item={item} />
  </Flex>
)

export const useGetNextAction = () => {
  const me = useMe()
  return useCallback(
    (text: string) => {
      if (!me) throw new Error('Not logged in')
      return {
        text,
        updatedOn: Date.now(),
        updatedBy: me.uid,
      }
    },
    [me],
  )
}

export const useSubmitCoverageNextAction = (assessmentId: string, id: InsuranceCoverageId) => {
  const getNextAction = useGetNextAction()
  return useCallback(
    async (updated: string): Promise<UpdateCallback> => {
      const ref = doc(db, ASSESSMENTS_ADMIN, assessmentId)
      return updateDoc(ref, `nextActions.${id}`, updated ? getNextAction(updated) : deleteField())
        .then(() => ({
          success: 'Next action updated!',
        }))
        .catch((err: any) => {
          console.error(err)
          return { error: err?.message || 'Internal error' }
        })
    },
    [id, assessmentId, getNextAction],
  )
}

export const CoverageNextActionContent = ({
  assessmentId,
  id,
  nextAction,
  dataCellProps,
  dateContainerProps,
  flexProps,
}: {
  assessmentId: string
  id: InsuranceCoverageId
  nextAction: NextAction | undefined
  dataCellProps?: BoxProps
  dateContainerProps?: StackProps
  flexProps?: FlexProps
}) => {
  const handleSubmit = useSubmitCoverageNextAction(assessmentId, id)
  const fProps = useMemo(() => ({ align: 'center', ...flexProps }), [flexProps])
  return (
    <Flex
      bg="whiteAlpha.600"
      borderTop="1px solid #00000033"
      align="flex-start"
      w="100%"
      px={2}
      minW="0">
      <NextActionContent
        dataCellProps={dataCellProps}
        flexProps={fProps}
        nextAction={nextAction}
        onSubmit={handleSubmit}
        dateContainerProps={dateContainerProps}
      />
    </Flex>
  )
}

export const CoverageNextAction = ({
  assessmentId,
  id,
  coverage,
  nextAction,
}: {
  assessmentId: string
  id: InsuranceCoverageId
  coverage: InsuranceCoverage | undefined
  nextAction: NextAction | undefined
}) => {
  const handleSubmit = useSubmitCoverageNextAction(assessmentId, id)
  const label = getCoverageLabel(id, coverage)
  const { item: insuranceProvider } = useCollectionItem(
    providersCollection,
    coverage?.insuranceProviderId,
  )
  const coverageText = getCoverageText(coverage, insuranceProvider)
  return (
    <Flex
      bg="whiteAlpha.600"
      borderTop="1px solid #00000033"
      w="100%"
      direction="column"
      pb={1}
      pt={2}
      px={2}>
      <Text lineHeight={1} color="#777" whiteSpace="pre" fontSize="0.8rem" fontWeight={600}>
        {label.toUpperCase()} - {coverageText}
      </Text>
      <Flex w="100%">
        <NextActionContent nextAction={nextAction} onSubmit={handleSubmit} />
      </Flex>
    </Flex>
  )
}

const getServiceType = (claim: Claim) => claim?.serviceType || claim?.legacy?.serviceType
export const ClaimNextAction = ({
  claimId,
  claim,
  ...props
}: FlexProps & {
  claim: Claim
  claimId: string
}) => (
  <Flex bg="#efefef" align="flex-start" py={1} w="300px" minW="0" {...props}>
    <Text px={2} pt={1} color="#777" whiteSpace="pre" fontSize="0.8rem" fontWeight={600}>
      {getServiceType(claim)?.toString().toUpperCase() || 'NO SERVICE TYPE'}
    </Text>
    <CollectionNextActionContent id={claimId} item={claim} collection="claims" />
  </Flex>
)

import { Center, Flex, forwardRef, Stack, Text, Tooltip } from '@chakra-ui/react'
import {
  AssessmentStageProgress,
  AssessmentStepKey,
  getAdditionalPlansRequested,
  getCoverageConfirmationRequired,
  PopulatedAssessment,
} from '@hb/shared'
import React, { PropsWithChildren, useCallback, useMemo } from 'react'

type UnderlineButtonProps = PropsWithChildren<{
  onClick: () => void
  width: number | string
  title: string
  isActive: boolean
  progress?: number
  isLast?: boolean
  isFirst?: boolean
  isVisible: boolean
}>
export const UnderlineButton = forwardRef<UnderlineButtonProps, 'div'>(
  ({ onClick, children, width, isActive, isLast, isVisible, isFirst, title, progress }, ref) => (
    <Center
      ref={ref}
      onClick={onClick}
      cursor="pointer"
      aria-label={title}
      opacity={isVisible ? 1 : 0}
      transition="all 1000ms"
      pos="relative"
      height="40px"
      borderStartRadius={isFirst ? 8 : 0}
      borderEndRadius={isLast ? 8 : 0}
      bg="whiteAlpha.800"
      width={isVisible ? width : 0}>
      <Flex
        top="0"
        left="0"
        transition={'width 1500ms cubic-bezier(0.16, 1, 0.3, 1)'}
        height="100%"
        borderStartRadius={isFirst ? 8 : 0}
        borderEndRadius={isLast ? 8 : 0}
        overflow="hidden"
        pos="absolute"
        bg="green.300"
        width={`${progress}%`}
        zIndex={1}
      />
      <Flex
        bottom="0"
        transform={`translate(0, ${isActive ? 8 : -5}px)`}
        bg="whiteAlpha.800"
        width="100%"
        height={'4px'}
        opacity={isActive ? 1 : 0}
        transition="all 1000ms cubic-bezier(0.16, 1, 0.3, 1)"
        borderStartRadius={isFirst ? 'md' : 'none'}
        borderEndRadius={isLast ? 'md' : 'none'}
        pos="absolute"
      />
      {children}
    </Center>
  ),
)

export const StageProgress: React.FC<{
  id: AssessmentStepKey
  isLast?: boolean
  isFirst?: boolean
  isActive: boolean
  index: number
  numItems: number
  stage: AssessmentStageProgress
  onClick: () => void
}> = ({ id, isLast, stage, onClick, isActive, numItems, isFirst }) => {
  return (
    <Tooltip
      colorScheme="green"
      bg="green.500"
      label={
        id === 'view'
          ? "When you submit the questionnaire, we'll look at your information and send you an assessment on your coverage"
          : 'After you view the assessment, you can choose to officially sign on for coverage'
      }
      isDisabled={stage.active}>
      <UnderlineButton
        isFirst={isFirst}
        isLast={isLast}
        isActive={isActive}
        isVisible={stage.visible}
        onClick={onClick}
        title={stage.name}
        width={`${100 / numItems}%`}
        progress={stage.progress}>
        <Text
          zIndex={1}
          color={stage.active ? 'green.700' : 'gray.400'}
          fontWeight={500}
          fontSize="xs"
          textAlign="center">
          {stage.name.toUpperCase()}
        </Text>
      </UnderlineButton>
    </Tooltip>
  )
}

export const AssessmentProgress: React.FC<{
  assessment?: PopulatedAssessment | null
  stage: AssessmentStepKey
  selectStage: (k: AssessmentStepKey) => void
}> = ({ assessment, stage, selectStage }) => {
  const { skippedQuestionnaire, signedOnDate } = assessment || {}
  const additionalPlansRequired = useMemo(
    () =>
      assessment
        ? getCoverageConfirmationRequired(assessment) || getAdditionalPlansRequested(assessment)
        : true,
    [assessment],
  )
  const bars = useMemo<Record<AssessmentStepKey, AssessmentStageProgress>>(() => {
    const getQuestionsProgress = () => {
      if (skippedQuestionnaire) {
        if (signedOnDate) return 100
        return 0
      }
      switch (assessment?.status) {
        case 'questions-complete':
          return 66
        case 'incomplete':
          return 33
        case 'awaiting-questionnaire':
          return 0
        default:
          return 100
      }
    }

    const getSignOnProgress = () => {
      let score = 0
      if (signedOnDate) score += 50
      if (!additionalPlansRequired) score += 50
      return score
    }
    const questionsProgress = getQuestionsProgress()
    const b: Record<AssessmentStepKey, AssessmentStageProgress> = {
      questions: {
        name: 'Questions',
        progress: questionsProgress,
        active: true,
        visible: !skippedQuestionnaire,
      },
      view: {
        name: 'Assessment',
        progress: assessment?.resultsViewedOn ? 100 : 0,
        active: !!assessment?.sentOn && questionsProgress === 100,
        visible: !skippedQuestionnaire,
      },
      signOn: {
        name: 'Sign On',
        progress: getSignOnProgress(),
        active: skippedQuestionnaire || !!assessment?.resultsViewedOn,
        visible: true,
      },
    }

    return b
  }, [assessment, signedOnDate, skippedQuestionnaire, additionalPlansRequired])

  const handleClick = useCallback(
    (key: AssessmentStepKey) => {
      if (bars[key]?.active) {
        selectStage(key as AssessmentStepKey)
      }
    },
    [bars, selectStage],
  )

  const visibleIndices = useMemo(() => {
    let numVisible = 0
    const indices: number[] = []
    Object.values(bars).forEach(b => {
      indices.push(numVisible)
      if (b.visible) {
        numVisible++
      }
    })
    return indices
  }, [bars])

  const numBars = useMemo(() => Object.values(bars).filter(b => b.visible).length, [bars])
  return (
    <Stack mt={1} mb={3} direction="row" spacing={1} w="100%" borderRadius="md">
      {Object.entries(bars).map(([key, b], i) => (
        <StageProgress
          onClick={() => handleClick(key as AssessmentStepKey)}
          key={key as AssessmentStepKey}
          numItems={numBars}
          isActive={key === stage}
          stage={b}
          id={key as AssessmentStepKey}
          index={i}
          isFirst={visibleIndices[i] === 0}
          isLast={visibleIndices[i] === numBars - 1}
        />
      ))}
    </Stack>
  )
}

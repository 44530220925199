import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import { get as nestedGet, set as nestedSet } from 'nested-property'
import {
  APPOINTMENTS,
  ASSESSMENTS,
  ASSESSMENTS_ADMIN,
  CONSENT_FORMS,
  getPracticeAssessmentCollectionPath,
  MIDWIVES,
  VISITS,
  VISIT_DRAFTS,
} from '../collections'
import { colors, FieldTypes } from '../constants'
import { callInField, getBasicInfoField, policyOwnerFieldMap } from '../fields'
import { planDesignField } from '../fields/callIn/planDesignAndStateMandates'
import { stateOptions } from '../fields/shared'
import { assessmentFields, getNestedUserFields } from '../fields/userFields'
import {
  AdditionalCoverageId,
  AdminAssessmentData,
  Assessment,
  AssessmentForm,
  AssessmentFormAlias,
  AssessmentFormKey,
  AssessmentFormSnippet,
  AssessmentSnippet,
  AssessmentStage,
  AssessmentStatus,
  BaseInsuranceCoverage,
  Claim,
  ConfirmCoverageStatus,
  CoverageStageStatus,
  DropdownField,
  FieldMap,
  FieldMapValue,
  InsuranceCoverage,
  InsuranceCoverageId,
  InsuranceCoverageRequest,
  InsuranceCoverageType,
  InsurancePlans,
  InsuranceProvider,
  isInsuranceCoverageRequest,
  Log,
  NextAction,
  NextActions,
  PopulatedAssessment,
  PracticeAssessmentData,
  PracticeAssessmentStage,
  PracticeWithAdmin,
  RedFlagRecord,
  UserGroup,
  WithId,
  WithInsuranceCoverageId,
} from '../types'
import {
  capitalizeFirstLetterOfEachWord,
  objectToArray,
  parseNextActionDate,
  toSearchString,
} from './data'
import { getDateString } from './dates'
import {
  defaultStageValidate,
  formatField,
  getFieldIsRedFlagged,
  isFieldMap,
  isInfoStage,
  isListField,
} from './fields'
import { getFormStatus } from './forms'
import { getCorrectedValue } from './populated'

export const getUrgentString = (log: Log | undefined | null) => {
  if (log) {
    const logKeys = Object.keys(log)
    for (let i = 0; i < logKeys.length; i += 1) {
      const logEntry = log[parseInt(logKeys[i], 10)]
      if (logEntry.urgent) return `From log - ${logEntry.text}`
    }
  }
  return undefined
}

export const getUrgentSort = (adminData?: AdminAssessmentData | Claim) => {
  const { urgentColor, urgentReason, log } = adminData || {}
  const logUrgent = getUrgentString(log)
  return urgentColor || (urgentReason || logUrgent ? colors.red.hex : 'zzz')
}

export const getLogInfo = (log?: Log | null) => {
  const logKeys = log ? Object.keys(log).map(key => parseInt(key, 10)) : undefined
  let lastUpdatedKey
  let lastCreatedKey
  let lastUpdated
  let lastCreated
  if (log) {
    lastUpdatedKey = logKeys?.sort(
      (a: number, b: number) => (log![b].updatedOn || b) - (log![a].updatedOn || a),
    )[0]
    lastCreatedKey = logKeys?.sort((a, b) => b - a)[0]
    if (lastUpdatedKey) lastUpdated = log[lastUpdatedKey]
    if (lastCreatedKey) lastCreated = log[lastCreatedKey]
  }

  return {
    lastCreated,
    lastUpdated,
    lastCreatedKey,
    lastUpdatedKey,
  }
}

export const getAvailableForms = (admin: boolean, assessment: PopulatedAssessment | null) => {
  const forms: AssessmentFormSnippet[] = []
  let currentFormId: AssessmentFormKey | null = 'introduction'
  while (currentFormId) {
    const form: AssessmentForm | AssessmentFormAlias | undefined = assessmentFields[currentFormId]
    if (form) {
      if (typeof form !== 'string') {
        const status = getFormStatus(form, assessment?.data)
        forms.push({ id: currentFormId, status })
        if (status !== 'complete') {
          currentFormId = null
        } else if (form.getNextFormId) {
          currentFormId = form.getNextFormId(admin, assessment)
        } else {
          currentFormId = null
        }
      } else {
        forms.push({ id: currentFormId, status: 'info' })
        currentFormId = null
      }
    } else if (currentFormId) {
      forms.push({ id: currentFormId, status: 'info' })
      currentFormId = null
    } else {
      console.error(`Can't find form with id: ${currentFormId}`)
      currentFormId = null
    }
  }

  return forms
}

export const getCurrentlyOnMedicaidPlan = (data?: FieldMapValue) =>
  data?.['insurance-info']?.option === 'medicaid' ||
  (data?.['insurance-info']?.option === 'two-plans' &&
    !!data?.['insurance-info']?.medicaidCoverage?.currentlyOnMedicaidPlan)

export const getOnlyOnMedicaidPlan = (data?: FieldMapValue) =>
  data?.['insurance-info']?.option === 'medicaid'

export const getPrimaryCoverage = (data?: FieldMapValue): InsuranceCoverage | null => {
  const coverage = data?.['insurance-info']?.primaryCoverage
  if (!coverage) return null
  return {
    ...coverage,
    isMedicaid: false,
  }
}

export const getMedicaidCoverage = (data?: FieldMapValue): InsuranceCoverage | null => {
  const coverage = data?.['insurance-info']?.medicaidCoverage
  if (!coverage) return null
  return {
    ...coverage,
    isMedicaid: true,
  }
}

export const getSecondaryCoverage = (data?: FieldMapValue) =>
  data?.['insurance-info']?.secondaryCoverage

export const getMidwifeId = (data?: FieldMapValue) => data?.delivery?.midwifeId

export const mergeAssessmentData = (assessment?: Assessment | PopulatedAssessment) =>
  merge({}, assessment?.data, assessment?.corrections)

export const mergeAssessmentSignOnData = (assessment?: Assessment) =>
  merge({}, assessment?.signOnData, assessment?.signOnCorrections)

export const getCoverageText = (
  coverage: BaseInsuranceCoverage | null | undefined,
  insurer: InsuranceProvider | undefined | null,
) => {
  const insuranceProviderName: string = insurer?.name || '(No Insurer)'

  const { isMedicaid } = coverage || {}
  // const isMedicaid = getCurrentlyOnMedicaidPlan(assessment.mergedData)

  const planName = coverage?.planName === "Other / I don't know" ? 'Other' : coverage?.planName
  if (isMedicaid) {
    if (planName && insurer?.plans?.find(p => p.name === planName)) {
      return `${insuranceProviderName} Medicaid - ${planName}`
    }
    return `${insuranceProviderName} Medicaid`
  }
  if (planName && insurer?.plans?.find(p => p.name === planName)) {
    return `${insuranceProviderName} - ${planName}`
  }
  return insuranceProviderName
}

export const getInsuranceCoverageText = (assessment?: PopulatedAssessment) => {
  if (!assessment) return 'zzz'
  const { insuranceProvider, selectedCoverage } = assessment
  const option = getCorrectedValue(assessment, 'insurance-info.option')
  if (option === 'no-coverage') return 'No coverage'
  const isMedicaid = getCurrentlyOnMedicaidPlan(assessment.mergedData)
  return getCoverageText(
    selectedCoverage ? { ...selectedCoverage, isMedicaid } : null,
    insuranceProvider,
  )
}

export const getAssessmentStatus = (
  assessment: PopulatedAssessment,
  adminData?: AdminAssessmentData | PopulatedAssessment | null,
): AssessmentStatus => {
  if (assessment) {
    const { data } = assessment || {}
    if (assessment?.resultsViewedOn) return 'viewed'
    if (assessment?.sentOn) return 'sent'
    if (adminData?.draftSavedOn) return 'draft-saved'
    if (assessment?.submittedOn) return 'submitted'
    if (data) {
      const formStatus = defaultStageValidate(getNestedUserFields(true, assessment), true)(data)
      const answersComplete = !(formStatus && Object.keys(formStatus).length)
      return answersComplete ? 'questions-complete' : 'incomplete'
    }
  }
  return 'awaiting-questionnaire'
}

export const getLogSnippet = (log?: Log | null): Log | null => {
  const { lastCreated, lastCreatedKey } = getLogInfo(log)
  return lastCreated && lastCreatedKey ? { [lastCreatedKey]: lastCreated } : null
}

// 0 awaiting questionnaire
// 1 questions complete, awaiting assessment
// 2 assessment sent, awaiting sign on by patient
// 3 awaiting sign on by midwife
// 4 signed on
export const getInquiryRank = ({
  assessment,
  signedOnByPractice,
}: {
  assessment?: Assessment | null
  signedOnByPractice: boolean
}) => {
  const { sentOn, submittedOn, signedOnDate } = assessment || {}
  let inquiryRank = 4
  if (signedOnByPractice) inquiryRank = 3
  else if (submittedOn) {
    inquiryRank = 1
    if (sentOn) {
      inquiryRank = 4
      if (signedOnDate) {
        inquiryRank = 2
      }
    }
  }

  return inquiryRank
}

const populateAssessmentData = (
  data: FieldMapValue | undefined,
  providers: Record<string, PracticeWithAdmin>,
  insurers: Record<string, InsuranceProvider>,
) => {
  if (!data) return undefined
  const populated = cloneDeep(data)
  const midwifeId = data?.delivery?.midwifeId
  if (populated?.delivery?.midwife) delete populated.delivery.midwife
  if (populated?.['insurance-info']?.primaryCoverage?.insuranceProvider) {
    delete populated['insurance-info'].primaryCoverage.insuranceProvider
  }
  if (populated?.['insurance-info']?.secondaryCoverage?.insuranceProvider) {
    delete populated['insurance-info'].secondaryCoverage.insuranceProvider
  }
  if (populated?.['insurance-info']?.medicaidCoverage?.insuranceProvider) {
    delete populated['insurance-info'].medicaidCoverage.insuranceProvider
  }
  const primaryInsurerId = data?.['insurance-info']?.primaryCoverage?.insuranceProviderId
  const secondaryInsurerId = data?.['insurance-info']?.secondaryCoverage?.insuranceProviderId
  const medicaidInsurerId = data?.['insurance-info']?.medicaidCoverage?.insuranceProviderId

  if (midwifeId && providers[midwifeId]) {
    nestedSet(populated, 'delivery.midwife', providers[midwifeId])
  }
  if (primaryInsurerId && insurers[primaryInsurerId]) {
    nestedSet(
      populated,
      'insurance-info.primaryCoverage.insuranceProvider',
      insurers[primaryInsurerId],
    )
  }
  if (secondaryInsurerId && insurers[secondaryInsurerId]) {
    nestedSet(
      populated,
      'insurance-info.secondaryCoverage.insuranceProvider',
      insurers[secondaryInsurerId],
    )
  }
  if (medicaidInsurerId && insurers[medicaidInsurerId]) {
    nestedSet(
      populated,
      'insurance-info.medicaidCoverage.insuranceProvider',
      insurers[medicaidInsurerId],
    )
  }

  return populated
}

export const getRedFlaggedFields = (
  fieldMap: FieldMap,
  values?: FieldMapValue,
): RedFlagRecord | undefined => {
  if (!values) return undefined
  const { children } = fieldMap
  let redFlagged: RedFlagRecord = {}
  const childKeys = Object.keys(children)
  for (let i = 0; i < childKeys.length; i += 1) {
    const key = childKeys[i]
    const child = children[key]
    if (!isInfoStage(child) && !isListField(child)) {
      if (isFieldMap(child)) {
        const childRedFlagged = getRedFlaggedFields(child, values[key])
        if (childRedFlagged) redFlagged = { ...redFlagged, ...childRedFlagged }
      } else {
        const childRedFlaggedReason = getFieldIsRedFlagged(child, values[key])
        if (childRedFlaggedReason) {
          redFlagged[key] = {
            reason: childRedFlaggedReason,
            header: child.placeholder,
          }
        }
      }
    }
  }
  return Object.keys(redFlagged).length !== 0 ? redFlagged : undefined
}

export const getAssessmentRedFlags = (assessment: Partial<PopulatedAssessment>) => {
  let redFlagged: RedFlagRecord = {}
  // if no unsent assessments, no red flags
  if (assessment.sentOn) return undefined
  if (assessment.insuranceProvider?.redFlagged) {
    redFlagged.insuranceProvider = {
      header: assessment.insuranceProvider.name,
      reason: assessment.insuranceProvider.redFlaggedReason || 'Insurance provider red-flagged',
    }
  }

  if (assessment.midwife?.redFlagged) {
    redFlagged.midwife = {
      header: assessment?.midwife?.name || 'Error getting midwife name',
      reason: assessment?.midwife?.redFlaggedReason || 'Midwife red-flagged',
    }
  }

  redFlagged = {
    ...redFlagged,
    ...getRedFlaggedFields(
      getNestedUserFields(true, assessment as PopulatedAssessment),
      assessment.mergedData,
    ),
  }

  return Object.keys(redFlagged).length ? redFlagged : undefined
}
export const getSelectedCoverage = (
  mergedData: FieldMapValue,
  insurers: Record<string, InsuranceProvider>,
) => {
  const isMedicaid = getCurrentlyOnMedicaidPlan(mergedData)
  const coverage = isMedicaid ? getMedicaidCoverage(mergedData) : getPrimaryCoverage(mergedData)
  if (coverage) {
    coverage.insuranceProvider =
      insurers?.[coverage?.insuranceProviderId] || coverage.insuranceProvider
  }

  return coverage
}
export const populateAssessment = (
  assessment: Assessment,
  providers: Record<string, PracticeWithAdmin>,
  insurers: Record<string, InsuranceProvider>,
  assessmentSnippet?: AssessmentSnippet | null,
  adminAssessmentData?: AdminAssessmentData | null,
  practiceAssessmentData?: PracticeAssessmentData,
): PopulatedAssessment => {
  const populatedData = populateAssessmentData(assessment.data, providers, insurers)
  const populatedCorrections = populateAssessmentData(assessment.corrections, providers, insurers)

  const mergedData = mergeAssessmentData(assessment)
  const mergedSignOnData = mergeAssessmentSignOnData(assessment)
  let stages: Array<AssessmentStage | PracticeAssessmentStage> = []
  if (practiceAssessmentData) stages = practiceAssessmentData.stages
  else if (adminAssessmentData) stages = adminAssessmentData.stages || []
  const midwifeId = getMidwifeId(mergedData)
  const payments = adminAssessmentData?.payments || practiceAssessmentData?.payments
  const populated: Omit<PopulatedAssessment, 'availableForms'> = {
    ...assessment,
    ...practiceAssessmentData,
    delivery: assessmentSnippet?.delivery || practiceAssessmentData?.delivery || undefined,
    archivedOn: assessment.archivedOn || null,
    stages,
    plans: getInsurancePlans(assessment, insurers),
    midwifeId: practiceAssessmentData?.midwifeId || midwifeId,
    data: populatedData,
    corrections: populatedCorrections,
    payments,
  }

  const plans = getInsurancePlans(populated, insurers)
  const selectedCoverage = plans.mainCoverage
  const insuranceProvider = selectedCoverage?.insuranceProvider
  const midwife = midwifeId ? providers[midwifeId] : undefined
  if (midwife) {
    mergedData.delivery.midwife = midwife
  }
  const urgent = getUrgentString(adminAssessmentData?.log)
  const withoutRedFlags: Omit<PopulatedAssessment, 'redFlags'> = {
    ...populated,
    ...assessmentSnippet,
    ...adminAssessmentData,
    archivedOn:
      (practiceAssessmentData ? practiceAssessmentData.archivedOn : assessment.archivedOn) || null,
    status: getAssessmentStatus(populated, adminAssessmentData),
    adminAlerts: adminAssessmentData?.alerts,
    practiceAlerts: practiceAssessmentData?.alerts,
    mergedData,
    mergedSignOnData,
    signedOnDate: assessment.signedOnDate || null,
    selectedCoverage,
    plans,
    insuranceProvider,
    urgent,
    assessmentNote: adminAssessmentData?.assessmentNote || practiceAssessmentData?.assessmentNote,
    sharedNote: assessmentSnippet?.sharedNote || practiceAssessmentData?.sharedNote,
    sharedPaymentNote:
      assessmentSnippet?.sharedPaymentNote || practiceAssessmentData?.sharedPaymentNote,
    midwife,
    midwifeId: midwifeId || undefined,
    receivedPayments: objectToArray(payments?.received || {}),
    duePayments: objectToArray(payments?.due || {}),
    files: assessment.files || {},
    adminFiles: adminAssessmentData?.files || {},
    practiceFiles: practiceAssessmentData?.files || {},
  }
  return {
    ...withoutRedFlags,
    redFlags: getAssessmentRedFlags(withoutRedFlags),
  } as PopulatedAssessment
}

export const getRedFlagText = (redFlags: RedFlagRecord | undefined) =>
  redFlags ? Object.values(redFlags)[0].reason : 'zzz'

export const stringifyNextAction = (
  nextAction: NextAction | null | undefined,
  coverage?: InsuranceCoverage | null,
) => {
  if (!nextAction) return ''
  const { text } = nextAction
  const parts: string[] = []
  if (text) parts.push(text)
  const insuranceProviderName = coverage?.insuranceProvider?.name
  if (insuranceProviderName) parts.push(insuranceProviderName)
  return parts.map(p => toSearchString(p)).join('|')
}

export const stringifySnippet = (snippet: Partial<AssessmentSnippet>, plans: InsurancePlans) => {
  const {
    phone = 'zzz',
    email = 'zzz',
    fname = 'zzz',
    lname = 'zzz',
    nextActionText = 'zzz',
    midwifeName = 'zzz',
    insuranceCoverage = 'zzz',
    partnerEmail,
    assessmentName = 'zzz',
    stages = [],
    status = 'incomplete',
    nickname,
    partnerName,
    nextActions,
    deliveredOn,
    authRefNumbers,
    additionalAuthRefNumbers,
    additionalClaimsRefNumbers,
    claims,
    dob,
    hasOverduePayment,
  } = snippet || {}
  const searchable = [
    fname,
    lname,
    midwifeName,
    insuranceCoverage,
    partnerName,
    partnerEmail,
    assessmentName,
    nickname,
    Object.keys(claims || {}).join('|'),
    authRefNumbers?.join('|') || '',
    nextActions?.primaryCoverage
      ? stringifyNextAction(nextActions.primaryCoverage, plans.primaryCoverage)
      : '',
    nextActions?.medicaidCoverage
      ? stringifyNextAction(nextActions.medicaidCoverage, plans.medicaidCoverage)
      : '',
    Object.entries(nextActions?.additionalPlans || {})
      .filter(t => !!t)
      .map(([planId, v]) => stringifyNextAction(v, plans.additionalPlans?.[planId]))
      .join('|'),
    deliveredOn ? getDateString(deliveredOn, 'short') : '',
    additionalAuthRefNumbers?.map(r => r.referenceNumber).join('|') || '',
    additionalClaimsRefNumbers?.map(r => r.referenceNumber).join('|') || '',
    email,
    phone,
    dob ? getDateString(dob, 'short') : '',
    hasOverduePayment ? 'overdue payment' : '',
    nextActionText,
    ...stages.join('|'),
    status,
  ]

  return toSearchString(searchable.filter(s => !!s && s !== 'zzz').join('|'))
}

export const getPracticeAppointmentsCollectionPath = (midwifeId: string) =>
  `${MIDWIVES}/${midwifeId}/${APPOINTMENTS}`
export const getPracticeAssessmentVisitsCollectionPath = (midwifeId: string) =>
  `${MIDWIVES}/${midwifeId}/${VISITS}`
export const getPracticeVisitDraftsCollectionPath = (midwifeId: string) =>
  `${MIDWIVES}/${midwifeId}/${VISIT_DRAFTS}`
export const getPracticeConsentFormsCollectionPath = (midwifeId: string) =>
  `${MIDWIVES}/${midwifeId}/${CONSENT_FORMS}`

export const getAssessmentName = (
  assessment: Assessment | PracticeAssessmentData | PopulatedAssessment | AssessmentSnippet | null,
  assessmentSnippet?: AssessmentSnippet,
) => {
  const edd = assessmentSnippet?.edd || getCorrectedValue(assessment as Assessment, 'delivery.edd')
  const assessmentName =
    (assessment as Assessment)?.name ||
    (assessment as PracticeAssessmentData)?.assessmentName ||
    assessmentSnippet?.assessmentName
  const enteredName = assessmentName && assessmentName !== 'zzz' ? assessmentName : ''
  return (
    enteredName ||
    `${enteredName ? `${enteredName} | ` : ''} EDD: ${
      edd ? getDateString(edd, 'short') : 'Awaiting'
    }`
  )
}

const getStateAcronym = (state: string) => stateOptions.find(s => state === s.text)?.id

export const getPlanState = (coverage: InsuranceCoverage | null | undefined) => {
  const planStateValue = coverage?.['plan-design-and-state-mandates']?.followsStateMandatesNote
  const completeAcronymMatch = stateOptions.map(s => s.id).find(s => planStateValue === s)
  if (completeAcronymMatch) return completeAcronymMatch

  const fullStateMatch = stateOptions
    .map(s => s.text.toLowerCase())
    .find(s => planStateValue?.toLowerCase().includes(s))
  const fullStateMatchAcronym = fullStateMatch ? getStateAcronym(fullStateMatch) : null
  if (fullStateMatchAcronym) return fullStateMatchAcronym

  // get value without punctuation
  const stateValueNoPunctuation = planStateValue?.replace(/[^a-zA-Z]/g, '')
  const stateAcronymMatch = stateOptions
    .map(s => s.id)
    .find(s => stateValueNoPunctuation?.toLowerCase().split(' ').includes(s.toLowerCase()))

  return stateAcronymMatch || null
}

export const getSnippetPlanState = <T extends PopulatedAssessment = PopulatedAssessment>(
  assessment: T,
) => {
  const mergedData = mergeAssessmentData(assessment)

  const isMedicaidPlan = getCurrentlyOnMedicaidPlan(mergedData)

  // account for legacy value which is note which may contain the state or abbreviation
  const selectedCoverage = getSelectedCoverage(mergedData, {})
  return getPlanState(selectedCoverage ? { ...selectedCoverage, isMedicaid: isMedicaidPlan } : null)
}

export const getSnippetPlanCoverageType = <T extends PopulatedAssessment = PopulatedAssessment>(
  assessment: T,
) => {
  const mergedData = mergeAssessmentData(assessment)

  const isMedicaidPlan = getCurrentlyOnMedicaidPlan(mergedData)
  const planCoverageTypeValue = getCorrectedValue(
    assessment,
    `insurance-info.${isMedicaidPlan ? 'medicaidCoverage' : 'primaryCoverage'}.plan-design-and-state-mandates.planDesign`,
  )

  return formatField(planDesignField, planCoverageTypeValue) || null
}

export const getSnippetIsMarketplacePlan = <T extends PopulatedAssessment = PopulatedAssessment>(
  assessment: T,
) => {
  const isMedicaidPlan = getCurrentlyOnMedicaidPlan(assessment.mergedData)
  const planOption = getCorrectedValue(assessment, 'insurance-info.option')
  return !isMedicaidPlan && planOption !== undefined
}

export const getSnippetPartnerEmail = <T extends PopulatedAssessment = PopulatedAssessment>(
  assessment: T,
) =>
  assessment.mergedSignOnData?.hasPartner
    ? assessment.mergedSignOnData?.partnerContact?.email
    : undefined

export const getSnippetPartnerName = <T extends PopulatedAssessment = PopulatedAssessment>(
  assessment: T,
) =>
  assessment.mergedSignOnData?.hasPartner
    ? assessment.mergedSignOnData?.partnerContact?.name
    : undefined

export const getBlankAssessment = (
  createdBy: string,
  createdByGroup: UserGroup,
  patientId: string,
  midwifeId?: string,
): Assessment => ({
  archivedOn: null,
  createdOn: Date.now(),
  patientId,
  midwifeId: midwifeId || null,
  data: midwifeId ? { delivery: { midwifeId } } : {},
  createdBy,
  createdByGroup,
})
export const getAssessmentFilesCollection = (
  firstArg: string,
  practiceId: string | undefined | null,
) => {
  switch (firstArg) {
    case 'files':
      return ASSESSMENTS
    case 'adminFiles':
      return ASSESSMENTS_ADMIN
    case 'practiceFiles':
      if (!practiceId) throw new Error('No practice ID provided when getting practice file')
      return getPracticeAssessmentCollectionPath(practiceId)
    default:
      throw new Error(`Invalid file id: ${firstArg} - should start with insurance-info or files`)
  }
}

export const getCoverageConfirmationRequired = (
  assessment: Assessment | PopulatedAssessment | undefined,
) => {
  const {
    additionalPlansConfirmedBy,
    additionalPlansConfirmedOn,
    additionalPlansRequestedBy,
    additionalPlansRequestedOn,
    questionnaireSkippedOn,
  } = assessment || {}

  if (questionnaireSkippedOn) return false
  if (!additionalPlansConfirmedBy || !additionalPlansConfirmedOn) return true
  // if any plan requires call in and does not have it, return true

  if (additionalPlansRequestedBy && additionalPlansRequestedOn) {
    if (additionalPlansRequestedOn > additionalPlansConfirmedOn) return true
  }

  return !(!additionalPlansRequestedBy || !additionalPlansRequestedOn)
}

export const getAdditionalPlansRequested = (
  assessment: Assessment | PopulatedAssessment | undefined,
) => {
  const { additionalPlans } = assessment || {}
  return Object.values(additionalPlans || {}).some(p => {
    if (isInsuranceCoverageRequest(p)) return true
    if (p.isMedicaid) return false
    const planCallInErrors = defaultStageValidate(callInField, true)(p)
    return !!planCallInErrors && !!Object.keys(p.callInRequests || {}).length
  })
}

const getRequestStatus = ({ withCallInForm }: InsuranceCoverageRequest) => {
  const res: CoverageStageStatus = {
    incomplete: ['basic-info'],
    required: ['basic-info'],
  }
  if (withCallInForm) {
    res.incomplete.push('call-in')
    res.required.push('call-in')
  }
  res.incomplete.push('policy-owner')
  res.required.push('policy-owner')
  return res
}
export const getCoverageStatus = (
  type: 'primaryCoverage' | 'medicaidCoverage' | 'additionalPlans',
  coverage: BaseInsuranceCoverage | undefined | null,
  optional?: boolean,
  request?: InsuranceCoverageRequest,
): CoverageStageStatus => {
  if (coverage && isInsuranceCoverageRequest(coverage)) return getRequestStatus(coverage)
  const isMedicaid = type === 'medicaidCoverage' || !!coverage?.isMedicaid
  const basicInfoField = getBasicInfoField(isMedicaid)
  const basicInfoComplete = !defaultStageValidate(basicInfoField, true)(coverage || undefined)

  const hasCallInRequests =
    !!Object.keys(coverage?.callInRequests || {}).length || request?.withCallInForm
  const callInRequired =
    !isMedicaid && ((!optional && type === 'primaryCoverage') || hasCallInRequests)

  const callInComplete =
    !callInRequired || !defaultStageValidate(callInField, true)(coverage || undefined)

  // const policyOwnerRequired = type === 'primaryCoverage' || type === 'medicaidCoverage' || callInRequired

  const policyOwnerComplete = !defaultStageValidate(
    policyOwnerFieldMap,
    true,
  )(coverage || undefined)

  const res: CoverageStageStatus = { incomplete: [], required: ['basic-info'] }
  if (!basicInfoComplete) res.incomplete.push('basic-info')
  if (callInRequired) {
    res.required.push('call-in')
    if (!callInComplete) res.incomplete.push('call-in')
  }

  // if (policyOwnerRequired) {
  res.required.push('policy-owner')
  if (!policyOwnerComplete) res.incomplete.push('policy-owner')
  // }

  return res
}

export const getAdditionalPlanStatus = (
  p: InsuranceCoverage | undefined,
  request?: InsuranceCoverageRequest,
) => getCoverageStatus('additionalPlans', p, false, request)

export const getAdditionalPlansStatus = (
  additionalPlans: Record<AdditionalCoverageId, InsuranceCoverage> | undefined,
): Record<AdditionalCoverageId, CoverageStageStatus> => {
  if (!additionalPlans) return {}
  return Object.entries(additionalPlans).reduce(
    (acc, [id, plan]) => ({
      ...acc,
      [id as AdditionalCoverageId]: getAdditionalPlanStatus(plan),
    }),
    {} as Record<AdditionalCoverageId, CoverageStageStatus>,
  )
}

export const getRequestsStatus = (
  additionalPlans: Record<string, InsuranceCoverageRequest> | undefined,
): Record<string, CoverageStageStatus> => {
  if (!additionalPlans) return {}
  return Object.entries(additionalPlans).reduce(
    (acc, [id, plan]) => ({
      ...acc,
      [id]: getAdditionalPlanStatus(undefined, plan),
    }),
    {},
  )
}

export const separateAdditionalPlansAndRequests = (
  additionalPlans: Assessment['additionalPlans'],
) => {
  const { plans, requests } = Object.entries(additionalPlans || {}).reduce(
    (acc, [id, plan]) => {
      if (isInsuranceCoverageRequest(plan)) {
        acc.requests[id] = plan
        return acc
      }
      acc.plans[`additionalPlans.${id}`] = plan
      return acc
    },
    {
      plans: {} as Record<InsuranceCoverageId, InsuranceCoverage>,
      requests: {} as Record<string, InsuranceCoverageRequest>,
    },
  )

  return { plans, requests }
}

export const getCoverageRequiresCallIn = <T extends WithInsuranceCoverageId<BaseInsuranceCoverage>>(
  coverage: T | undefined | null,
  optional?: boolean,
  request?: InsuranceCoverageRequest,
) => {
  if (!coverage) return false
  const type = getCoverageType(coverage.id)
  if (type === 'medicaidCoverage' || coverage?.isMedicaid) return false
  if (request?.withCallInForm) return true
  if (type === 'primaryCoverage' && !optional) return true
  if (type === 'additionalPlans') return !!Object.keys(coverage?.callInRequests || {}).length
  return false
}

export const getCoverageType = (id: InsuranceCoverageId): InsuranceCoverageType => {
  if (id === 'primaryCoverage') return 'primaryCoverage'
  if (id === 'medicaidCoverage') return 'medicaidCoverage'
  return 'additionalPlans'
}

export const getCoverageLabel = (
  id: InsuranceCoverageId,
  coverage?: BaseInsuranceCoverage | null,
) => {
  const type = getCoverageType(id)
  if (coverage?.label) return capitalizeFirstLetterOfEachWord(coverage.label)
  if (type === 'primaryCoverage') return 'Primary'
  if (type === 'medicaidCoverage') return 'Medicaid'
  if (type === 'additionalPlans') return 'Potential'
  return 'Potential'
}

export const getConfirmCoverageStatus = (
  assessment: Assessment | PopulatedAssessment | null | undefined,
): ConfirmCoverageStatus => {
  const primaryCoverage = getPrimaryCoverage(assessment?.data)
  const medicaidCoverage = getMedicaidCoverage(assessment?.data)
  const hasMedicaidCoverage = getHasMedicaidCoverage(assessment?.data)
  const hasPrimaryCoverage = !getOnlyOnMedicaidPlan(assessment?.data)
  const { additionalPlans: additionalPlansAndRequests } = assessment || {}
  const { plans: additionalPlans, requests } = separateAdditionalPlansAndRequests(
    additionalPlansAndRequests,
  )
  return {
    primaryCoverage: hasPrimaryCoverage
      ? getCoverageStatus('primaryCoverage', primaryCoverage)
      : null,
    medicaidCoverage: hasMedicaidCoverage
      ? getCoverageStatus('medicaidCoverage', medicaidCoverage)
      : null,
    additionalPlans: getAdditionalPlansStatus(additionalPlans),
    requests: getRequestsStatus(requests),
  }
}

export const getCoverageComplete = (status: ConfirmCoverageStatus) => {
  const primaryComplete = !status.primaryCoverage?.incomplete?.length
  const medicaidComplete = !status.medicaidCoverage || !status.medicaidCoverage.incomplete.length
  const additionalComplete = !Object.keys(status.additionalPlans || {}).find(
    p => !!status.additionalPlans[p as AdditionalCoverageId]?.incomplete.length,
  )
  return primaryComplete && medicaidComplete && additionalComplete
}

export const getInsurancePlanId = (coverage: WithId<InsuranceCoverage>) => {
  if (coverage.label === 'main') return coverage.isMedicaid ? 'medicaidCoverage' : 'primaryCoverage'
  return `additionalPlans.${coverage.id}`
}

export const getPlanPropPath = (adminView: boolean, id: InsuranceCoverageId) => {
  const dataPath = adminView ? 'corrections' : 'data'
  if (id === 'primaryCoverage') return `${dataPath}.insurance-info.primaryCoverage`
  if (id === 'medicaidCoverage') return `${dataPath}.insurance-info.medicaidCoverage`
  return id
}
export const getHasMedicaidCoverage = (mergedData?: FieldMapValue) =>
  mergedData?.['insurance-info']?.option === 'medicaid' ||
  (mergedData?.['insurance-info']?.option === 'two-plans' &&
    mergedData?.['insurance-info']?.onePlanIsMedicaid)

export const getInsurancePlans = <A extends Assessment | PopulatedAssessment>(
  assessment: A | null | undefined,
  insurers?: Record<string, InsuranceProvider>,
): InsurancePlans => {
  if (!assessment) {
    return {
      isMedicaid: false,
      mainCoverage: null,
      medicaidCoverage: null,
      primaryCoverage: null,
      secondaryCoverage: null,
    }
  }

  const mergedData =
    (assessment as PopulatedAssessment).mergedData || mergeAssessmentData(assessment)
  const additionalPlansArr = objectToArray(assessment?.additionalPlans || {})

  const hasMedicaid = getHasMedicaidCoverage(mergedData)
  const isMedicaid = hasMedicaid && getCurrentlyOnMedicaidPlan(mergedData)
  const primaryCoverage = getPrimaryCoverage(mergedData)
  if (primaryCoverage?.insuranceProviderId && insurers?.[primaryCoverage.insuranceProviderId]) {
    primaryCoverage.insuranceProvider = insurers[primaryCoverage.insuranceProviderId]
  }
  // TODO: Medicaid should not be included unless specified in the future
  // const medicaidCoverage = hasMedicaid ? getMedicaidCoverage(mergedData) : null
  // For now, we are always including it
  const medicaidCoverage = getMedicaidCoverage(mergedData)

  if (medicaidCoverage?.insuranceProviderId && insurers?.[medicaidCoverage.insuranceProviderId]) {
    medicaidCoverage.insuranceProvider = insurers[medicaidCoverage.insuranceProviderId]
  }
  const mainCoverage = isMedicaid ? medicaidCoverage : primaryCoverage

  const { additionalPlans, requests } = additionalPlansArr.reduce(
    (acc, plan) => {
      if (isInsuranceCoverageRequest(plan)) {
        acc.requests[plan.id] = plan
        return acc
      }
      if (plan.label === 'secondary') {
        acc.secondaryCoverage = {
          ...plan,
          id: `additionalPlans.${plan.id}`,
          insuranceProvider: plan.insuranceProviderId ? insurers?.[plan.insuranceProviderId] : null,
        }
      }
      acc.additionalPlans[plan.id] = {
        ...plan,
        id: `additionalPlans.${plan.id}`,
        insuranceProvider: plan.insuranceProviderId ? insurers?.[plan.insuranceProviderId] : null,
      }
      return acc
    },
    {
      secondaryCoverage: null as InsuranceCoverage | null,
      additionalPlans: {} as Record<string, InsuranceCoverage>,
      requests: {} as Record<string, InsuranceCoverageRequest>,
    },
  )
  return {
    isMedicaid,
    mainCoverage: mainCoverage ? { ...mainCoverage, label: 'main', isMedicaid } : null,
    primaryCoverage,
    medicaidCoverage,
    additionalPlans,
    requests,
  }
}

export const getInsurancePlan = (
  insurancePlans: InsurancePlans,
  id: InsuranceCoverageId,
): InsuranceCoverage | undefined | null => nestedGet(insurancePlans, id) || null

export const getPlansArray = (
  plans: Partial<InsurancePlans>,
): Array<InsuranceCoverage & { id: InsuranceCoverageId }> => {
  const plansArray: Array<InsuranceCoverage & { id: InsuranceCoverageId }> = []
  if (plans.primaryCoverage) plansArray.push({ ...plans.primaryCoverage, id: 'primaryCoverage' })
  if (plans.medicaidCoverage) plansArray.push({ ...plans.medicaidCoverage, id: 'medicaidCoverage' })
  plansArray.push(
    ...Object.entries(plans.additionalPlans || {})
      .filter(([, p]) => !isInsuranceCoverageRequest(p))
      .map(([id, plan]) => ({
        ...(plan as InsuranceCoverage),
        id: `additionalPlans.${id}` as InsuranceCoverageId,
      })),
  )
  return plansArray
}

export const getPlansInsurerIds = (plans: Partial<InsurancePlans>): string[] => {
  const insurerIds: string[] = []
  const plansArray = getPlansArray(plans)
  plansArray.forEach(p => {
    if (p.insuranceProviderId && !insurerIds.includes(p.insuranceProviderId)) {
      insurerIds.push(p.insuranceProviderId)
    }
  })
  return insurerIds
}

export const getPlanNextActionPath = (id: InsuranceCoverageId) => {
  if (id === 'primaryCoverage') return 'nextActions.primaryCoverage'
  if (id === 'medicaidCoverage') return 'nexActions.medicaidCoverage'
  return `nextActions.additionalPlans.${id}`
}

export const getPlanNextAction = (nextActions: NextActions, id: InsuranceCoverageId) => {
  if (id.startsWith('additionalPlans.')) {
    const parsedId = id.split('.')[1]
    if (!parsedId) return undefined
    return nextActions?.additionalPlans?.[parsedId]
  }
  if (id === 'primaryCoverage') return nextActions.primaryCoverage
  if (id === 'medicaidCoverage') return nextActions.medicaidCoverage
  return undefined
}

export const getCoverageNextActions = (assessment: AssessmentSnippet) => {
  const { plans, nextActions } = assessment
  const { isMedicaid } = plans || {}

  const nextActionsArr: Array<{
    coverage: InsuranceCoverage | undefined | null
    id: InsuranceCoverageId
    date: number
    nextAction: NextAction | null | undefined
  }> = []
  if (nextActions?.primaryCoverage || !isMedicaid) {
    nextActionsArr.push({
      id: 'primaryCoverage',
      coverage: plans?.primaryCoverage,
      date: parseNextActionDate(nextActions?.primaryCoverage?.text),
      nextAction: nextActions?.primaryCoverage,
    })
  }
  if (nextActions?.medicaidCoverage || isMedicaid) {
    nextActionsArr.push({
      id: 'medicaidCoverage',
      coverage: plans?.medicaidCoverage,
      date: parseNextActionDate(nextActions?.medicaidCoverage?.text),
      nextAction: nextActions?.medicaidCoverage,
    })
  }
  if (nextActions?.additionalPlans) {
    Object.entries(nextActions.additionalPlans).forEach(([nextActionId, nextAction]) => {
      nextActionsArr.push({
        id: nextActionId as AdditionalCoverageId,
        coverage: plans?.additionalPlans?.[nextActionId],
        date: parseNextActionDate(nextAction?.text),
        nextAction,
      })
    })
  }
  return nextActionsArr.sort((a, b) => a.date - b.date)
}

export const getCoverageNextActionsCount = (assessment: AssessmentSnippet) => {
  let count = 0
  const { plans, nextActions } = assessment || {}
  const { isMedicaid } = plans || {}
  if (nextActions?.primaryCoverage || !isMedicaid) count += 1
  if (nextActions?.medicaidCoverage || isMedicaid) count += 1
  if (nextActions?.additionalPlans) count += Object.keys(nextActions.additionalPlans).length
  return count
}

export const getInsurancePlansField = (plans: Partial<InsurancePlans>): DropdownField => {
  const plansArray = getPlansArray(plans)
  return {
    type: FieldTypes.DROPDOWN,
    options: plansArray.map(p => ({
      id: p.id,
      text: p ? getCoverageText(p, p.insuranceProvider) : 'No coverage',
    })),
    placeholder: 'Select Coverage',
  }
}

export const getHasMultiplePlans = (plans: Partial<InsurancePlans>) => {
  let numPlans = 0
  const {
    primaryCoverage,
    medicaidCoverage,
    secondaryCoverage,
    mainCoverage: _,
    additionalPlans: otherPlans,
  } = plans
  if (primaryCoverage) numPlans += 1
  if (medicaidCoverage) numPlans += 1
  if (secondaryCoverage) numPlans += 1
  numPlans += Object.keys(otherPlans || {}).length
  return numPlans > 1
}

import useResizeObserver from '@react-hook/resize-observer'
import React, {
  createContext,
  createRef,
  MutableRefObject,
  PropsWithChildren,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

type GestureContainerContextData = {
  currHeight: MutableRefObject<number>
  currWidth: MutableRefObject<number>
  containerRef: RefObject<HTMLDivElement>
  width: number
  updateWidth: (width: number) => void
}
const GestureContainerContext = createContext<GestureContainerContextData>({
  currHeight: createRef<number>() as MutableRefObject<number>,
  currWidth: createRef<number>() as MutableRefObject<number>,
  containerRef: createRef<HTMLDivElement>(),
  width: 0,
  updateWidth: () => {},
})

export const GestureContainerProvider = ({ children }: PropsWithChildren) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const currWidth = useRef<number>(0)
  const currHeight = useRef<number>(0)
  const [width, setWidth] = useState(0)

  const handleResize = useCallback((entry: ResizeObserverEntry) => {
    if (!currWidth.current) {
      setWidth(entry.contentRect.width)
    }
    currHeight.current = entry.contentRect.height
    currWidth.current = entry.contentRect.width
  }, [])
  useResizeObserver(containerRef, handleResize)
  const value = useMemo<GestureContainerContextData>(
    () => ({ currHeight, currWidth, containerRef, width, updateWidth: w => setWidth(w) }),
    [width],
  )
  return (
    <GestureContainerContext.Provider value={value}>{children}</GestureContainerContext.Provider>
  )
}

export const useGestureContainer = () => useContext(GestureContainerContext)

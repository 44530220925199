import { adminRoles } from '../constants'
import {
  AdminUserData,
  AppName,
  FieldMapValue,
  PracticeWithAdmin,
  UserGroup,
  UserGroupLabel,
  UserRole,
  UserRoleItem,
  WithId,
} from '../types'
import { PopulatedUser } from '../types/app/populated'
import { User } from '../types/users/user'

export const getInitials = (name: string) => {
  const names = name.split(' ')
  return names.reduce((acc, curr) => {
    if (curr[0]) {
      return acc + curr[0].toUpperCase()
    }
    return acc
  }, '')
}

export const getFullName = (
  user?: User | PracticeWithAdmin | FieldMapValue | null,
  ignoreMiddle?: boolean,
) => {
  let name = ''
  const { fname, lname } = user || {}
  if (fname) name += fname
  if (!ignoreMiddle && (user as User)?.middleName) name += ` ${(user as User).middleName}`
  if (lname) name = `${name ? `${name} ` : ''}${lname}`
  return name || user?.name || ''
}

export const getNameAndInitials = (
  appName: AppName,
  user?: UserRoleItem | null,
  withRole?: boolean,
) => {
  if (!user) return { name: '', initials: '' }
  const group = getUserGroup(user)
  const fullName = getFullName(user)
  const __name = `${fullName}${group && withRole ? ` | ${appName === 'providers-app' && adminRoles.includes(user.role) ? 'Practice ' : ''}${userGroupLabels[group]}` : ''}`
  const _initials = getInitials(fullName)
  return { name: __name, initials: _initials }
}

export const getReverseName = (user?: any | null) => {
  let name = ''
  const { lname, fname } = user || {}
  if (fname) name += fname
  if (lname) name = `${lname}, ${name ? `${name}` : ''}`
  if (name) return name
  if ((user as User)?.name) {
    return (user as User)?.name?.split(' ').reverse().join(', ')
  }
  return user?.email
}

export const populateUser = (
  usersItem: WithId<User>,
  adminItem: AdminUserData | null,
  isInvite: boolean,
): PopulatedUser => ({
  ...usersItem,
  ...adminItem,
  isInvite,
  reverseName: getReverseName(usersItem),
})

export const getIsAdmin = (user: UserRoleItem) => adminRoles.includes(user.role)
export const getIsPracticeAdmin = (user: UserRoleItem) =>
  user.practiceAccess &&
  Object.keys(user.practiceAccess).length > 0 &&
  Object.values(user.practiceAccess).some(role => adminRoles.includes(role))

export const getUserGroup = (user?: UserRoleItem | null): UserGroup => {
  if (!user) return 'patient'
  if (getIsAdmin(user)) return 'admin'
  if (getIsPracticeAdmin(user)) return 'practice'
  return 'patient'
}

export const userGroupLabels: Record<UserGroup, UserGroupLabel> = {
  admin: 'Admin',
  practice: 'Provider',
  patient: 'Patient',
}

export const getAppRole = (
  appName: AppName,
  uid: string | null,
  claims: Record<string, any> | null,
  selectedPracticeId: string | null,
): UserRole | null => {
  if (appName === 'providers-app') {
    if (!selectedPracticeId) return uid ? 'user' : null
    return claims?.practiceAccess[selectedPracticeId] || (uid ? 'user' : null)
  }
  if (claims?.superAdmin) return 'super-admin'
  if (claims?.admin) return 'admin'
  return uid ? 'user' : null
}

import { Field, FieldTypes, isAlphaNumeric } from '@hb/shared'
import { useMemo } from 'react'
import { useCollections } from '../store/collections'
import { formatValue } from '../utils/data'
import { useCollection } from './backend'

export const useFormattedValue = (field?: Field, value?: any, textOnly?: boolean) => {
  const collections = useCollections()

  const collection = useMemo(() => {
    if (!field) return null
    switch (field.type) {
      case FieldTypes.ID:
      case FieldTypes.DYNAMIC_DROPDOWN:
        return field.collection
      default:
        return null
    }
  }, [field])

  const itemId = useMemo(() => {
    if (typeof value !== 'string') return null
    return isAlphaNumeric(value) ? value : null
  }, [value])

  const collectionData = useCollection(collection)

  const formatted = useMemo(
    () =>
      formatValue({
        field,
        value,
        collections,
        textOnly,
      }),
    [field, value, collections, textOnly],
  )

  return useMemo(
    () => ({
      collection: collectionData,
      itemId,
      value: formatted,
    }),
    [itemId, formatted, collectionData],
  )
}

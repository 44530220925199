import React from 'react'
import { GestureOverlayContainer } from './GestureOverlayContainer'
import { MoveOverlayBody } from './MoveOverlay'
import { ResizeOverlayBody } from './ResizeOverlay'

export const OrientationOverlay = ({
  zoom,
  position = [0, 0],
  scale = [1, 1],
  onResize,
  onMove,
  constrainAspectRatio,
  onDelete,
}: {
  zoom: number
  position?: [number, number]
  scale?: [number, number]
  onResize?: (scale: [number, number]) => void
  onMove?: (pos: [number, number]) => void
  constrainAspectRatio?: boolean
  onDelete?: () => void
}) => {
  return (
    <GestureOverlayContainer onDelete={onDelete}>
      {onMove ? <MoveOverlayBody position={position} onMove={onMove} zoom={zoom} /> : null}
      {onResize ? (
        <ResizeOverlayBody
          scale={scale}
          zoom={zoom}
          onResize={onResize}
          constrainAspectRatio={constrainAspectRatio}
        />
      ) : null}
    </GestureOverlayContainer>
  )
}

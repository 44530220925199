import { ButtonProps, Flex, HStack, Input, Text, Tooltip, VStack } from '@chakra-ui/react'
import {
  capitalizeFirstLetterOfEachWord,
  colors,
  CustomElement,
  EditorVersion,
  getFullName,
  PopulatedNode,
  TemplateKey,
  templateTypeNames,
  UpdateCallback,
  UserFieldItem,
} from '@hb/shared'

import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useSlate } from 'slate-react'
import { PopUpMessageContext, UserContext } from '../../contexts'
import { populateTemplate } from '../../utils/templates'
import { ActionButton, BlueButton, SolidActionButton } from '../Buttons'
import { DefaultModal } from '../Modals/DefaultModal'
import { TextEditorContext } from '../RichText/context'
import { SubmitText } from '../RichText/hooks'
import { useTemplateData, useTemplateView } from './contexts'

import { TemplateErrorsAlert } from './TemplateErrorsAlert'
import { TemplateViewProps } from './types'

export const useOnSubmit = (
  templateType: TemplateKey,
  editorVersion: EditorVersion,
  submitText?: TemplateViewProps['submitText'],
) => {
  const { onSubmit } = useTemplateView()
  const { shortcutArgs } = useTemplateData()
  const [submitConfirm, setSubmitConfirm] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [displayedErrors, setDisplayedErrors] = useState<Array<PopulatedNode> | null>(null)
  const [submittedText, setSubmittedText] = useState<Array<CustomElement & UserFieldItem> | null>(
    null,
  )
  const { processResponse } = useContext(PopUpMessageContext)
  const handleSubmit = useCallback(
    async (name: string): Promise<UpdateCallback> => {
      if (submitConfirm) {
        setSubmitConfirm(false)
        if (!submittedText) {
          return processResponse({
            error: 'Internal error - missing submitted text',
          })
        }
        if (!name) {
          return processResponse({
            error: 'Document name cannot be empty',
          })
        }
        const { populated, errors } = populateTemplate(
          { templateText: submittedText },
          shortcutArgs,
        )
        if (errors.length) {
          setDisplayedErrors(errors)
          return processResponse({
            error: 'Errors in template when submitting',
          })
        }
        if (!onSubmit) {
          return processResponse({
            error: 'Internal error - missing submit function',
          })
        }
        setSubmitLoading(true)
        const res = await onSubmit(populated, editorVersion, name)
        setSubmitLoading(false)
        return processResponse(res)
      }
      setSubmitConfirm(true)
      return { success: 'confirm?' }
    },
    [onSubmit, submitConfirm, shortcutArgs, processResponse, editorVersion, submittedText],
  )

  const handleSubmitClick = useCallback((text: (CustomElement & UserFieldItem)[]) => {
    setSubmittedText(text)
    setSubmitConfirm(true)
  }, [])

  const submitButtonText = useMemo(() => submitText?.submit || 'Submit', [submitText])

  return useMemo(
    () => ({
      submitLoading,
      displayedErrors,
      handleSubmit,
      templateType,
      submitButtonText,
      submitConfirm,
      handleSubmitClick,
      onCancel: () => {
        setSubmitConfirm(false)
        setDisplayedErrors(null)
      },
    }),
    [
      submitLoading,
      displayedErrors,
      handleSubmit,
      templateType,
      submitButtonText,
      submitConfirm,
      handleSubmitClick,
    ],
  )
}

const DocumentSubmitModal = ({
  onCancel,
  templateType,
  handleSubmit,
}: {
  onCancel: () => void
  templateType: TemplateKey
  handleSubmit: (name: string) => Promise<UpdateCallback>
}) => {
  // const {} = useContext(TextEditorContext)
  const { user } = useContext(UserContext)
  const fullName = useMemo(() => getFullName(user), [user])
  const documentTypeName = useMemo(() => templateTypeNames[templateType], [templateType])
  const [documentName, setDocumentName] = useState(
    capitalizeFirstLetterOfEachWord(documentTypeName),
  )
  return (
    <DefaultModal
      onClose={onCancel}
      overlayHeader
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen
      render={() => (
        <VStack w="100%" align="flex-start" py={3} px={4}>
          {templateType === 'assessments' ? (
            <Flex flexFlow="column">
              <Text fontFamily="Comfortaa" fontSize="lg" fontWeight={600} color="green.500">
                Confirm Send
              </Text>
              <Text color="gray.600">Send this assessment to {fullName}?</Text>
            </Flex>
          ) : (
            <>
              <Text color="gray.600" fontSize="sm">
                Document name (visible to patient):
              </Text>
              <Input
                value={documentName}
                onChange={e => setDocumentName(e.target.value)}
                _focus={{
                  boxShadow: 'none',
                  border: `1px solid ${colors.green.hex}`,
                }}
                placeholder="Document Name"
              />
            </>
          )}
          <HStack w="100%" justify="flex-end">
            <ActionButton onClick={onCancel}>Cancel</ActionButton>
            <SolidActionButton
              onClick={() => {
                handleSubmit(documentName)
              }}>
              Send {documentTypeName}
            </SolidActionButton>
          </HStack>
        </VStack>
      )}
    />
  )
}

export const TextEditorSubmitButton = ({
  submitButtonText,
  onCancel,
  submitConfirm,
  handleSubmit,
  handleSubmitClick,
  templateType,
  submitLoading,
  displayedErrors,
  ...props
}: ButtonProps & ReturnType<typeof useOnSubmit>) => {
  const editor = useSlate()
  const { mode } = useContext(TextEditorContext)
  const isEnabled = mode === 'View' && !submitLoading
  return (
    <>
      {/* {submitConfirm ? (
        <ActionButton
          colorScheme="gray"
          size="xs"
          isDisabled={submitLoading}
          mt={0}
          mr={2}
          opacity={0.9}
          pointerEvents={'auto'}
          onClick={onCancel}
        >
          Cancel
        </ActionButton>
      ) : null} */}
      <Tooltip
        placement="top"
        hasArrow
        label={mode === 'Edit' ? 'Must be in View mode to submit' : null}>
        <BlueButton
          mt={0}
          size="xs"
          style={{ fontSize: '13px' }}
          onClick={isEnabled ? () => handleSubmitClick(editor.children) : undefined}
          isLoading={submitLoading}
          opacity={isEnabled ? '1' : '0.5'}
          {...props}>
          {submitButtonText}
        </BlueButton>
      </Tooltip>
      {submitConfirm ? (
        <DocumentSubmitModal
          templateType={templateType}
          onCancel={onCancel}
          handleSubmit={handleSubmit}
        />
      ) : null}
      <TemplateErrorsAlert errors={displayedErrors} onCancel={onCancel} />
    </>
  )
}

export const PdfViewSubmitButton = ({
  submitText,
  submitLoading,
  templateType,
  handleSubmit,
}: {
  submitText: SubmitText
  submitLoading: boolean
  templateType: TemplateKey
  handleSubmit: (name: string) => Promise<UpdateCallback>
}) => {
  const [submitConfirm, setSubmitConfirm] = useState(false)
  return (
    <Flex align="center">
      <BlueButton
        size="xs"
        style={{ fontSize: '13px' }}
        onClick={() => setSubmitConfirm(true)}
        isLoading={submitLoading}>
        {submitConfirm ? submitText.confirm : submitText.submit}
      </BlueButton>
      {submitConfirm ? (
        <DocumentSubmitModal
          templateType={templateType}
          onCancel={() => setSubmitConfirm(false)}
          handleSubmit={handleSubmit}
        />
      ) : null}
    </Flex>
  )
}

import { Button, ButtonProps, HStack, Tooltip, VStack } from '@chakra-ui/react'
import {
  colors,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  PopulatedNode,
  SendFaxArgs,
  UpdateCallback,
} from '@hb/shared'

import arrayMutators from 'final-form-arrays'
import React, { useCallback, useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { ReactEditor, useSlate } from 'slate-react'
import { PopUpMessageContext, ThemeContext } from '../../contexts'
import { AdditionalFilesField } from '../Assessments/AdditionalFilesField'
import { BlueButton } from '../Buttons'
import { FormElement } from '../forms'
import { DefaultModal } from '../Modals/DefaultModal'
import { TextEditorContext } from '../RichText/context'
import { useTemplateView } from './contexts'

import { TemplateErrorsAlert } from './TemplateErrorsAlert'

export const useOnFax = () => {
  const { onFax } = useTemplateView()
  // TODO: show a dialog to collect phone number to fax to
  const [textToSend, setTextToSend] = useState<string | null>(null)
  const [faxSending, setFaxSending] = useState(false)
  const [faxErrors, setFaxErrors] = useState<Array<PopulatedNode> | null>(null)
  const { processResponse } = useContext(PopUpMessageContext)

  const handleSubmitFaxNumber = useCallback(
    async ({
      faxToNumber,
      notes,
      fileIds,
    }: Omit<SendFaxArgs, 'assessmentId' | 'html' | 'category'>): Promise<UpdateCallback> => {
      if (!onFax) {
        return { error: 'Internal error - missing submit function' }
      }
      if (!textToSend) {
        return { error: 'internal error - missing text to send' }
      }
      setFaxSending(true)
      const res = await onFax({
        faxToNumber,
        notes,
        html: textToSend,
        fileIds,
      })
      if (res.success) {
        setTextToSend(null)
        setFaxErrors(null)
      }
      setFaxSending(false)
      return processResponse(res)
    },
    [textToSend, onFax, processResponse],
  )

  const handleFax = useCallback((text: string) => {
    setTextToSend(text)
  }, [])

  return {
    faxSending,
    faxErrors,
    textToSend,
    handleFax,
    handleSubmitFaxNumber,
    onCancel: () => {
      setTextToSend(null)
      setFaxErrors(null)
    },
  }
}

const faxDetails: FieldMap = {
  name: 'Fax Details',
  children: {
    faxNumber: {
      type: FieldTypes.PHONE,
      placeholder: 'Number to fax to',
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      optional: true,
      placeholder: 'Notes...',
    },
  },
} as const

export const FaxDetailsEntryModal = ({
  onSubmit,
  isOpen,
  onCancel,
}: {
  isOpen: boolean
  onSubmit: (
    args: Omit<SendFaxArgs, 'html' | 'assessmentId' | 'category'>,
  ) => Promise<UpdateCallback>
  onCancel: () => void
}) => {
  const submit = useCallback(
    (data: FieldMapValue) =>
      onSubmit({
        faxToNumber: data.faxNumber,
        notes: data.notes,
        fileIds: data.fileIds,
      }),
    [onSubmit],
  )
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onCancel}
      overlayHeader
      render={() => (
        <ThemeContext.Provider value={{ theme: 'detailed' }}>
          <Form onSubmit={submit} mutators={{ ...arrayMutators }}>
            {({ handleSubmit, submitting }) => (
              <VStack w="100%" py={3} px={5} align="flex-start" spacing={0}>
                <FormElement name="faxNumber" field={faxDetails.children.faxNumber} />
                <FormElement name="notes" field={faxDetails.children.notes} />
                <FieldArray name="fileIds">
                  {({ fields, meta }) => <AdditionalFilesField meta={meta} fields={fields} />}
                </FieldArray>
                <HStack w="100%" pt={2}>
                  <Button
                    onClick={handleSubmit}
                    isLoading={submitting}
                    ml="auto"
                    size="sm"
                    bg={colors.green.hex}
                    color="white">
                    Send Fax
                  </Button>
                </HStack>
              </VStack>
            )}
          </Form>
        </ThemeContext.Provider>
      )}
    />
  )
}

export const EditorFaxButton = ({
  onCancel,
  faxErrors,
  handleFax,
  faxSending,
  handleSubmitFaxNumber,
  textToSend,
  ...props
}: ButtonProps & ReturnType<typeof useOnFax>) => {
  const editor = useSlate()
  const { mode } = useContext(TextEditorContext)
  const isEnabled = mode === 'View' && !faxSending

  return (
    <>
      <Tooltip
        placement="top"
        hasArrow
        label={mode === 'Edit' ? 'Must be in View mode to send fax' : null}>
        <BlueButton
          mt={0}
          size="xs"
          style={{ fontSize: '13px' }}
          isLoading={faxSending}
          onClick={() => {
            if (isEnabled) handleFax(ReactEditor.toDOMNode(editor, editor).innerHTML as any)
          }}
          {...props}
          // pointerEvents = {isEnabled ? 'auto' : 'none'}
          opacity={isEnabled ? '1' : '0.5'}>
          Send Fax
        </BlueButton>
      </Tooltip>
      <TemplateErrorsAlert errors={faxErrors} onCancel={onCancel} />
      <FaxDetailsEntryModal
        onCancel={onCancel}
        isOpen={!!textToSend}
        onSubmit={handleSubmitFaxNumber}
      />
    </>
  )
}

import React, { useCallback, useState } from 'react'
import { useDrag } from 'react-use-gesture'
import resizeArrowIcon from '../../icons/resize_arrow.svg'
import { useGestureContainer } from './context'
import { GestureOverlayContainer } from './GestureOverlayContainer'
import './styles.css'

type ResizeOverlayProps = {
  zoom: number
  scale: [number, number]
  onResize: (scale: [number, number]) => void
  constrainAspectRatio?: boolean
}

export const ResizeOverlayBody = ({
  zoom,
  scale,
  constrainAspectRatio,
  onResize,
}: {
  zoom: number
  scale: [number, number]
  onResize: (scale: [number, number]) => void
  constrainAspectRatio?: boolean
}) => {
  const { currHeight, currWidth, updateWidth } = useGestureContainer()
  const dragStartWidth = React.useRef(0)
  const dragStartHeight = React.useRef(0)
  const dragStartScale = React.useRef([0, 0])
  const [isDragging, setIsDragging] = useState(false)

  const onResizeDrag: Parameters<typeof useDrag<React.PointerEvent>>[0] = useCallback(
    ({ movement: [dx, dy], event, first, last }) => {
      event.stopPropagation()
      if (first) {
        dragStartWidth.current = currWidth.current
        dragStartHeight.current = currHeight.current
        dragStartScale.current = [scale[0], scale[1]]
        setIsDragging(true)
      }
      if (last) {
        setIsDragging(false)
        updateWidth(currWidth.current)
      }
      requestAnimationFrame(() => {
        if (!dragStartWidth.current || !dragStartHeight.current) return
        const baseWidth = dragStartWidth.current + dx / zoom
        const baseHeight = dragStartHeight.current - dy / zoom
        const avgScale =
          (baseWidth / dragStartWidth.current) * 0.5 + (baseHeight / dragStartHeight.current) * 0.5

        const newHeight = constrainAspectRatio ? dragStartHeight.current * avgScale : baseHeight
        const newWidth = constrainAspectRatio ? dragStartWidth.current * avgScale : baseWidth
        currWidth.current = newWidth
        currHeight.current = newHeight
        onResize([
          Math.min(
            2,
            Math.max((newWidth / dragStartWidth.current) * dragStartScale.current[0], 0.2),
          ),
          Math.min(
            2,
            Math.max((newHeight / dragStartHeight.current) * dragStartScale.current[1], 0.2),
          ),
        ])
      })
    },
    [zoom, constrainAspectRatio, onResize, scale, updateWidth, currWidth, currHeight],
  )
  const bind = useDrag(onResizeDrag)

  return (
    <div style={{ cursor: isDragging ? 'grabbing' : 'grab' }} {...bind()} className="resize-handle">
      <img src={resizeArrowIcon} height="16px" width="16px" alt="resize" draggable={false} />
    </div>
  )
}

export const ResizeOverlay = (props: ResizeOverlayProps) => {
  return (
    <GestureOverlayContainer>
      <ResizeOverlayBody {...props} />
    </GestureOverlayContainer>
  )
}

import {
  Button,
  Divider,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import { defaultFont, FontId, fonts } from '@hb/shared'
import React, { useMemo } from 'react'
import { fontFamilyFromFontId } from '../TemplateToolbar/utils'

export const FontSelect = ({
  font,
  setFont,
}: {
  font?: FontId
  setFont: (updated: FontId) => void
}) => {
  const currentFontName = useMemo(() => fonts[font || defaultFont.id]?.name || '', [font])
  return (
    <Popover trigger="hover" size="sm">
      <PopoverTrigger>
        <Button
          h="26px"
          variant="unstyled"
          borderRadius={0}
          border="1px solid #ababab"
          fontWeight={500}
          display="flex"
          alignItems="center"
          gap={2}
          px={2}
          minW="120px">
          <Flex minW="0" flex={1}>
            <Text
              w="100%"
              isTruncated
              color="#777"
              textAlign="left"
              fontFamily={fontFamilyFromFontId(font || defaultFont.id)}>
              {currentFontName}
            </Text>
          </Flex>
          <Text color="#777" fontSize="xs">
            ▼
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent w="auto" minW="120px">
        <VStack divider={<Divider />} spacing={0}>
          {Object.values(fonts).map(f => (
            <Button
              w="100%"
              key={f.id}
              size="sm"
              fontWeight={500}
              _hover={{
                bg: 'gray.100',
              }}
              variant="unstyled"
              px={2}
              minH="0"
              h="auto"
              py={1}
              justifyContent="flex-start"
              display="flex"
              fontFamily={fontFamilyFromFontId(f.id)}
              onClick={() => setFont(f.id)}>
              {f.name}
            </Button>
          ))}
        </VStack>
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  )
}

import { Flex, HStack, TooltipProps } from '@chakra-ui/react'
import { getDateString, getDateTimeString, UserGroup } from '@hb/shared'
import React, { CSSProperties, useMemo } from 'react'
import { PreviewSpan } from './PreviewSpan'
import { UserBadge, UserBadgeProps } from './Users/UserBadge'

export const getActionLogText = (
  action?: string,
  on?: number | string | null,
  dateStyle?: 'datetime' | 'date',
) => {
  if (on) {
    const t = `${action ? `${action} on ` : ''}${dateStyle === 'date' ? getDateString(on, 'short') : getDateTimeString(on, 'short')}`
    return t
  }
  return action
}

export const ActionLog: React.FC<{
  action?: string
  on?: number | string | null
  by?: string | null
  color?: string
  group?: UserGroup
  index?: number
  dateStyle?: 'datetime' | 'date'
  badgeProps?: Omit<UserBadgeProps, 'on' | 'userId'>
  tooltipProps?: Omit<TooltipProps, 'children'>
  style?: CSSProperties
}> = ({ action, on, by, color = '#808080', badgeProps, style, tooltipProps, group, dateStyle }) => {
  const text = useMemo(() => {
    const t = getActionLogText(action, on, dateStyle)
    return (
      <PreviewSpan
        style={{
          fontWeight: 400,
          fontFamily: 'Open Sans',
          fontSize: '0.9rem',
          lineHeight: 1.1,
          color,
          ...style,
        }}>
        {t}
      </PreviewSpan>
    )
  }, [action, on, color, style, dateStyle])

  return text || by ? (
    <HStack
      spacing={1.5}
      minW="0"
      // bg={getRowBackground(index)}
      w="100%"
      style={style}>
      {by ? (
        <UserBadge userGroup={group} userId={by} tooltipProps={tooltipProps} {...badgeProps} />
      ) : null}
      <Flex
        flex={1}
        minW="0"
        color="inherit"
        fontSize="inherit"
        whiteSpace="nowrap"
        overflow="hidden"
        fontWeight={600}>
        {text}
      </Flex>
    </HStack>
  ) : null
}

import { FieldMapValue, nameField } from '@hb/shared'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'
import { useAppModals, useAuth } from '../../store'
import { GenericEditModal } from '../DataView/GenericEditModal'

export const useNameEntryForm = () => {
  const { user, ref } = useAuth()
  const { fname, lname, nickname, middleName, pronouns } = user || {}
  const value = useMemo(
    () => ({ fname, lname, nickname, middleName, pronouns }),
    [fname, lname, nickname, middleName, pronouns],
  )

  const update = useUpdateDoc('your info')
  const onUpdate = useCallback(
    (data: FieldMapValue) =>
      update(ref, '', {
        fname: (data.fname || '').trim(),
        lname: (data.lname || '').trim(),
        middleName: (data.middleName || '').trim(),
        nickname: (data.nickname || '').trim(),
        pronouns: (data.pronouns || '').trim(),
      }),
    [ref, update],
  )

  const onUpdateField = useCallback(
    (field: string, value: string) => {
      return update(ref, field, value)
    },
    [ref, update],
  )

  return { value, onUpdate, onUpdateField }
}

export const NameEntryModal = ({ id = 'root' }: { id?: string }) => {
  const { openId, close } = useAppModals(s => s.nameEntry)
  const history = useHistory()
  const { onUpdate, value } = useNameEntryForm()

  return openId === id ? (
    <GenericEditModal
      isOpen
      onClose={close}
      onSubmit={data =>
        onUpdate(data).then(res => {
          history.push('/assessment')
          return res
        })
      }
      data={value}
      field={nameField}
    />
  ) : null
}

import { AssessmentDocument, ConsentForm, EditorVersion } from '@hb/shared'
import React from 'react'
import { DefaultModalProps } from '../Modals/DefaultModal'
import { EditorModalContainer } from './EditorModalContainer'
import { ReadOnlyEditor } from './TextEditor'
import { ReadOnlyEditorProps } from './types'

export const TextViewModal = ({
  ...props
}: Omit<ReadOnlyEditorProps, 'width' | 'baseZoom' | 'height'> &
  Omit<DefaultModalProps, 'render'> & {
    version: EditorVersion
    document?: AssessmentDocument | ConsentForm
  }) => {
  const { decorators, value, withDownload, document, version, onFormSubmit, ...modalProps } = props

  return (
    <EditorModalContainer
      {...modalProps}
      render={editorSize => (
        <ReadOnlyEditor
          version={version}
          {...editorSize}
          document={document}
          decorators={decorators}
          onFormSubmit={onFormSubmit}
          withDownload={withDownload}
          value={value}
        />
      )}
    />
  )
}

import { AssessmentDocument, ConsentForm, FieldMap, Mode, UserRoleItem } from '@hb/shared'
import { createContext, createRef, RefObject, useContext } from 'react'
import { EditorSize } from './hooks'

export type DocumentFormContextValue<FieldType extends FieldMap = FieldMap> = {
  toBeSignedByUser: UserRoleItem | null
  toBeSignedByMe: boolean
  document: ConsentForm | AssessmentDocument | undefined
  signerLoading: boolean
  field: FieldType | null
  readOnly: boolean
}

export type TextEditorContextValue = {
  mode: Mode
  editorDomElement: RefObject<HTMLDivElement | undefined>
} & EditorSize

export const TextEditorContext = createContext<TextEditorContextValue>({
  mode: 'View',
  editorDomElement: createRef(),
  baseZoom: 1,
  height: 0,
  width: 0,
})

export const DocumentFormContext = createContext<DocumentFormContextValue<any>>({
  field: null,
  readOnly: false,
  toBeSignedByUser: null,
  toBeSignedByMe: false,
  document: undefined,
  signerLoading: false,
})

export const useDocumentForm = <FieldType extends FieldMap = FieldMap>() =>
  useContext<DocumentFormContextValue<FieldType>>(DocumentFormContext)

import { useDisclosure } from '@chakra-ui/react'
import {
  NotificationType,
  ThreadSearchResult,
  ThreadSelection,
  ThreadType,
  UserRoleItem,
  USER_ROLES,
} from '@hb/shared'
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useDocument } from '../../hooks/backend/useDocument'
import { useAuth } from '../../store'
import { ThreadsListThread, useMessagingWidget, UseMyThreads, useMyThreads } from './hooks'

import { UseMessagingWidget } from './types'

export type UseSelectedThread = {
  thread: ThreadsListThread | null
  // messages: UseThreadMessages
}

type GoToThread = (data: {
  id: string
  type: ThreadType
  title: string
  searchResult?: ThreadSearchResult | undefined
}) => void
type ThreadsListContextData = {
  threads: UseMyThreads
  widget: UseMessagingWidget
  selectedThread: UseSelectedThread
  goToThread: GoToThread
  notificationType: NotificationType | null
  closeThread: () => void
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

export const ThreadsListContext = createContext<ThreadsListContextData>({
  threads: {
    data: [],
    selectedTabThreads: [],
    deepSearchLoading: false,
    deepSearchResults: null,
    deepSearchThreads: () => {},
    numUnreadThreads: 0,
    loading: false,
    error: null,
  },
  notificationType: null,
  closeThread: () => {},
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  widget: {
    data: {
      notificationVolume: 0,
      tab: null,
      thread: null,
    },
    searchQuery: '',
    tabsVisible: false,
    updateSearchQuery: () => {},
    creatingNewThread: false,
    setCreatingNewThread: () => {},
    loading: false,
    clearSearchResult: () => {},
    searchResult: null,
    setSearchResult: () => {},
    localNotificationVolume: null,
    updateLocalNotificationVolume: () => {},
    error: null,
    update: async () => ({ error: 'No widget' }),
  },
  selectedThread: {
    thread: null,
    // messages: {
    //   data: [],
    //   fetchMoreData: () => Promise.resolve({ data: [], error: null }),
    //   hasMoreData: false,
    //   loading: false,
    //   error: null,
    // },
  },
  goToThread: () => {},
})

const useSelectedThread = (
  threadSelection: ThreadSelection | null,
  threads: ThreadsListThread[],
) => {
  // const { id: threadId } = threadSelection || {}
  const thread = useMemo(
    () => (threadSelection ? threads.find(t => t.threadId === threadSelection.id) || null : null),
    [threads, threadSelection],
  )

  const [searchQuery, setSearchQuery] = useState('')

  return useMemo(
    () => ({
      thread,
      searchQuery,
      updateSearchQuery: setSearchQuery,
    }),
    [thread, searchQuery],
  )
}

export const MyThreadsListProvider = ({ children }: PropsWithChildren) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const widget = useMessagingWidget()
  const authUser = useAuth(s => s.authUser)
  const { data: me } = useDocument<UserRoleItem>(USER_ROLES, authUser?.uid || null)
  const { webPushNotifications } = me || {}
  const { messaging: notificationType = null } = webPushNotifications || {}
  const threads = useMyThreads(widget)
  const {
    data: widgetData,
    setSearchResult,
    update: updateWidget,
    updateSearchQuery,
  } = widget || {}
  const { thread: threadSelection } = widgetData || {}
  const { data: threadsData } = threads || {}

  const selectedThread = useSelectedThread(threadSelection, threadsData)
  const goToThread = useCallback(
    ({
      id,
      title,
      type,
      searchResult: goToSearchResult,
    }: {
      id: string
      type: ThreadType
      title: string
      searchResult?: ThreadSearchResult
    }) => {
      updateSearchQuery('')
      updateWidget({
        thread: {
          id,
          type,
          title: title || '',
        },
      })
      if (goToSearchResult) setSearchResult(goToSearchResult)
    },
    [updateWidget, setSearchResult, updateSearchQuery],
  )

  const closeThread = useCallback(() => {
    updateWidget({
      thread: null,
    })
  }, [updateWidget])

  const contextData = useMemo<ThreadsListContextData>(
    () => ({
      threads,
      widget: { ...widget },
      selectedThread,
      goToThread,
      closeThread,
      notificationType,
      isOpen,
      onClose,
      onOpen,
    }),
    [
      threads,
      widget,
      selectedThread,
      goToThread,
      closeThread,
      notificationType,
      isOpen,
      onClose,
      onOpen,
    ],
  )

  return <ThreadsListContext.Provider value={contextData}>{children}</ThreadsListContext.Provider>
}

export const useThreadsList = () => useContext(ThreadsListContext)

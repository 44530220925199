import {
  A4_DIMS_PDF_UNITS,
  ExternalPdfFieldType,
  FieldTypes,
  InlineFilePlacement,
  TemplateAttachment,
} from '@hb/shared'

const defaultFieldSize: Record<ExternalPdfFieldType, [number, number]> = {
  [FieldTypes.TEXT]: [100, 32],
  [FieldTypes.DATE]: [193, 30],
  [FieldTypes.SIGNATURE]: [240, 80],
  [FieldTypes.INITIALS]: [58, 24],
}

export const getInlineSize = (attachment: TemplateAttachment<InlineFilePlacement>) => {
  switch (attachment.type) {
    case 'input':
      return defaultFieldSize[attachment.field.type as ExternalPdfFieldType]
    case 'file':
      return A4_DIMS_PDF_UNITS
  }
}
